<!-- Anverso Tarjeta -->
<div class="row factura" *ngIf="configuracion">

    <div class="col-12 mb-1">
      <!-- Cabecera Logos -->
      <div class="row tarjeta-cabecera mt-3">

        <!-- Logo -->
        <div class="col-6">
            <img src="../../../../assets/logo_limusa.png" class="logo-limusa" />
        </div>

        <!-- Datos empresa -->
        <div class="col-6">
            <div class="row">
                <div class="col-12 text-center"><strong>{{configuracion.nombre}}</strong></div>
                <div class="col-12 text-center">{{configuracion.domicilio}}</div>
                <div class="col-12 text-center">{{configuracion.web}}</div>
                <div class="col-12 text-center">{{telefono}}</div>
                <div class="col-12 text-center">{{localidad}}</div>
            </div>
        </div>

      </div>

    </div>

    <!-- Pie Pagina -->
    <div class="col-12 mt-5 cliente ">
        <div class="row">
            <div class="col-3 text-end texto" ><strong>Fecha: </strong></div>
            <div class="col-9 text-start texto" *ngIf="factura && factura.fechaaceptacion !== ''" >
              {{ factura.fechaaceptacion }}
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-end texto" ><strong>Factura: </strong></div>
            <div class="col-9 text-start texto" *ngIf="factura">
                {{factura.factura_numero}}/{{factura.factura_serie}}
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-end texto" ><strong>NIF/CIF: </strong></div>
            <div class="col-9 text-start texto" *ngIf="factura && factura.usuario_dni" >
                {{factura.usuario_dni}}
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-end texto" ><strong>Cliente: </strong></div>
            <div class="col-9 text-start texto" *ngIf="factura && factura.usuario_nombre"  >
                {{factura.usuario_nombre}}
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-end texto" ><strong>Domicilio: </strong></div>
            <div class="col-9 text-start texto maxancho bg-warning" *ngIf="factura && factura.domicilio"  >
                {{factura.domicilio}}
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-end texto" ><strong>Localidad: </strong></div>
            <div class="col-9 text-start texto"  *ngIf="factura && factura.usuario_localidad">
                {{factura.usuario_localidad}}
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3 text-end texto"  *ngIf="factura && factura.destinatario"><strong>Destinatario: </strong></div>
            <div class="col-9 text-start texto"  *ngIf="factura && factura.destinatario">
                {{factura.destinatario}}
            </div>
        </div>
    </div>

    <!-- Tarjeta y zona -->
    <div class="col-12 my-0 ">
        <div class="row justify-content-end margentarjeta">
            <div class="col-6 text-center align-self-end cuadro " >

                <div class="row">
                    <div class="col texto"><strong class="">Tarjeta Nº:</strong><span class="margenizquierdo" *ngIf="factura && factura.tarjeta_numero">{{factura.tarjeta_numero}}</span></div>
                    <div class="col texto"><strong>Zona:</strong><span class="margenizquierdo" *ngIf="factura && factura.zona_id">{{factura.zona_id}}</span></div>
                </div>
            </div>
        </div>
    </div>

    <!-- Concepto e importes -->
    <div class="col-12 my-5 cuadro">
        <div class="row mt-2">
            <div class="col-12 text-start texto " ><strong>CONCEPTO: </strong></div>
        </div>

        <div class="row mt-2">
            <div class="col-12 text-start texto" >
                <strong>TARJETA DE COMERCIO-RESIDENTE SERVICIO O.R.A. </strong>
                <span *ngIf="factura && factura.annio">{{factura.annio}}</span>
            </div>
        </div>

        <div class="row justify-content-end my-5">
            <div class="col-8 text-end align-self-end " >
                <div class="row">
                    <div class="col-8 texto">
                        <strong>BASE IMPONIBLE ....... </strong>
                    </div>
                    <div class="col-4 texto">
                        <span *ngIf="factura && factura.importe">{{factura.importe | number:'1.2-2'}} €</span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-8 texto">
                        <strong>IVA .. 21 % ......... </strong>
                    </div>
                    <div class="col-4 texto">
                        <span *ngIf="factura && factura.iva">{{factura.iva | number:'1.2-2'}} €</span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-8 texto">
                        <strong>TOTAL ............... </strong>
                    </div>
                    <div class="col-4 texto">
                        <span *ngIf="factura && factura.total">{{factura.total | number:'1.2-2'}} €</span>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Pie Pagina -->
    <div class="col-12 mt-5">
        <div class="row">
            <div class="col-12 text-center pie" >{{configuracion.registro_mercantil}} </div>
        </div>
    </div>


</div>


