import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, AfterViewInit, OnInit } from '@angular/core';
import { globalState, tiposEstado } from 'src/global';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { ToastrService } from 'ngx-toastr';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faAddressCard, faFileContract, faPrint } from '@fortawesome/free-solid-svg-icons';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { LoadService } from 'src/app/services/helpers/load.service';

@Component({
  selector: 'app-solicitud-header-fomr',
  templateUrl: './solicitud-header-form.component.html',
  styleUrls: ['./solicitud-header-form.component.scss'],
  providers: [SolicitudesServices, UsuariosService, TarjetasServices]
})
export class SolicitudHeaderFormComponent implements OnInit{
  // Datos para dibujar la pantalla
  @Input() data!: EditaSolicitudView;
  tipos_estado = tiposEstado
  estado_texto: string = "";
  icono_tarjeta: IconDefinition = faAddressCard;
  icono_factura: IconDefinition = faPrint;
  icono_tar_fac: IconDefinition = faFileContract;

  // Eventos para interactuar con el componente anterior
  @Output() refresca_datos: EventEmitter<boolean> = new EventEmitter<boolean>;
  @Output() envia_datos_tarjeta: EventEmitter<any> = new EventEmitter<any>;
  @Output() envia_datos_factura: EventEmitter<any> = new EventEmitter<any>;
  @Output() envia_datos_tar_fac: EventEmitter<any> = new EventEmitter<any>;

  constructor(
    private _sol_services: SolicitudesServices,
    private _load_service: LoadService,
    private _tarjeta_services: TarjetasServices,
    private _toast: ToastrService,
  ) {}

  ngOnInit(): void {
    this.obtener_estado()
  }

  obtener_estado() {
    this.tipos_estado.forEach((item) => {
      if( item.id === this.data.estado) {
        this.estado_texto = item.descripcion;
      }
    })
  }

  async cambia_estado(id_estado: number) {
    this._load_service.notifyLoadChange(true);
    try {
      const data = await this._sol_services.actualiza_estado(this.data.id, id_estado)
      if (data != null)
        this.refresca_datos.emit(true);
    } catch (e: any){
      this._toast.error(e)
    } finally {
      this._load_service.notifyLoadChange(false);
    }
  }

  async datos_tarjeta(tarjeta_id: number) {
    try {
      const datos_tarjeta = await this._tarjeta_services.ficha_vista(tarjeta_id)
      const datos_impresion = {
        id: datos_tarjeta.id,
        annio: datos_tarjeta.annio,
        numero: datos_tarjeta.numero,
        zona_id: datos_tarjeta.zonaId,
        zonaAbreviatura: datos_tarjeta.zonaAbreviatura,
        matricula: datos_tarjeta.matricula,
        color: datos_tarjeta.zonaColor
      }
      this.envia_datos_tarjeta.emit(datos_impresion);

    } catch (e: any){
      this._toast.error(e);
    }
  }

  datos_factura(solicitud_id: number) {
    const data = {
      id: solicitud_id
    }
    this.envia_datos_factura.emit(data)
  }

  async datos_tarjeta_factura(tarjeta_id: number, solicitud_id: number) {
    const datos_tarjeta = await this._tarjeta_services.ficha_vista(tarjeta_id)
      const datos_impresion = {
        id: datos_tarjeta.id,
        annio: datos_tarjeta.annio,
        numero: datos_tarjeta.numero,
        zona_id: datos_tarjeta.zonaId,
        zonaAbreviatura: datos_tarjeta.zonaAbreviatura,
        matricula: datos_tarjeta.matricula,
        color: datos_tarjeta.zonaColor
      }
    this.envia_datos_tar_fac.emit({datos_tarjeta: datos_impresion, datos_factura: {id: solicitud_id}})
  }

  /*
  usuario: IUsuario | undefined;
  id: number = 0;
  inicializado: number = 0;
  fechaSolicitud : Date = new Date();
  tramita : string = '';
  cliente : string = '';
  tarjetaNumero : number = 0;
  dni : string = '';
  telefono: string = '';
  tarjeta: TarjetaView | null = null;
  tarjetaIcon: IconDefinition = faAddressCard;
  bothIcon: IconDefinition = faPrint;
  facturaIcon: IconDefinition = faFileContract;


  @Input() data!:SolicitudModel;
  @Output() recargarDatos: EventEmitter<number> = new EventEmitter();
  tiposEstado = tiposEstado;
  estadoLabel: string = '';
  @Output() enviarDatosTarjeta: EventEmitter<any> = new EventEmitter();
  @Output() enviarDatosFactura: EventEmitter<any> = new EventEmitter();
  @Output() enviarAmbos: EventEmitter<any[]> = new EventEmitter();

  constructor(
    private _router: ActivatedRoute,
    private _services: SolicitudesServices,
    private _servicesTar: TarjetasServices,
    private _usuariosServices: UsuariosService,
    private _toast: ToastrService
  ) {
    if(globalState.identity) {
      // Se obtiene usuario
      this.usuario = globalState.identity;
    }
    this._router.params.forEach((param: Params) => {
      if (param['slug'] !== undefined) {
        // Se obtiene id de Solicitud
        this.id = Number(param['slug']);
      }
    });

  }

  ngAfterViewChecked() {
    if ((this.inicializado == 0) && (this.data.usuarioId > 0)) {
      this.fechaSolicitud = new Date(this.data.fechaSolicitud);
      this.obtenNombreUsuario(false);
      this.obtenNombreUsuario(true);

      if (this.data.tarjeta_id > 0) {
        this.obtenTarjeta(this.data.tarjeta_id);
      }

      this.inicializado = 1;

    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    // Verificar si el atributo 'data' ha cambiado
    if (changes["data"] && changes["data"].currentValue) {
      // Llamar a la función que deseas ejecutar cuando 'data' se actualiza
      this.obtenerEstado();
    }

}

  obtenerEstado() {
    this.tiposEstado.forEach((item) => {
      if( item.id === this.data.estado) {
        this.estadoLabel = item.descripcion;
      }
    })
  }

  onChangeEstado(estado: number) {
    this._services.actualizaEstado(this.id, estado).subscribe({
      next: (res) => {
        this._toast.success('Estado actualizado')
        this.data.estado = estado;
        this.data.fechaAceptacion =  res.data[0].fechaAceptacion ?? null;
        this.data.fechaDenegacion =  res.data[0].fechaDenegacion ?? null;
        this.data.tarjeta_id = res.datr
        this.recargarDatos.emit(this.data.id)
        this.obtenerEstado();
      },
      error: (err) => {
        this._toast.error('No ha sido posible actualizar el estado', '¡Error!')
      },
    })
  }

  // Se obtienen todas las solicitudes
  obtenNombreUsuario(usuario: boolean) {

    let codigo = 0;
    if (usuario) {
      codigo = this.data.tramitaId;
    }
    else {
      codigo = this.data.usuarioId;
    }

    if(codigo !== 0) {
      this._usuariosServices.ficha(codigo).subscribe({
        next: (res) => {
          if (usuario) {
            this.tramita = res.data.nombre;
          }
          else {
            this.cliente = res.data.nombre;
            this.dni = res.data.dni;
            this.telefono = res.data.telefono;
          }
        },
        error: (err) => {
          this.tramita = '';
        },
        complete: () => {
        },
      });
    }
  }

  obtenTarjeta(id: number) {
    this._servicesTar.ficha(id).subscribe({
      next: (res) => {
        this.tarjetaNumero = res.data[0].numero;
      },
      error: (err) => {
        this.tarjetaNumero = 0;
      },
      complete: () => {
      },
    });

  }

  recibirDatosTarjeta(tarjetaId: number) {
    this.obtenerTarjeta(tarjetaId).subscribe(datos => {
      this.imprimirDatosTarjeta(datos);
    });
  }

  obtenerTarjeta(id: number) {
    return this._servicesTar.fichaVista(id).pipe(
      switchMap((res) => {
        this.tarjeta = createTarjetaVista(res.data[0]);
        return of({
          id: this.tarjeta.id,
          annio: this.tarjeta.annio,
          numero: this.tarjeta.numero,
          zona_id: this.tarjeta.zonaId,
          zonaAbreviatura: this.tarjeta.zonaAbreviatura,
          matricula: this.tarjeta.matricula,
          color: this.tarjeta.zonaColor
        });
      })
    );
  }

  imprimirDatosTarjeta(datos: any) {
    // Aquí puedes realizar la lógica de impresión o emitir el evento según tus necesidades
    if (datos !== null) {
      this.enviarDatosTarjeta.emit(datos);
    }
  }

  imprimirDatosFactura(datos: any) {
    const data = {
      id: datos
    }
    this.enviarDatosFactura.emit(data)
  }

  recibirDos(tarjetaId: number, solicitudId: number){
    this.obtenerTarjeta(tarjetaId).subscribe(tarjeta => {
      this.enviarAmbos.emit([tarjeta, {id: solicitudId}]);
    });
  }*/
}
