import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { loginData } from 'src/app/models/interface/login.interface';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { createUsuario } from '../helpers/model.helper';

@Injectable()
export class AuthService {
  public url: string = globalState.url ;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  async login(data: loginData): Promise<{ token: string, usuario: UsuarioModel } > {
    const params = new HttpParams()
      .set('dni', data.dni)
      .set('password', data.password);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    try {
      const res = await firstValueFrom(this._http.post<any>(this.url + 'usuarios/login', params, httpOptions));
      const token = res.data.token;
      const usuario = createUsuario(res.data.usuario);
      return { token, usuario }
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al iniciar sesión';
      return Promise.reject(errorMessage);

    }
  }


  getIdentity() :UsuarioModel | undefined {
     // Se comprueba identity
     if (globalState.identity) {
      this.identity = globalState.identity
      // Se comprueba token
      if (globalState.token) {
        this.token = globalState.token;
      }
     } else {
      const localToken = localStorage.getItem(globalState.tokenName);
      if (localToken) {
        try {
          const newIdentity: UsuarioModel = JSON.parse(atob(localToken));
          if(newIdentity != null) {
            globalState.identity = new UsuarioModel(
              newIdentity.id,
              newIdentity.email,
              newIdentity.dni,
              newIdentity.nombre,
              newIdentity.activo,
              newIdentity.password,
              newIdentity.avatar,
              newIdentity.telefono,
              newIdentity.role,
              newIdentity.domicilio,
              newIdentity.codigoPostal,
              newIdentity.localidad,
              newIdentity.provincia,
              newIdentity.observaciones
            );
            this.identity = globalState.identity;
          } else {
            this.identity = undefined;
          }
        } catch(e: any) {
          this.identity = undefined;
        }
      }
    }
    return this.identity;
  }
}
