<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4">
    <h6 *ngIf="isNuevo" class="mb-0">Registro de zona</h6>
    <h6 *ngIf="!isNuevo" class="mb-0">Actualización de zona</h6>
    <div class="form-check form-switch form-check">
      <input
        #activo
        name="activo"
        class="form-check-input"
        type="checkbox"
        ngModel="data.activo"
        id="activo"
        (change)="cambia_activo()"
      >
      <label *ngIf="data.activo" class="form-check-label" for="activo">Activo</label>
      <label *ngIf="!data.activo" class="form-check-label" for="activo">Inactivo</label>
    </div>

  </div>

  <div class="form-body-three-cols mt-3">
    <app-input
      class="mt-3"
      [control]="formGroup.controls.nombre"
      [label]="'Nombre *'"
      [type]="'text'"

      [id]="'nombre'">
    </app-input>
    <div class="col mx-auto mt-3">
        <app-select-input
          [optionData]="tipo_documentacion_lista"
          [optionsLabels]="['id','nombre']"
          [defaultSelectText]="'Selecciona tipo de documentación'"
          [control]="formGroup.controls.tipoDocumentacionId"
          [name]="'select-documentacion'"
        />

      <app-input
        [control]="formGroup.controls.tipoDocumentacionId"
        [type]="'hidden'">
      </app-input>
    </div>
    <div class="switch-col">
      <app-reverse-switch
        class="col mx-auto mt-3"
        [control]="formGroup.controls.caducidad"
        [id]="'caducidad-switch'"
        [label]="'¿Tendrá caducidad?'"
      >
      </app-reverse-switch>
    </div>
    <div class="switch-col">
      <app-reverse-switch
        class="col mx-auto mt-3"
        [control]="formGroup.controls.obligatorio"
        [id]="'obligatorio-switch'"
        [label]="'Obligatorio'"
      >
      </app-reverse-switch>

    </div>
    <div class="switch-col">
      <app-reverse-switch
        class="col mx-auto mt-3"
        [control]="formGroup.controls.cambio_domicilio"
        [id]="'obligatorio-switch'"
        [label]="'Se necesita para cambio de domicilio'"
      >
      </app-reverse-switch>

    </div>
    <div class="switch-col">
      <app-reverse-switch
        class="col mx-auto mt-3"
        [control]="formGroup.controls.cambio_vehiculo"
        [id]="'obligatorio-switch'"
        [label]="'Se necesita para cambio de matrícula'"
      >
      </app-reverse-switch>

    </div>
  </div>

  <div class="form-body-one-cols mt-3">

    <app-text-editor
    [label]="'Observaciones'"
    [control]="formGroup.controls.observaciones"
    [id]="'select-observaciones'"
  />
</div>

  <div class="d-flex gap-3 justify-content-start">
    <button
      type="submit"
      class="btn btn-aceptar mt-5"
      [disabled]="!formGroup.valid || formGroup.controls.tipoDocumentacionId.value === 0"
      >
      <span  *ngIf="isNuevo">
        Crear
      </span>
      <span  *ngIf="!isNuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/tipo-cliente/' + idCliente" class="btn btn-outline-error mt-5" >
      Cancelar
    </a>
  </div>
</form>
