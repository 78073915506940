import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadService } from 'src/app/services/helpers/load.service';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { globalState } from 'src/global';

@Component({
  selector: 'utilidades-page',
  templateUrl: './utilidades-page.component.html',
  styleUrls: ['./utilidades-page.component.scss'],
  providers: [TarjetasServices,
    ConfiguracionService,
    NgbModalConfig,
    NgbModal]
})
export class UtilidadesPageComponent {
  typeForm: string = '';
  public verProgeso : boolean = false;
  public maxProgreso : number = 0;
  public actualProgreso : number = 0;
  public porcentajeProgreso : number = 0;
  imprimirTarjeta: boolean = false;

  formGroup = new FormGroup({
    desde:  new FormControl<number>(0, [Validators.required]),
    hasta:  new FormControl<number>(0, [Validators.required]),
    annio:  new FormControl<number>(new Date().getFullYear()+1, [Validators.required]),
    annioActual:  new FormControl<number>(new Date().getFullYear()+1, [Validators.required]),
    zona:  new FormControl<number>(0, [Validators.required]),
    saltopagina:  new FormControl<boolean>(true, [Validators.required]),
  });

  public tarjetas : any;

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: TarjetasServices,
    private _configuracion: ConfiguracionService,
    private _loadService: LoadService,
    config: NgbModalConfig,
    private modalService: NgbModal
    ){
  }

  ngOnInit() {
    this.getUltima();
  }


  guardarTarjetas() {
    //console.log('Guardando tarjetas');
  }


  ngDoCheck(){
    if((globalState.annioActual != this.formGroup.controls.annioActual.value) && (globalState.annioActual > 0)) {
      this.formGroup.controls.annioActual.setValue(globalState.annioActual);
      this.getUltima();
    }
  }
 
  getFiltro() {
    let filtro = '';

    filtro = 'where (annio = '+this.formGroup.controls.annioActual.value+')';

    if (this.formGroup.controls.desde.value) {
      if (this.formGroup.controls.desde.value > 0) {
        filtro = filtro+'  and (numero >= '+this.formGroup.controls.desde.value+')';
      }
    }

    if (this.formGroup.controls.hasta.value) {
      if (this.formGroup.controls.hasta.value > 0) {
        if (filtro == '') {
          filtro = filtro+' where ';
        }
        else {
          filtro = filtro+'   and ';
        }

        filtro = filtro+'(numero <= '+this.formGroup.controls.hasta.value+')';
      }
    }

    if (this.formGroup.controls.zona.value) {
      if (this.formGroup.controls.zona.value > 0) {
        if (filtro == '') {
          filtro = filtro+' where ';
        }
        else {
          filtro = filtro+'   and ';
        }

        filtro = filtro+'(zona_id = '+this.formGroup.controls.zona.value+')';
      }
    }

    //filtro = 'where (id < 100)';

    return filtro;
  }
 
  getTarjetas() {
    this._loadService.notifyLoadChange(true);

    let filtro = this.getFiltro();

    this._service.listapag(1,10000,filtro).subscribe({
      next: (res) => {

        this.tarjetas = res.data;

        this.imprimirTarjetas();

        this._loadService.notifyLoadChange(false);
      },
      error: (err) => {
        console.log(err.error);        
        this._loadService.notifyLoadChange(false);
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }
 
  getUltima() {
    this._loadService.notifyLoadChange(true);

    this._service.ultima(this.formGroup.controls.annioActual.value??0).subscribe({
      next: (res) => {
          this.formGroup.controls.hasta.setValue(res.data[0].total??0);
        //}

        this._loadService.notifyLoadChange(false);
      },
      error: (err) => {
        console.log(err.error);        
        this._loadService.notifyLoadChange(false);
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }

  confirmarImprimir(content : any) {
    this.modalService.open(content, { centered: true }).result.then(
			(result) => {
        this.getTarjetas();
			},
			(reason) => {
      },
		);
  }

  imprimirTarjetas() {
    //this.imprimirTarjeta = false;
    if (this.tarjetas) {
      this.maxProgreso = this.tarjetas.length;
      this.actualProgreso = 50;

      if (this.maxProgreso > 0) {
        this.verProgeso = true;

        this.tarjetas.forEach((element: any, i : number) => {
            if (i == this.tarjetas.length-1) {
              element['visualiza'] = false;
            }
            else {
              element['visualiza'] = true;
            }
            this.actualProgreso = ((i+1)*100)/this.maxProgreso;

          });

          timeoutTime((500)).then(() => {
            this._loadService.notifyLoadChange(false);
            this.imprimirTarjeta = true;
          });
          this.imprimirTarjeta = false;
      }

      this.verProgeso = false;
    }
  }

}
