import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
  // Nombre encima del input
  @Input() label!:string;
  // Control del usuario dónde se define las propiedades y los validadores
  @Input() control = new FormControl();
  // Name para el input
  @Input() name!:string;
  // Es opcional y permite indicar un ancho máximo para el campo
  @Input() maxWidth!:string;
  // Es opcional y permite indicar un alto máximo para el campo
  @Input() maxHeight!:string;
  // Id del campo
  @Input() id!:string;

  // Define los tipos y el mensaje de error a mostrar
  errorMessages: Record<string, string> = {
    required: "Este campo es obligatorio",
    email: "Este campo no es válido",
    minlength: "Este campo no es lo suficientemente largo",
    pattern: "Formato no válido"
  }
}
