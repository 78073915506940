import { IUsuario } from './../interface/usuario.interface';


export class UsuarioModel implements IUsuario {
  id: number;
  email: string;
  dni: string;
  nombre: string;
  activo: boolean;
  telefono: string;
  password: string;
  avatar: string;
  role: number;
  domicilio: string;
  codigoPostal: string;
  localidad: string;
  provincia: string;
  observaciones: string;
  constructor(
    id: number,
    email: string,
    dni: string,
    nombre: string,
    activo: boolean,
    telefono: string,
    password: string,
    avatar: string,
    role: number,
    domicilio: string,
    codigoPostal: string,
    localidad: string,
    provincia: string,
    observaciones: string,
  ) {
    this.id = id;
    this.email = email;
    this.dni = dni;
    this.nombre = nombre;
    this.activo = activo;
    this.telefono = telefono;
    this.password = password;
    this.avatar = avatar;
    this.role = role;
    this.domicilio = domicilio;
    this.codigoPostal = codigoPostal;
    this.provincia = provincia;
    this.localidad = localidad;
    this.observaciones = observaciones;
  }
}
