<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4">
    <h6 *ngIf="es_nuevo" class="mb-0">Registro de {{titulo}}</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualización de {{titulo}}</h6>
    <div class="form-check form-switch form-check">
      <input
        #activo
        name="activo"
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="tipo_cliente.activo"
        id="activo"
        (change)="toggle_activo(!this.tipo_cliente.activo)"
      >
      <label *ngIf="tipo_cliente.activo" class="form-check-label" for="activo">Activo</label>
      <label *ngIf="!tipo_cliente.activo" class="form-check-label" for="activo">Inactivo</label>
    </div>
  </div>
  <div class="form-body-three-cols mt-3">
    <app-input
      [control]="formGroup.controls.nombre"
      [label]="'Nombre *'"
      [type]="'text'"
      [id]="'nombre'">
    </app-input>
    <div class="">
      <app-color-picker
        [control]="formGroup.controls.color"
        [label]="'Color *'"
        [name]="'color'"
        [maxWidth]="'38px'"
        [maxHeight]="'38px'"
        [id]="'color'">
      </app-color-picker>
    </div>
  </div>
  <div class="d-flex gap-3 justify-content-start">
    <button type="submit" class="btn btn-aceptar mt-5">
      <span  *ngIf="es_nuevo">
        Crear
      </span>
      <span  *ngIf="!es_nuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/tipo-cliente'" class="btn btn-outline-error mt-5" >
      Cancelar
    </a>
  </div>
</form>

<app-lineal-component-selector *ngIf="!es_nuevo"
  [componentsLabel]="['Documentación']"
  [dynamicComponent]="[tipoDocu]"
></app-lineal-component-selector>
