import { Component, Input } from '@angular/core';
import { TableField } from 'src/app/models/entity/tableField.model';
import { tiposValidado } from 'src/global';

@Component({
  selector: 'app-item-table-string',
  templateUrl: './item-table-string.component.html',
  styleUrls: ['./item-table-string.component.scss']
})
export class ItemTableStringComponent {
  @Input() tableField!: TableField
  @Input() column!: number
  @Input() row!: number
  @Input() data!: any
  @Input() labelsValidado: any[] = [];
  @Input() editableObservaciones: boolean = false;
  tipo_validado = tiposValidado;
  ngOnInit() {
  }

  tipoValidado(value: number): string {
    return tiposValidado[value as keyof typeof tiposValidado] || 'Desconocido';
  }
}
