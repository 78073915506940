import { Component } from '@angular/core';
import { deleteToken } from 'src/app/services/helpers/session.helper';

@Component({
  selector: 'app-admin-options',
  templateUrl: './admin-options.component.html',
  styleUrls: ['./admin-options.component.scss']
})
export class AdminOptionsComponent {

  cerrarSesion() {
    deleteToken();
  }
}
