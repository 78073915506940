<ng-container *ngIf="tableField.type === ''" >
  <!-- crea un div por cada campo que hay en la columna. Los siguientes al primeros tendrán menor tamaño -->
  <div
    [ngClass]="{ 'text-start' : tableField.align == 'start',
                  'text-center' : tableField.align == 'center',
                  'text-end' : tableField.align == 'end',
                  }">
    <span *ngIf=" column === 0">{{ data[row][tableField.name] }}</span>
    <span *ngIf=" column !== 0" class="text-muted text-small"> {{data[row][tableField.name]}}</span>
  </div>
</ng-container>
<!-- Mostrar datos complejos-->
<ng-container *ngIf="tableField.type=== 'numberLabel'">
  <app-item-table-number-name
    [value]="data[row][tableField.name]"
    [labels]="labelsValidado"
  />
</ng-container>
<!-- Tipo Boolean -->
<ng-container *ngIf="tableField.type=== 'boolean'">
  <span *ngIf="data[row][tableField.name] === true"> ✅ </span>
  <span *ngIf="data[row][tableField.name] === false"> ❌ </span>
</ng-container>
<!--TipoBadgesuccess-->
<ng-container *ngIf="tableField.type=== 'badge-success'">
  <span class="badge badge-success">{{ data[row][tableField.name] }}</span>
</ng-container>
<!--TipoMoney-->
<ng-container *ngIf="tableField.type=== 'money'">
  <span class="">{{ data[row][tableField.name] | number : '1.2-2' }}</span>
</ng-container>
<!-- TipoEstado-->
<!-- No se usa -->
<ng-container *ngIf="tableField.type=== 'estado'">
  <span class="">{{ data[row][tableField.name] }}</span>
</ng-container>
<ng-container *ngIf="tableField.type=== 'badge-dinamyc'">
  <span
    [ngStyle]="{ 'backgroundColor:': data[row][tableField.name] }"
    class="badge badge-success"
    >{{ data[row][tableField.name] }}</span
  >
</ng-container>
<!--TipoBadgeerror-->
<ng-container *ngIf="tableField.type=== 'badge-error'">
  <span class="badge badge-danger">{{ data[row][tableField.name] }}</span>
</ng-container>
<!-- TipoColor-->
<ng-container *ngIf="tableField.type=== 'color'">
  <div
    style="width: 40px; height: 20px; margin: auto"
    class="rounded border"
    [ngStyle]="{
      backgroundColor:
        data[row][tableField.name] !== '' ? data[row][tableField.name] : 'white'
    }"
  ></div>
</ng-container>
<!-- TipoValidado-->
<ng-container *ngIf="tableField.type=== 'validado'">
  <div
    style="width: 100%; height: 20px; margin: auto"
  ><small>
    {{ tipoValidado(data[row][tableField.name]) }}
  </small>
  </div>
</ng-container>

    <!-- Botón de ver y editar observaciones para el administrador
    <button
      *ngIf="role === 2"
      class="btn btn-sm btn-outline-primary"
      style="margin-block: 1px; margin-left: 5px;height: fit-content;"
      (click)="
        openObservaciones(
          formObservaciones,
          data[row][tableField.name],
          row[idName]
        )
      "
    >
      <fa-icon class="bell" [icon]="observacionIcon"></fa-icon>
    </button>
    <span *ngIf="data[row][tableField.name] !== '' && role === 1">
      ¡Nueva observacion!
    </span>
  </div>
</ng-container>-->
