import { Component } from '@angular/core';
import { UsuariosService } from './../../../../services/https/usuarios.services';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { createUsuario } from 'src/app/services/helpers/model.helper';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LoadService } from 'src/app/services/helpers/load.service';
import { globalState } from 'src/global';

@Component({
  selector: 'app-usuarios-page',
  templateUrl: './usuarios-page.component.html',
  styleUrls: ['./usuarios-page.component.scss'],
  providers: [UsuariosService]
})
export class UsuariosPageComponent {
  typeForm: string = '';
  data: UsuarioModel[] = [];

  // Paginacion
  pagina : number = 1;
  filtro : string = '';
  outFiltro : string = '';
  totalRegistros : number = 100;
  registrosPagina : number = 100;
  filtros: any[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: UsuariosService,
    private _loadService: LoadService,
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
      }
    });

    this.registrosPagina = globalState.registrosPagina;
  }

  ngOnInit(){
    if(this.typeForm === '') {
      this.obtainData();
    }
  }

  getFiltro(tablaFiltro: string = '') {
    let filtro = '';

    if (tablaFiltro != '') {
      if (filtro == '') {
        filtro = " where ";
      }
      else {
        filtro = filtro+ "  and ";
      }
      filtro = filtro+ " ((nombre like '%"+tablaFiltro+"%') or "+
                        "(dni like '%"+tablaFiltro+"%'))";
    }

    return filtro;
  }

  obtainData() {
    this._loadService.notifyLoadChange(true);

    this.data = [];
    let nuevoFiltro = this.getFiltro(this.filtro);
    this.outFiltro = nuevoFiltro;

    this._service.listapag(this.pagina,this.registrosPagina,nuevoFiltro).subscribe({
      next: (res) => {
        res.data.forEach((element: any) => {
          this.data.push(
            createUsuario(element)
          );
        });
      this._loadService.notifyLoadChange(false);
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);
        console.log(err);
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }


  recibePagina(pagina: number) {
    this.pagina = pagina;
    this.obtainData();

  }

  recibeFiltro(filtro: [string, string]) {

    this.filtro = filtro[0];
    this.obtainData();

  }

}
