<app-page-container-publico
  class="solo-pantalla"
  [title]="'Utilidades'"
  >
  <div class="container-fluid">
    <ng-container *ngIf="typeForm === ''">
      <!--<h3
        *ngIf="allData.length <= 0"
        class="text-gray text-center mt-5"
      >
        No hay tarjetas registradas
      </h3>-->

      <div class="mt-5">
        <h3>Imprimir tarjetas</h3>

        <div class="row">
            <app-input class="col"
                [control]="formGroup.controls.desde"
                [label]="'Desde'"
                [type]="'number'"
                [disabled]="false"
                [id]="'desde'">
            </app-input>

            <app-input class="col"
                [control]="formGroup.controls.hasta"
                [label]="'Hasta'"
                [type]="'number'"
                [disabled]="false"
                [id]="'hasta'">
            </app-input>

            <app-input class="col"
                [control]="formGroup.controls.zona"
                [label]="'Zona'"
                [type]="'number'"
                [disabled]="false"
                [id]="'zona'">
            </app-input>

            <app-input class="col"
                [control]="formGroup.controls.annio"
                [label]="'Año'"
                [type]="'number'"
                [disabled]="false"
                [id]="'annio'">
            </app-input>

            <div class="col mt-5 d-flex flex-wrap justify-content-center align-content-center">
                <app-reverse-switch
                [label]="'Salto página'"
                [control]="formGroup.controls.saltopagina"
                [id]="'calle-encontrada'"
                />
            </div>

            <div class="col ">
                <div class="row align-items-end h-100 ">
                    <div class="col col-md-6 col-lg-3 ">
                        <a class="btn btn-success"  (click)="confirmarImprimir(formConfirmaImprimir);">Imprimir</a>
                    </div>
                </div>
            </div>

        </div>

        <div class="progress mt-3" *ngIf="verProgeso">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: {{actualProgreso}}%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{{porcentajeProgreso}}%</div>
          </div>

        </div>

    </ng-container>
  </div>
</app-page-container-publico>


<!-- The Modal -->
<ng-template #formConfirmaImprimir let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirmar Impresión</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('')"></button>
    </div>
    <div class="modal-body">

        ¿Seguro que desea imprimir las tarjetas?
        <br/>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="modal.close('')">Imprimir</button>
    </div>
</ng-template>


<!-- Impresión: Solo será accesible desde la tabla.
<div *ngFor="let tarjeta of tarjetas" class="mt-5" >
    <tarjeta-print

    id="tarjeta"
    #tarjeta
    [tarjetaId]="tarjeta.id"
    [annio]="formGroup.controls.annio.value??2024"
    [numero]="tarjeta.numero"
    [zonaId]="tarjeta.zonaId"
    [zonaAbreviatura]="tarjeta.zonaAbreviatura"
    [matricula]="tarjeta.matricula"
    [color]="tarjeta.zonaColor"
    [visualiza]="tarjeta.visualiza"
    [saltopagina]="formGroup.controls.saltopagina.value??true"
    [imprime]="imprimirTarjeta"
    />
</div>
-->
