<label for="{{id}}">{{label}}</label>
<input
    [class.invalid]="control.invalid && control.dirty && control.touched"
    [formControl]="control"
    type="color"
    [id]="id"
    [name]="name"
    [ngStyle]="{
      '--color-picker-width': maxWidth ? maxHeight : '100%',
      '--color-picker-height': maxHeight ? maxHeight : '100%',
    }"
    style="padding:0.375rem;;"
    class="form-control"
    [defaultValue]="'#000'"
/>
