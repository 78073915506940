import { IConfigGeneral } from './../interface/configuracion.interface';

export class ConfigGeneralModel implements IConfigGeneral {
  id: number;
  nombre: string;
  cif: string;
  domicilio: string;
  codigoPostal: string;
  localidad: string;
  provincia: string;
  web: string;
  email: string;
  telefono: string;
  notificacionesEmail: string;
  notificacionesHost: string;
  notificacionesPuerto: number;
  notificacionesUsuario: string;
  notificacionesPassword: string;
  registroMercantil: string;
  annioActual: number;
  facturaSerie: string;
  consentimiento: string;
  condiciones: string;
  mapa: string;

  constructor(
    id: number,
    nombre: string,
    cif: string,
    domicilio: string,
    codigoPostal: string,
    localidad: string,
    provincia: string,
    web: string,
    email: string,
    telefono: string,
    notificacionesEmail: string,
    notificacionesHost: string,
    notificacionesPuerto: number,
    notificacionesUsuario: string,
    notificacionesPassword: string,
    registroMercantil: string,
    annioActual: number,
    facturaSerie: string,
    consentimiento: string,
    condiciones: string,
    mapa: string,
  ) {
    this.id = id;
    this.nombre = nombre;
    this.cif = cif;
    this.domicilio = domicilio;
    this.codigoPostal = codigoPostal;
    this.localidad = localidad;
    this.provincia = provincia;
    this.web = web;
    this.email = email;
    this.telefono = telefono;
    this.notificacionesEmail = notificacionesEmail;
    this.notificacionesHost = notificacionesHost;
    this.notificacionesPuerto = notificacionesPuerto;
    this.notificacionesUsuario = notificacionesUsuario;
    this.notificacionesPassword = notificacionesPassword;
    this.registroMercantil = registroMercantil;
    this.annioActual = annioActual;
    this.consentimiento = consentimiento;
    this.condiciones = condiciones;
    this.mapa = mapa;
    this.facturaSerie = facturaSerie
  }

}
