import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';

@Injectable()
export class ActualizaServices {
  public url: string = globalState.url ;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  actualizaObservaciones(id: number, observaciones: string, table: string): Observable<any> {
   const params = new HttpParams()
      .set('id', id)
      .set('observaciones', observaciones)

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(`${this.url + table}/actualizaObservaciones`, params, httpOptions);
  }

  actualizaObservacionesUsuario(id: number, observaciones: string, table: string): Observable<any> {
   const params = new HttpParams()
      .set('id', id)
      .set('observacionesusuario', observaciones)

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(`${this.url + table}/actualizaObservacionesUsuario`, params, httpOptions);
  }

  actualizaValidado(id: number, value: number): Observable<any> {
   const params = new HttpParams()
      .set('id', id)
      .set('validado', value)

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(`${this.url}documentos/actualizaValidado`, params, httpOptions);
  }
}
