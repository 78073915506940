import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DocumentoModel } from 'src/app/models/entity/documento.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { DocumentosService } from 'src/app/services/https/documentos.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-modal-edita-observaciones',
  templateUrl: './modal-edita-observaciones.component.html',
  styleUrls: ['./modal-edita-observaciones.component.scss'],
  providers: [DocumentosService]
})
export class ModalEditaObservacionesComponent {
  @Input() id!: number;
  @Input() observaciones!: string;
  @Input() es_administrador!: boolean
  @Output() guardado: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public active_modal: NgbActiveModal,
    private _documeto_service: DocumentosService,
    private _toast: ToastrService,
    private _loader: LoadService
  ) {}

  form_group = new FormGroup({
    // Variables para el formulario
    edita_observaciones: new FormControl<string>(this.observaciones, [Validators.required]),
  });

  ngOnInit() {
    this.form_group.patchValue({
      edita_observaciones: this.observaciones
    });
  }

  async guardar_observaciones(): Promise<void> {
    this._loader.notifyLoadChange(true);
    const observaciones = this.form_group.value.edita_observaciones;
    if (observaciones !== undefined && observaciones !== null) {
      try {
        const data = await this._documeto_service.actualiza_observaciones(this.id, observaciones);
        if (data !== null)
          this.guardado.emit(true);
      } catch (e:any) {
        this._toast.error(e);
        this._loader.notifyLoadChange(false);
      }
    }
  }
}
