<ul class="nav nav-tabs">
  <li
    *ngFor="let label of componentsLabel; index as i"
    class="nav-item selected"
    [ngClass]="{ selected: activeLabel === label }"
    (click)="generateComponent(i)"
  >
  {{label}}
</li>
</ul>
<div class="component-container" >
  <ng-template appDynamicComponent></ng-template>
</div>
