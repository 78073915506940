<div class="position-relative">
  <!-- Desktop-->
  <div class="container-fluid">
    <div class="gap-5 pt-3 pb-3 menu-desktop clear-fix px-3">
      <div class="">
        <a routerLink="/">
          <img
            class="header-img"
            src="../../../../../assets/logo_limusa.png"
            alt="logo-limusa"
          />
        </a>
      </div>
      <nav class="header-links-container">
        <a
          role="button"
          [ngClass]="{ selected: rutaActiva === '/' }"
          class="header-link-item"
          [routerLink]="'/'"
          >Inicio</a
        >
        <a
          role="button"
          [ngClass]="{ selected: rutaActiva === '/noticias' }"
          class="header-link-item"
          >Noticias</a
        >
        <a
          role="button"
          [ngClass]="{ selected: rutaActiva === '/contacto' }"
          class="header-link-item"
          >Contacto</a
        >
        <a *ngIf="identity === undefined" routerLink="login" class="btn btn-login">Identificate</a>
        <app-usuario-options *ngIf="identity !== undefined"></app-usuario-options>
      </nav>
    </div>

    <!-- Mobile -->
    <div class="menu-mobile">
      <!-- Menu mobile-->
      <div class="menu-mobile-abs" [ngClass]="{ active: mobileMenuActive }">
        <div class="menu-mobile__header underline-border">
          <div class="d-flex justify-content-between pr-3">
            <img
              class="logo-mobile"
              src="../../../../../assets/logo_limusa.png"
              alt="logo-limusa"
            />
            <fa-icon
              [icon]="menuCloseIcon"
              (click)="toggleMenuMobile()"
            ></fa-icon>
          </div>
        </div>
        <div class="menu-mobile__body">
          <nav class="header-links-container">
            <a [ngClass]="{'selected' : rutaActiva === '/'}" class="header-link-item" href="">Inicio</a>
            <a class="header-link-item" href="">Noticias</a>
            <a class="header-link-item" href="">Contacto</a>
            <a *ngIf="identity === undefined" routerLink="login" class="btn btn-login">Identificate</a>
            <app-usuario-options *ngIf="identity !== undefined"></app-usuario-options>
          </nav>
        </div>
      </div>
      <!-- Fin del menu-->
      <a routerLink="/">
        <img
          class="logo-mobile"
          src="../../../../../assets/logo_limusa.png"
          alt="logo-limusa"
        />
      </a>
      <fa-icon (click)="toggleMenuMobile()" [icon]="menuIcon"></fa-icon>
    </div>
  </div>
</div>
