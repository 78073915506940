<app-page-container-publico [title]="'Documentos'">
  <div class="container-fluid" *ngIf="documento !== null">
    <div class="d-flex flex-wrap align-items-center justify-content-between mt-5 bg-secondary p-2 toolbar" style="--bs-bg-opacity: 0.1; border-radius: 5px;">
      <div *ngIf="roleAdmin" class="btn-group btn-documentos" role="group">
        <button [ngClass]="{'btn-light': documento.valida_id !== 0}" type="button" (click)="actualiza_validado(0)" class="btn btn-warning">Pendiente</button>
        <button [ngClass]="{'btn-light': documento.valida_id !== 1}" type="button" (click)="actualiza_validado(1)" class="btn btn-success">Validado</button>
        <button [ngClass]="{'btn-light': documento.valida_id !== 2}"type="button" (click)="actualiza_validado(2)" class="btn btn-danger">Rechazado</button>
      </div>
      <div *ngIf="!roleAdmin" class="btn-group btn-documentos" role="group">
        <div [ngClass]="{'not-show': documento.valida_id !== 0}" class="btn btn-warning">Pendiente</div>
        <div [ngClass]="{'not-show': documento.valida_id !== 1}" class="btn btn-success">Validado</div>
        <div [ngClass]="{'not-show': documento.valida_id !== 2}" class="btn btn-danger">Rechazado</div>
      </div>
      <div class="d-flex gap-2">
        <button
          class="btn btn-primary"
          type="button"
          (click)="obten_archivo()"
        >
          <fa-icon [icon]="faDownload" />
        </button>
        <a
          [routerLink]="volver"
          class="btn btn-light"
          type="button"
          >
          <fa-icon [icon]="faVolver" />
          Volver
        </a>
      </div>
    </div>

    <ng-container *ngIf="roleAdmin">
      <div class="observaciones-container">
        <div class="obs-usuario my-5" *ngIf="documento.observacionesUsuario !== ''">
          <h6>Observaciones del usuario</h6>
          <div [innerHTML]="observaciones_usuario"></div>
        </div>
        <div class="obs-vista-admin my-5" *ngIf="documento.observacionesUsuario === ''">
          <h6 class="text-center">Sin observaciones del usuario</h6>
        </div>
        <app-form-admin-docs class="form__observaciones my-5" [id]="id" [observaciones]="documento.observaciones"/>
      </div>
    </ng-container>

    <ng-container *ngIf="!roleAdmin">
      <div class="obs-usuario my-5" *ngIf="documento.observacionesUsuario !== ''">
        <h6>Observaciones del administrador</h6>
        <div [innerHTML]="observaciones_admin"></div>
      </div>
      <div class="my-5" *ngIf="documento.observacionesUsuario === ''">
        <h6 class="text-center">Sin observaciones del administrador</h6>
      </div>
      <app-form-user-docs class="form__observaciones my-5" [id]="id" [observaciones]="documento.observacionesUsuario"/>
    </ng-container>


    <div *ngIf="'visor' && documento.archivo.includes('pdf')" class="mt-3">
      <h6 class="mb-3">Documento: </h6>
      <app-pdf-viewer [src]="archivo_data"> </app-pdf-viewer>
    </div>
    <ng-container *ngIf="'visor' && !documento.archivo.includes('pdf')">
      <div class="d-flex justify-content-center">
        <img class="img-fluid mx-auto" style="max-height: 980px;" [src]="archivo_data" alt="" />
      </div>
    </ng-container>
  </div>
</app-page-container-publico>
