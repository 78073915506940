import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-modal-cambio-matricula',
  templateUrl: './modal-cambio-matricula.component.html',
  styleUrls: ['./modal-cambio-matricula.component.scss'],
  providers: [SolicitudesServices]
})
export class ModalCambioMatriculaComponent {
  @Input() id!: number;

  constructor(
    public active_modal: NgbActiveModal,
    private _solicitudes_services: SolicitudesServices,
    private _nav: Router,
    private _load_service: LoadService,
    private _toast: ToastrService
  ) {}

  form = new FormGroup({
    matricula: new FormControl <string>('', [Validators.required]),
    modelo: new FormControl <string>('', [Validators.required]),
    marca: new FormControl <string>('', [Validators.required]),
    error: new FormControl <string>('', [Validators.required]),
  })

  async cambia_matricula() {
    this._load_service.notifyLoadChange(true);
    if (this.form.value.marca === '' ||
      this.form.value.modelo === '' ||
      this.form.value.matricula === ''
    ) {
      this.form.controls.error.setValue('¡Formulario incompleto! Revisa todos los campos');
    } else {
      this._load_service.notifyLoadChange(true);
      this.form.controls.error.setValue('');
      try {
        const nueva_id = await this._solicitudes_services.cambia_matricula(this.id,this.form.value.matricula ?? '',this.form.value.marca ?? '',this.form.value.modelo ?? '',globalState.identity?.id ?? 0);
        this._nav.navigate(['solicitudes/'+ nueva_id])
        this._toast.success("¡Cambio de matricula generado!")
      } catch (e: any) {
          this._toast.error(e);
      } finally {
        this._load_service.notifyLoadChange(true);
      }
    }
  }
}
