<div class="form-group mb-0">
  <div class="col">
    <div class="row">
      <div class="col-12 ">
        <label [htmlFor]="id" class="align-bottom">{{label}}</label>
      </div>
      <div class="col-6 text-end " *ngIf="avisoRight">
       <ng-container class=" " *ngFor="let err of control.errors | keyvalue"  >
           <div class="error-message" [ngClass]="{'not-show' : !control.touched && control.invalid}">
            <span *ngIf="errorMessages[err.key]" >{{errorMessages[err.key]}}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <input
    [class.invalid]="control.invalid && control.dirty && control.touched"
    [formControl]="control"
    [id]="id"
    [placeholder]="placeholder"
    [step]="step"
    type="{{type}}"
    class="form-control"
    [maxlength]="maxLength > 0 ? maxLength : null"
    (blur)="isBlurValidation ? this.validation() : undefined"
    >
    <div *ngIf="!avisoRight">
      <ng-container class="position-relative" *ngFor="let err of control.errors | keyvalue"  >
        <div class="position-absolute error-message" [ngClass]="{'not-show' : !control.touched && control.invalid}">
          <p *ngIf="errorMessages[err.key]">{{errorMessages[err.key]}}</p>
      </div>
    </ng-container>
  </div>
</div>


