<div class="usermenu-container">
  <a class="usermenu-item"> Avisos </a>
  <a class="usermenu-item" [routerLink]="'/informes'"> Informes </a>
  <a class="usermenu-item" [routerLink]="'/solicitudes'"> Solicitudes </a>
  <a class="usermenu-item" [routerLink]="'/tarjetas'"> Tarjetas </a>
  <a class="usermenu-item" [routerLink]="'/tipo-cliente'"> Tipo de Cliente </a>
  <a class="usermenu-item" [routerLink]="'/tipo-documentacion'">Tipo de Documentación</a>
  <a class="usermenu-item" [routerLink]="'/usuarios'">Usuarios</a>
  <a class="usermenu-item" [routerLink]="'/zonas'"> Zonas </a>
  <a class="usermenu-item" [routerLink]="'/configuraciones'"> Configuración </a>
  <a class="usermenu-item" [routerLink]="'/utilidades'"> Utilidades </a>
  <a class="usermenu-item" (click)="cerrarSesion()"> Cerrar sesión </a>
</div>
