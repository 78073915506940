import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createSolicitudView } from 'src/app/services/helpers/model.helper';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-informes-page',
  templateUrl: './informes-page.component.html',
  styleUrls: ['./informes-page.component.scss'],
  providers: [SolicitudesServices]
})
export class InformesPageComponent implements OnInit{

  constructor(
    private _loadService: LoadService,
    private _service: SolicitudesServices,
  ) {}

  data: SolicitudView[] = [];
  count: number = 0;
  totalTpv: number = 0;
  totalEfectivo: number = 0;
  totalOnline: number = 0;

  formGroup = new FormGroup({
    fechaInicio:  new FormControl<string>(this.convertToSqlDate(new Date().toLocaleDateString()), []),
    fechaFin:  new FormControl<string>(this.convertToSqlDate(new Date().toLocaleDateString()), []),
  });

  async ngOnInit() {

    let filtro = this.getFiltro(this.formGroup.value.fechaInicio??'',this.formGroup.value.fechaFin??'');
    this._loadService.notifyLoadChange(true)

    try {
      this.data = await this.getData(filtro);
      this.count = await this.getCount()
    } catch (e) {
      console.log(e)
    } finally {
      this._loadService.notifyLoadChange(false)
    }
  }

  getFiltro(fechaInicio: string, fechaFin: string): string {
    let filtro = "WHERE estado = 3 AND total > 0";
    let inicio = this.convertToTypeDate(fechaInicio);
    // let inicioSql = this.convertToSqlDate(fechaInicio)
    let fin = this.convertToTypeDate(fechaFin);
    // let finSql = this.convertToSqlDate(fechaFin);

    if(fechaInicio == fechaFin) {
       filtro += ` AND fecha_pagado IS NOT NULL AND fecha_pagado = "${fechaInicio}"`;
      }
    if(inicio < fin) {
      filtro += ` AND fecha_pagado BETWEEN "${fechaInicio}" AND "${fechaFin}"`;
    } if (fin < inicio) {
      filtro += ` AND fecha_pagado BETWEEN "${fechaFin}" AND "${fechaInicio}"`;
    }
    return filtro;
  }

  async getData(filtro: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      this._service.getInforme(filtro).subscribe({
        next: (res) => {
          let data: SolicitudView[] = [];
          res.data.forEach((element: any) => {
            data.push(
              createSolicitudView(element)
            )
            if (element.tipo_pago === "Tarjeta") this.totalTpv++;
            if (element.tipo_pago === "Efectivo") this.totalEfectivo++;
            if (element.tipo_pago === "Online") this.totalOnline++;
          });
          resolve(data)
        },
        error: (err) => {
          console.log(err);
          reject(err.error.message)
        },
      })
    })
  }

  async getCount(): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      this._service.getCount(globalState.annioActual.toString()).subscribe({
        next: (res) => {
          resolve(res.data)
        },
        error: (err) => {
          console.log(err);
          reject(err.error.message)
        }
      })
    })
  }

  convertToTypeDate(fecha: String): Date {
    let partes = fecha.split('-');
    let year = Number(partes[0])
    let month = Number(partes[1]) - 1;
    let day = Number(partes[2])
    return new Date(year, month, day);
  }

  convertToSqlDate(fecha: string): string {
    let partes = fecha.split('/');
    let day = Number(partes[0])
    let month = Number(partes[1]);
    let year = Number(partes[2])
    let dayStr = day < 10 ?`0${day}` : `${day}`;
    let monthStr = month < 10 ?`0${month}` : `${month}`;
    return `${year}-${monthStr}-${dayStr}`;
  }
}
