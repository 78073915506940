<div class="modal-header">
  <h4 class="modal-title">Mapa de Zona Verde</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body w-100">
  <div class="modal-image__container mx-auto">
    <app-slider [sliders]="slider_mapas" (eventSlide)="selecciona_zona.emit($event)" />
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="active_modal.close('')"
  >
    Cancelar
  </button>
</div>
