<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Nuevo Usuario</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  <app-input
    [control]="formGroup.controls.nombre"
    [label]="'Nombre completo*'"
    [type]="'text'"
    [id]="'nombre'"
    [avisoRight]="false"
  />
  <div class="separator">
    <app-input
      [control]="formGroup.controls.dni"
      [label]="'DNI/NIE*'"
      [type]="'text'"
      [id]="'dni'"
      [avisoRight]="false"
    />
  </div>

  <div class="separator">
    <app-input
      [control]="formGroup.controls.email"
      [label]="'Email'"
      [type]="'email'"
      [id]="'email'"
      [avisoRight]="false"
    />
  </div>
  <div class="separator">
    <app-input
      [control]="formGroup.controls.telefono"
      [label]="'Telefono'"
      [type]="'tel'"
      [id]="'telefono'"
      [avisoRight]="false"
    />
  </div>

  <div class="separator">
    <app-input
      [control]="formGroup.controls.codigoPostal"
      [label]="'Código Postal'"
      [type]="'number'"
      [id]="'codigoPostal'"
      [avisoRight]="false"
    />
  </div>

  <div class="separator">
    <app-input
      [control]="formGroup.controls.localidad"
      [label]="'Localidad'"
      [type]="'text'"
      [id]="'localidad'"
      [avisoRight]="false"
    />
  </div>

  <div class="separator">
    <app-input
      [control]="formGroup.controls.provincia"
      [label]="'Provincia'"
      [type]="'text'"
      [id]="'provincia'"
      [avisoRight]="false"
    />
  </div>

  <div class="separator">
    <app-input
      [control]="formGroup.controls.domicilio"
      [label]="'Domicilio'"
      [type]="'text'"
      [id]="'domicilio'"
      [avisoRight]="false"
    />
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="crea_usuario()"
    [disabled]="
      formGroup.controls.nombre.value == '' &&
      formGroup.controls.dni.value == ''
    "
  >
    Crear
  </button>
</div>
