import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-modal-cambio-domicilio',
  templateUrl: './modal-cambio-domicilio.component.html',
  styleUrls: ['./modal-cambio-domicilio.component.scss'],
  providers: [SolicitudesServices, ZonasService]
})
export class ModalCambioDomicilioComponent {
  @Input() id!: number;
  zonas_data: ZonasModel[] = [];

  constructor(
    public active_modal: NgbActiveModal,
    private _solicitudes_services: SolicitudesServices,
    private _zonas_services: ZonasService,
    private _nav: Router,
    private _load_service: LoadService,
    private _toast: ToastrService
  ) { }

  form = new FormGroup({
    calle: new FormControl<string>('', [Validators.required]),
    error: new FormControl<string>('', [Validators.required]),
    zona_id: new FormControl<number>(0, [Validators.required]),
  })

  async ngOnInit(): Promise<void> {
    try {
      this._load_service.notifyLoadChange(true);
      const { zonas } = await this._zonas_services.lista_completa();
      this.zonas_data = zonas;
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._load_service.notifyLoadChange(false);
    }
  }

  async cambia_domicilio() {
    this._load_service.notifyLoadChange(true);
    if (this.form.value.calle === '' ||this.form.value.zona_id === 0) {
      this.form.controls.error.setValue('¡Formulario incompleto! Revisa todos los campos');
    } else {
      this._load_service.notifyLoadChange(true);
      this.form.controls.error.setValue('');
      try {
        const nueva_id = await this._solicitudes_services.cambia_domicilio(this.id, this.form.value.calle ?? '', this.form.value.zona_id ?? 0, globalState.identity?.id ?? 0);
        console.log(nueva_id)
        this._nav.navigate(['solicitudes/' + nueva_id])
        this._toast.success("¡Cambio de domicilio generado!")
      } catch (e: any) {
        this._toast.error(e);
      } finally {
        this._load_service.notifyLoadChange(false);
      }
    }
  }
}
