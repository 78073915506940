<app-page-container-publico  [title]="'Usuarios'">
  <div class="container-fluid px-5">
    <ng-container *ngIf="typeForm === ''">
      <!-- <app-table-default
        [data] = data
        [tableColumns] = "['nombre', 'email', 'dni', 'activo']"
        [tableItemType]="['','','','boolean']"
        [labelColumns] = "['Nombre', 'Email', 'DNI/CIF', 'Activo']"
        [alignColumns]="['start','start','centar','']"
        [widthColumns] = "[]"
        [searcher] = "['nombre', 'dni']"
        [tableName] = "'usuarios'"
        [isPaginacion] = "true"
        [inPagina] = "pagina"
        [inFiltro]="outFiltro"
        [filtroDinamicoPr]="filtros"
        [showEditButton]="true"
        [showDeleteButton]="true"
        (outPagina)="recibePagina($event)"
        (outFiltro)="recibeFiltro($event)"
        ></app-table-default> -->

    </ng-container>
    <app-edita-mi-usuario *ngIf="typeForm==='mi-usuario'" />
    <app-nuevo-edita-usuario *ngIf="typeForm!== '' && typeForm!== 'mi-usuario'"></app-nuevo-edita-usuario>
  </div>
</app-page-container-publico>
