import { CalleModel } from "src/app/models/entity/calle.model";
import { ConfigGeneralModel } from "src/app/models/entity/configuracion.model";
import { DocumentoModel } from "src/app/models/entity/documento.model";
import { SolicitudModel } from "src/app/models/entity/solicitud.model";
import { TarjetaModel } from "src/app/models/entity/tarjeta.model";
import { TipoClienteModel } from "src/app/models/entity/tipoCliente.model";
import { TipoDocuModel } from "src/app/models/entity/tipoDocu.model";
import { TipoDocuClienteModel } from "src/app/models/entity/tipoDocuCliente.model";
import { UsuarioModel } from "src/app/models/entity/usuario.model";
import { SolicitudView } from "src/app/models/entity/views/solicitudes.view";
import { TarjetaView } from "src/app/models/entity/views/tarjetas.view";
import { ZonasModel } from "src/app/models/entity/zonas.model";
import { ICalle } from "src/app/models/interface/calle.interface";
import { ITarjeta } from "src/app/models/interface/tarjeta.interface";
import { ITipoCliente } from "src/app/models/interface/tipoCliente.interface";
import { ITipoDocu } from "src/app/models/interface/tipoDocu.interface ";
import { ITipoDocuCliente } from "src/app/models/interface/tipoDocuCliente.interface ";
import { IUsuario } from "src/app/models/interface/usuario.interface";
import { IZona } from "src/app/models/interface/zona.interface";
import { tiposEstado } from "src/global";

export function createUsuario(model: any) {
  return new UsuarioModel(
    model.id,
    model.email,
    model.dni,
    model.nombre,
    model.activo,
    model.telefono,
    '',
    model.avatar,
    model.role,
    model.domicilio,
    model.codigopostal,
    model.localidad,
    model.provincia,
    model.observaciones
  );
}

export function createZona(model: IZona){
  return new ZonasModel(
    model.id,
    model.nombre,
    model.abreviatura,
    model.activo,
    model.observaciones,
    model.color,
    model.completo_base,
    model.completo_iva,
    model.medio_base,
    model.medio_iva,
    model.mapa
  );
}

export function createTipoClient(model: ITipoCliente){
  return new TipoClienteModel(
    model.id,
    model.nombre,
    model.activo,
    model.color
  );
}

export function createTipoDocu(model: ITipoDocu){
  return new TipoDocuModel(
    model.id,
    model.nombre,
    model.activo,
    model.caducidad,
  );
}

export function createTipoDocuCliente(model: any){
  return new TipoDocuClienteModel(
    model.id,
    model.nombre,
    model.activo,
    model.caducidad,
    model.obligatorio,
    model.observaciones,
    model.tipodocumentacion_id,
    model.tipocliente_id,
    model.cambio_domicilio,
    model.cambio_vehiculo
  );
}

export function createCalle(model: any) {
  return new CalleModel(
    model.id,
    model.nombre,
    model.zona_id
  );
}

export function createSolicitud(model: any) {
  return new SolicitudModel(
    model.id,
    model.usuario_id,
    model.destinatario,
    // Se muestra solo la fecha
    // model.fechasolicitud ? model.fechasolicitud.date.split(' ')[0] : null,
    model.fechasolicitud ? (model.fechasolicitud.date !== '-0001-11-30 00:00:00.000000' ? model.fechasolicitud.date.split(' ')[0] : 'Sin fecha' ) : null,
    model.estado,
    // Se muestra solo la fecha
    model.fechaaceptacion ? (model.fechaaceptacion.date !== '-0001-11-30 00:00:00.000000' ? model.fechaaceptacion.date.split(' ')[0] : 'Sin fecha' ) : null,
    // Se muestra solo la fecha
    model.fechadenegacion ? (model.fechadenegacion.date !== '-0001-11-30 00:00:00.000000' ? model.fechadenegacion.date.split(' ')[0] : 'Sin fecha' ) : null,
    model.tramita_id,
    model.matricula,
    model.tarjeta_id,
    model.importe,
    model.pagada,
    model.annio,
    model.iva,
    model.total,
    model.factura_serie,
    model.factura_numero,
    model.marca,
    model.modelo,
    model.tipocliente_id,
    model.medio,
    model.consentimiento,
    model.zona_id,
    model.calle_id,
    model.domicilio,
    model.documentosPendientes,
    model.renovacion,
    model.documentosCorrectos,
    model.tarjetarenovada,
    model.tiposolicitud,
    model.solicitud_destino,
    model.solicitud_origen,
    model.tipo_pago
  );
}

export function createSolicitudView(model: any) {
  let fechaSolicitud = model.fechaSolicitud ? (model.fechaSolicitud.date ? model.fechaSolicitud.date.split(' ')[0] : null ) : null;
  if (fechaSolicitud) {
    let annio = fechaSolicitud.split(['-'])[0]
    let mes = fechaSolicitud.split(['-'])[1]
    let dia = fechaSolicitud.split(['-'])[2]
    fechaSolicitud = `${dia}-${mes}-${annio}`;
  }

  let fechaAceptacion = model.fechaAceptacion ? (model.fechaAceptacion.date ? model.fechaAceptacion.date.split(' ')[0] : null ) : null;
  if (fechaAceptacion) {
    let annio = fechaAceptacion.split(['-'])[0]
    let mes = fechaAceptacion.split(['-'])[1]
    let dia = fechaAceptacion.split(['-'])[2]
    fechaAceptacion = `${dia}-${mes}-${annio}`;

  }

  let fechaDenegacion = model.fechaDenegacion ? (model.fechaDenegacion.date ? model.fechaDenegacion.date.split(' ')[0] : null ) : null;
  if (fechaDenegacion) {
    let annio = fechaDenegacion.split(['-'])[0]
    let mes = fechaDenegacion.split(['-'])[1]
    let dia = fechaDenegacion.split(['-'])[2]
    fechaDenegacion = `${dia}-${mes}-${annio}`;
  }

  //  = tiposEstado.filter((x) => x.id == model.estado)[0].descripcion ?? 'Pendiente de documentación'

  return new SolicitudView(
    model.id,
    model.codigo,
    fechaSolicitud,
    model.estado,
    // estado,
    fechaAceptacion,
    fechaDenegacion,
    model.tramitaId,
    model.matricula,
    model.tarjetaId,
    model.importe,
    model.pagada,
    model.annio,
    model.numero,
    model.iva,
    model.total,
    model.documentosPendientes,
    model.documentosCorrectos,
    model.facturaSerie,
    model.facturaNumero,
    model.factura_codigo,
    model.marca,
    model.modelo,
    model.tipoClienteId,
    model.medio,
    model.consentimiento,
    model.zonaId,
    model.calleId,
    model.domicilio,
    model.usuarioId,
    model.destinatario,
    model.usuarioEmail,
    model.usuarioDni,
    model.usuarioNombre,
    model.usuarioActivo,
    model.telefono,
    model.usuarioAvatar,
    model.usuarioRole,
    model.usuarioDomicilio,
    model.usuarioCodigoPostal,
    model.usuarioLocalidad,
    model.usuarioProvincia,
    model.usuarioObservaciones,
    model.nombreZona,
    model.tarjeta_numero,
    model.tiposolicitud,
    model.solicitud_destino,
    model.solicitud_origen,
    model.tarjeta_activa,
    model.fecha_pagado != null ? model.fecha_pagado.date.split(' ')[0] : null,
    model.tipo_pago
  );
}


export function createConfigGenereal( model: any) {
  return new ConfigGeneralModel(
    model.id,
    model.nombre,
    model.cif,
    model.domicilio,
    model.codigopostal,
    model.localidad,
    model.provincia,
    model.web,
    model.email,
    model.telefono,
    model.notificaciones_email,
    model.notificaciones_host,
    model.notificaciones_puerto,
    model.notificaciones_usuario,
    model.notificaciones_password,
    model.registro_mercantil,
    model.annio_actual,
    model.factura_serie,
    model.consentimiento,
    model.condiciones,
    model.mapa
  );
}

export function createDocumento(model: any) {
  return new DocumentoModel(
    model.id,
    model.nombre,
    model.solicitud_id,
    model.archivo,
    model.fechaadjuntado,
    model.validado,
    model.valida_id,
    model.fechavalida,
    model.observaciones,
    model.observaciones_usuario,
    model.tipodocumentacion_id,
    model.caduca,
    model.obligatorio,
    model.caducidad
  );
}

export function createTarjetaVista(model: any) {
  return new TarjetaView(
    model.id,
    model.codigo,
    model.annio,
    model.numero,
    model.usuarioId,
    model.destinatario,
    model.fechaCreacion,
    model.activo,
    model.zonaId,
    model.matricula,
    model.modelo,
    model.marca,
    model.tipoclienteId,
    model.facturaSerie,
    model.facturaNumero,
    model.observaciones,
    model.solicitudId,
    model.nombreUsuario,
    model.emailUsuario,
    model.dniUsuario,
    model.avatarUsuario,
    model.roleUsuario,
    model.domicilio,
    model.codigopostal,
    model.localidad,
    model.provincia,
    model.telefono,
    model.observacionesUsuario,
    model.zonaAbreviatura,
    model.zonaColor
  );
}

export function createTarjeta(model: any) {
  return new TarjetaModel(
    model.id,
    model.annio,
    model.numero,
    model.usuario_id,
    model.destinatario,
    model.fechacreacion,
    model.activo,
    model.zona_id,
    model.matricula,
    model.marca,
    model.modelo,
    model.tipocliente_id,
    model.factura_serie,
    model.factura_numero,
    model.observaciones,
    model.nombreUsuario,
    model.emailUsuario,
    model.avatarUsuario,
    model.dniUsuario,
    model.roleUsuario,
    model.solicitudId
  );
}
