import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TarjetaModel } from 'src/app/models/entity/tarjeta.model';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { createTarjetaVista } from 'src/app/services/helpers/model.helper';
import { TarjetaView } from 'src/app/models/entity/views/tarjetas.view';

@Component({
  selector: 'app-check-tarjeta-valida',
  templateUrl: './check-tarjeta-valida.component.html',
  styleUrls: ['./check-tarjeta-valida.component.scss'],
  providers: [TarjetasServices],
})
export class CheckTarjetaValidaComponent {

  id: number = 0;
  tarjeta: TarjetaView = new TarjetaView(
    0,'',0,0,0,'',new Date(), false, 0, '', '', '', 0, '', 0, '', 0, '', '','','',0,'','','','','','','',''
  );

  constructor(
    private _router: ActivatedRoute,
    private _service: TarjetasServices,
  ) {
    this._router.params.forEach((param: Params) => {
      if (param['id'] !== undefined) {
        this.id = Number(param['id']);
        this.obtenTarjeta(this.id);
      }
    })
  }

  obtenTarjeta(id: number) {
    const filtro = id !== 0 ? `WHERE id = ${id}` : '';

    this._service.vista(filtro).subscribe({
      next: (res) => {
          this.tarjeta = createTarjetaVista(res.data[0]);
      },
      error: (err) => {
      },
      complete: () => {

      }
    })
  }
}
