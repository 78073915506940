<div
  [ngClass]="{
    'border-primary': tarjeta.activo,
    'border-danger': !tarjeta.activo
  }"
  class="p-2 rounded border"
  style="--bs-bg-opacity: 0.5;"
  >
  <p>
    Zona:
    <strong>
      {{tarjeta.zonaId}}
    </strong>
  </p>
  <p>
    Activo:
    <strong *ngIf="tarjeta.activo">
      Activo
    </strong>
    <strong *ngIf="!tarjeta.activo">
      Inactivo
    </strong>
  </p>
  <p>
    Matricula:
    <strong>
      {{tarjeta.matricula}}
    </strong>
  </p>
  <p>
    Marca:
    <strong>
      {{tarjeta.marca}}
    </strong>
  </p>
  <p>
    Marca:
    <strong>
      {{tarjeta.modelo}}
    </strong>
  </p>
  <p>
    Nº de tarjeta:
    <strong>
      {{tarjeta.id}}
    </strong>
  </p>
</div>
