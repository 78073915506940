<app-loading-component></app-loading-component>
<header>
  <app-header-container></app-header-container>
</header>
<main class="px-3">
  <div class="container-fluid">
    <div class="title-container">
      <h2 class="title-text">{{ title }}</h2>
    </div>
  </div>
  <ng-content ></ng-content>
</main>

<footer>

</footer>
