<form class="container-fluid" (ngSubmit)="onSubmit(zonaForm)" #zonaForm="ngForm">
    <div class="form-header mt-4  ">
      <h6  class="mb-0">Resetear Contraseña</h6>
    </div>

    <div class="form-body-three-cols mt-3">
      <div class="two-cols mt-3">
        <app-input
          [control]="formGroup.controls.clave1"
          [label]="'Contraseña*'"
          [type]="'password'"
          [id]="'clave1'"
        />
      </div>
      <app-input
        [control]="formGroup.controls.clave2"
        class="mt-3"
        [label]="'Repite Contraseña*'"
        [type]="'password'"
        [id]="'clave2'"
      />

    </div>




    <div class="d-flex gap-3 justify-content-start">
      <button
        type="submit"
        class="btn btn-aceptar mt-5"
        [disabled]="!formGroup.valid"
      >
        <span > Cambiar </span>
      </button>
      <a
        [routerLink]="'/'"
        class="btn btn-outline-error mt-5"
      >
        Cancelar
      </a>
    </div>
  </form>
