import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { globalState, tiposEstado } from 'src/global';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { FiltroModel } from 'src/app/models/entity/filtro.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { TarjetaView } from 'src/app/models/entity/views/tarjetas.view';
import { ModalRenovacionesComponent } from '../../../../components/modals/modal-renovaciones/modal-renovaciones.component';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';

@Component({
  selector: 'app-solicitudes-page',
  templateUrl: './solicitudes-page.component.html',
  styleUrls: ['./solicitudes-page.component.scss'],
  providers: [SolicitudesServices, ZonasService, TarjetasServices],
})
export class SolicitudesPageComponent implements OnInit {
  // Datos
  allData: SolicitudView[] = [];
  zonasData: ZonasModel[] = [];
  estados = tiposEstado;

  usuario: UsuarioModel | undefined = globalState.identity;
  typeForm: string = '';

  // Paginacion y filtrado de solicitudes
  filtro: string = '';
  filtro_dinamico: string = '';
  filtros_dinamicos_principales: any[] = [];
  id_seleccionado: number = 0;
  pagina_actual = 1;
  total_paginas = 0;
  registros_pagina: number = globalState.registrosPagina;
  datos_tarjeta: any = null;
  datos_factura: any = null;
  timeout: any;

  // Impresion de documentos
  imprimir_tarjeta: boolean = false;
  imprimir_factura: boolean = false;

  constructor(
    private _router: ActivatedRoute,
    private _loadService: LoadService,
    private _toastService: ToastrService,
    private _service: SolicitudesServices,
    private _tar_service: TarjetasServices,
    private _serviceZonas: ZonasService,
    private _modalService: NgbModal
  ) {
    this._router.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
        if (!isNaN(Number(this.typeForm))) {
          this.id_seleccionado = Number(this.typeForm);
        }
      }
    });
  }

  async ngOnInit() {
    this._loadService.notifyLoadChange(true);
    try {
      await this.obten_solicitudes();
      await this.obtenZonas();
    } catch (e: any) {
      this._toastService.error(e);
    } finally {
      this._loadService.notifyLoadChange(false);
    }
  }

  async obten_solicitudes(): Promise<void> {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
    })
    let filtro = this.get_filtro_solicitudes(this.filtro, this.filtro_dinamico);
    const data = await this._service.listapag(this.pagina_actual, this.registros_pagina, filtro);
    // Obtenemos los datos de solicitudes, total de paginas y pagina actual
    this.allData = data.solicitudes;
    this.total_paginas = data.total_paginas;
    this.pagina_actual = data.pagina_actual;
  }

  async obtenZonas(): Promise<void> {
    const { zonas } = await this._serviceZonas.lista_completa();
    this.zonasData = zonas;
    this.filtros_dinamicos_principales.push(
      new FiltroModel(0, 'zona-filtro', 'id', 'Filtrar por zonas', 'Todas las zonas', 0, 'nombre', 'zona_id', zonas, 0),
      new FiltroModel(1, 'estado-filtro', 'id', 'Selecciona estado', 'Todas los estados', 0, 'descripcion', 'estado', tiposEstado, 0)
    )
  }

  get_filtro_solicitudes(filtro_tabla: string, filtro_dinamico: string): string {
    let filtro = '';

    // Si el role es de usuario solo debe ver sus solicitudes
    if (this.usuario!!.role < 1) {
      filtro += `where (usuario_id = '${this.usuario!!.id}')`;
    }

    // Se pasa parametros de annio para reducir el tiempo de la petición
    if (globalState.annioActual > 0) {
      filtro = filtro == '' ? ' where ' : ' and ';
      filtro += `(annio = '${globalState.annioActual}')`;
    }
    // Filtro del cuadro de busqueda
    if (filtro_tabla != '') {
      filtro += filtro == '' ? ' where ' : ' and ';
      filtro += `((INSTR(nombre, '${filtro_tabla}') > 0) OR (INSTR(dni, '${filtro_tabla}') > 0) OR (INSTR(matricula, '${filtro_tabla}') > 0) OR (INSTR(numero, '${filtro_tabla}') > 0) OR (INSTR(destinatario, '${filtro_tabla}') > 0) OR (INSTR(domicilio, '${filtro_tabla}') > 0))`;
    }
    if (filtro_dinamico != '') {
      filtro += filtro == '' ? ' where ' : ' and ';
      filtro += filtro_dinamico;
    }
    return filtro;
  }

  cambia_pagina(nueva_pagina: number) {
    this.pagina_actual = nueva_pagina
  }

  abrir_modal_externo(abrir: boolean) {
    if (abrir) {
      this._modalService.open(ModalRenovacionesComponent, { centered: true });
    }
  }

  async recibe_filtro(value: { filtro_texto: string, filtro_dinamico: string }) {
    this.filtro = value.filtro_texto;
    this.filtro_dinamico = value.filtro_dinamico;
    await this.obtiene_solicitudes_filtro();
  }

  async recibe_pagina(value: number) {
    this.pagina_actual = value;
    try {
      this._loadService.notifyLoadChange(true);
      await this.obten_solicitudes();
    } catch (e: any) {
      this._toastService.error(e);
      this.allData = [];
    } finally {
      this._loadService.notifyLoadChange(false);
    }
  }

  async obtiene_solicitudes_filtro(): Promise<void> {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(async () => {
      this._loadService.notifyLoadChange(true);
      let filtro = this.get_filtro_solicitudes(this.filtro, this.filtro_dinamico);
      try {
        const data = await this._service.listapag(this.pagina_actual, this.registros_pagina, filtro);
        this.allData = data.solicitudes;
        this.total_paginas = data.total_paginas;
        this.pagina_actual = data.pagina_actual;
      } catch (e: any) {
        this._toastService.error(e);
        this.allData = [];
        this.total_paginas = 1;
        this.pagina_actual = 1;
      } finally {
        this._loadService.notifyLoadChange(false)
      }
      // Obtenemos los datos de solicitudes, total de paginas y pagina actual
    }, globalState.timeoutDelay)
  }

  abrir_modal_borrado(id: number): void {
    const modalRef = this._modalService.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "solicitud"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "solicitudes"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this._loadService.notifyLoadChange(true);
          await this.obten_solicitudes();
        } catch (e: any) {
          this._toastService.error(e);
        } finally {
          this._loadService.notifyLoadChange(false);
        }
      }
    })
  }

  recibir_datos_factura(datos: any) {
    this._loadService.notifyLoadChange(true);
    this.datos_factura = datos;
    timeoutTime(500).then(() => {
      this._loadService.notifyLoadChange(false);
      this.imprimir_factura = true;
      timeoutTime(500).then(() => {
        this.datos_factura = null;
      });
    });
    this.imprimir_factura = false;
  }

  recibir_datos_tarjeta(datos: any) {
    this.datos_tarjeta = datos
    this._loadService.notifyLoadChange(true);
    timeoutTime(500).then(() => {
      this._loadService.notifyLoadChange(false);
      this.imprimir_tarjeta = true;
      timeoutTime(500).then(() => {
        this.datos_tarjeta = null;
      });
    });
    this.imprimir_tarjeta = false;
  }

  async recibir_ambos(datos: any) {
    try {
      this._loadService.notifyLoadChange(true);
      const datos_tarjeta = await this._tar_service.ficha_vista(datos.tarjetaId)
      const datos_impresion = {
        id: datos_tarjeta.id,
        annio: datos_tarjeta.annio,
        numero: datos_tarjeta.numero,
        zona_id: datos_tarjeta.zonaId,
        zonaAbreviatura: datos_tarjeta.zonaAbreviatura,
        matricula: datos_tarjeta.matricula,
        color: datos_tarjeta.zonaColor
      }
      const datos_final = { datos_tarjeta: datos_impresion, datos_factura: { id: datos.id } };
      this.datos_factura = datos_final.datos_factura
      this.datos_tarjeta = datos_final.datos_tarjeta;
      timeoutTime(500).then(() => {
        this._loadService.notifyLoadChange(false);
        this.imprimir_tarjeta = true;
        timeoutTime(5).then(() => {
          this.datos_factura = null;
          this.datos_tarjeta = null;
        });
      });
      this.imprimir_tarjeta = false;
      this.imprimir_factura = false;
    } catch (e: any) {
      this._toastService.error(e);
    } finally {
      this._loadService.notifyLoadChange(false);
    }
  }
}
