import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { obtainToken } from '../helpers/session.helper';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = obtainToken();

    // Verificar si es una solicitud de inicio de sesión
    if (req.url.includes('usuarios/login') || req.url.includes('usuarios/registro') || req.url.includes('usuarios/existeEmail') ) {
      // Clonar la solicitud sin modificarla y continuar
      return next.handle(req);
    }

    if (token === 'TokenInvalido') {
      this.router.navigate(['/']);
    }

    const headers = req.headers
                               .set('Authorization', 'Bearer ' + token);
    const modifiedReq = req.clone({
      setHeaders:{
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(modifiedReq);
  }
}

