<app-solicitud-header-fomr
  *ngIf="!is_nuevo && edita_solicitud_view"
  [data]="edita_solicitud_view"
  (refresca_datos)="refresca_datos($event)"
  (envia_datos_tarjeta)="envia_datos_tarjeta.emit($event)"
  (envia_datos_factura)="envia_datos_factura.emit($event)"
  (envia_datos_tar_fac)="envia_datos_tar_fac.emit($event)"
/>

<form class="form-container" #zonaForm="ngForm" *ngIf="edita_solicitud_view">
  <div class="form-header mt-4">
    <h6 *ngIf="is_nuevo" class="mb-0">Nueva Solicitud</h6>
    <h6 *ngIf="!is_nuevo" class="mb-0">Actualizar Solicitud</h6>
  </div>
  <div class="form-body-three-cols mt-3">
    <div class="col mt-3">
      <div class="col-12">
        <label class="mt-auto" for="usuarioId">Usuario*</label>
        <div class="position-relative">
          <input
            (click)="abrir_modal_selecciona_usuario()"
            [id]="'usuarioId'"
            class="form-control"
            type="text"
            #usuarioNombre
            disabled
            [value]="formGroup.controls.usuarioNombre.value"
          />

          <button
            #toggleButton
            type="button"
            (click)="pulsa_boton_menu()"
            class="btn btn-primary position-absolute end-0 top-0"
          >
            <fa-icon [icon]="flecha_abajo"> </fa-icon>
          </button>
          <div
            #menu
            [ngClass]="{ 'not-show': !menu_boton_usuario }"
            class="header-usermenu"
          >
          <div class="usermenu-container">

              <button
                (click)="abrir_modal_nuevo_usuario()"
                role="button"
                class="btn btn-primary position-absolute2 end-0 top-0"
              >
                <fa-icon [icon]="icono_anyadir"> </fa-icon>
              </button>

              <button
                (click)="abrir_modal_selecciona_usuario()"
                role="button"
                class="btn btn-primary position-absolute end-0 top-0"
              >
                <fa-icon [icon]="icono_buscar"> </fa-icon>
              </button>
              <a
                class="btn btn-primary position-absolute end-0 top-0"
                *ngIf="
                  (formGroup.controls.usuarioId.value != 0 &&
                    formGroup.controls.usuarioId.value != null) &&
                  formGroup.controls.usuarioId.value != null
                "
                target="_blank"
                [routerLink]="['/usuarios', formGroup.controls.usuarioId.value]"
              >
                <fa-icon [icon]="icono_editar" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <app-input
          class="col mt-0"
          [control]="formGroup.controls.destinatario"
          [label]="'Destinatario'"
          [type]="'text'"
          [id]="'destinatario'"
          [disabled]="edita_solicitud_view!!.estado >= 2"
        >
        </app-input>
      </div>
    </div>

    <div class="col mt-3">
      <app-select-input
        [label]="'Tipo de cliente *'"
        [optionsLabels]="['id', 'nombre']"
        [optionData]="tipos_clientes"
        [defaultSelectText]="'Selecciona tipo de cliente'"
        [control]="formGroup.controls.tipoClienteId"
        [name]="'select-tipo-cliente'"
        [id]="'select-tipo-cliente'"
        [disabled]="edita_solicitud_view!!.estado >= 2"
      />
      <p
        *ngIf="formGroup.controls.tipoClienteId.value === 0 && formGroup.valid"
        class="error-message text-center"
      >
        Tipo de cliente no seleccionado
      </p>
    </div>

    <app-input
      #matricula
      class="col mt-3"
      [control]="formGroup.controls.matricula"
      [label]="'Matrícula del vehículo *'"
      [type]="'text'"
      [id]="'matricula'"
      [maxLength]="10"
      [disabled]="edita_solicitud_view!!.estado >= 2"
    >
    </app-input>

    <app-input
      class="col mt-4"
      [control]="formGroup.controls.marca"
      [label]="'Marca del vehículo *'"
      [type]="'text'"
      [id]="'marca'"
      [disabled]="edita_solicitud_view!!.estado >= 2"
    >
    </app-input>
    <app-input
      class="col mt-4"
      [control]="formGroup.controls.modelo"
      [label]="'Modelo del vehículo *'"
      [type]="'text'"
      [id]="'modelo'"
      [disabled]="edita_solicitud_view!!.estado >= 2"
    >
    </app-input>

    <div class="col mt-4">
      <div class="position-relative w-100 d-flex flex-wrap align-content-end">
        <app-select-input
          [label]="'Zona*'"
          style="display: block; width: 80%"
          [optionData]="zonas"
          [optionsLabels]="['id', 'nombre']"
          [defaultSelectText]="'Selecciona zona'"
          [control]="formGroup.controls.zonaId"
          [name]="'select-zona'"
          [id]="'select-zona'"
          [disabled]="edita_solicitud_view!!.estado >= 2"
        />
        <button
          (click)="abrir_modal_mapa()"
          role="button"
          class="btn btn-primary mt-auto"
        >
          <fa-icon [icon]="icono_mapa"> </fa-icon>
        </button>
      </div>
      <p
        *ngIf="formGroup.controls.zonaId.value === 0 && formGroup.valid"
        class="error-message text-center mb-0ç"
      >
        Zona no seleccionada
      </p>
    </div>
    <div class="col mt-4">
      <app-select-input
        *ngIf="!formGroup.controls.calleEncontrada.value"
        class="mb-0"
        [label]="'Calle*'"
        [optionData]="calles"
        [optionsLabels]="['id', 'nombre']"
        [defaultSelectText]="'Selecciona calle'"
        [control]="formGroup.controls.calleId"
        [name]="'select-calle'"
        [id]="'select-calle'"
        [disabled]="formGroup.controls.zonaId.value === 0 || edita_solicitud_view!!.estado >= 2"
      />
      <app-input
        *ngIf="formGroup.controls.calleEncontrada.value"
        [control]="formGroup.controls.domicilio"
        [label]="'Calle *'"
        [placeholder]="'Calle Perez Casas'"
        [type]="'text'"
        [disabled]="formGroup.controls.zonaId.value === 0 || edita_solicitud_view!!.estado >= 2"
        [id]="'calleEncontrada'"
      />
      <app-reverse-switch
        [label]="'No he encontrado mi calle'"
        [control]="formGroup.controls.calleEncontrada"
        [id]="'calle-encontrada'"
      />
      <p
        *ngIf="formGroup.controls.zonaId.value === 0 && formGroup.valid"
        class="error-message text-center mb-0"
      >
        Seleciona una zona
      </p>
    </div>
    <div
      class="col d-flex flex-wrap align-content-center"
    >
    <app-select-input
      class="mb-0 w-100"
      [label]="'Tipo de pago *'"
      [optionData]="tipo_pago_data"
      [optionsLabels]="['id', 'nombre']"
      [defaultSelectText]="'Selecciona Tipo de Pago'"
      [control]="formGroup.controls.tipoPagoId"
      [name]="'select-calle'"
      [id]="'select-calle'"
    />
    </div>
    <div
      class="col mt-5 d-flex flex-wrap justify-content-center align-content-center"
    >
      <app-reverse-switch
        [label]="'Segundo Semestre'"
        [control]="formGroup.controls.medio"
        [id]="'calle-encontrada'"
      />
    </div>
    <div class="full-width mt-5">
      <div class="form-check form-switch form-check-reverse">
        <input
          class="form-check-input"
          type="checkbox"
          id="consentimiento"
          [formControl]="formGroup.controls.consentimiento"
        />
        <p class="form-check-label" for="consentimiento">
          <span>He leido y acepto las </span>
          <a
            class="text-primary"
            role="button"
            (click)="abrir_modal_consentimiento()"
            >CLÁUSULA ADICIONAL DATOS TERCEROS</a
          >
        </p>
      </div>
    </div>
    <div class="full-width">
      <p class="text-end">
        <a
          class="text-primary"
          role="button"
          (click)="abrir_modal_condiciones()"
          >CONDICIONES DE USO DE LA TARJETA (EXTRACTO ORDENANZAS)</a
        >
      </p>
    </div>
  </div>
  <div
    [ngClass]="
      is_nuevo
        ? 'd-flex gap-3 justify-content-end'
        : 'd-flex justify-content-between'
    "
  >
    <div *ngIf="!is_nuevo" class="d-flex gap-3">
      <button
        class="btn btn-outline-primary mt-5"
        (click)="abrir_modal_cambio_matricula()"
      >
        Cambio de matrícula
      </button>
      <button
        class="btn btn-outline-primary mt-5"
        (click)="abrir_modal_cambio_domicilio()"
      >
        Cambio de domicilio
      </button>
    </div>
    <div class="d-flex gap-3">
      <button
        (click)="onSubmit()"
        type="submit"
        class="btn btn-aceptar mt-5"
        [disabled]="
          !formGroup.valid ||
          formGroup.controls.estado.value === 0 ||
          formGroup.controls.usuarioId.value === 0 ||
          formGroup.controls.consentimiento.value === false
        "
      >
        <span *ngIf="is_nuevo"> Crear </span>
        <span *ngIf="!is_nuevo"> Actualiza </span>
      </button>
      <a [routerLink]="'/solicitudes'" class="btn btn-outline-error mt-5">
        Cancelar
      </a>
    </div>
  </div>
</form>

<app-lineal-component-selector
  *ngIf="!is_nuevo"
  [componentsLabel]="['Documentación']"
  [dynamicComponent]="[documentos_lista]"
/>
