import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createCalle } from 'src/app/services/helpers/model.helper';
import { CallesService } from 'src/app/services/https/calles.services';

@Component({
  selector: 'app-calles-page',
  templateUrl: './calles-page.component.html',
  styleUrls: ['./calles-page.component.scss'],
  providers: [CallesService]
})
export class CallesPageComponent {
  typeForm: string = '';
  allData: CalleModel[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: CallesService,
    private _loadService: LoadService,
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
      }
    });
  }

  ngOnInit(){
    if(this.typeForm === '') {
      this.obtainData();
    }
  }

  obtainData() {
    this._loadService.notifyLoadChange(true);
    this._service.lista().subscribe({
      next: (res) => {
        res.data.forEach((element: any) => {
          this.allData.push(
            createCalle(element)
          );
        });
      this._loadService.notifyLoadChange(false);
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }
}
