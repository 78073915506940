import { ISlider } from "../interface/slider.interface";

export class ModelSlider implements ISlider{
    id: number;
    img:string;
    text: string;
    isButton: boolean;
    buttonText: string;
    link: string;

    constructor(
        id: number,
        img:string,
        text: string,
        isButton: boolean,
        buttonText: string,
        link: string,
    ) {
      this.id = id;
      this.img = img;
      this.text = text;
      this.isButton = isButton;
      this.buttonText = buttonText;
      this.link = link;
    }
}
