<app-page-container-publico [title]="'Configuración'">
  <div class="container-fluid">
    <form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
      <div class="form-body-three-cols mt-3">
        <app-input
          class="mt-3"
          [control]="formGroup.controls.nombre"
          [label]="'Configuración *'"
          [type]="'text'"
          [id]="'nombre'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.cif"
          [label]="'CIF *'"
          [type]="'text'"
          [id]="'modelo'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.domicilio"
          [label]="'Domicilio *'"
          [type]="'text'"
          [id]="'domicilio'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.codigoPostal"
          [label]="'Código Postal *'"
          [type]="'text'"
          [id]="'codigoPostal'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.localidad"
          [label]="'Localidad *'"
          [type]="'text'"
          [id]="'localidad'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.provincia"
          [label]="'Provincia *'"
          [type]="'text'"
          [id]="'provincia'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.email"
          [label]="'email *'"
          [type]="'text'"
          [id]="'email'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.web"
          [label]="'web *'"
          [type]="'text'"
          [id]="'web'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.telefono"
          [label]="'Teléfono *'"
          [type]="'text'"
          [id]="'telefono'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.registroMercantil"
          [label]="'Registro Mercantil *'"
          [type]="'text'"
          [id]="'registroMercantil'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.annioActual"
          [label]="'Año Actual *'"
          [type]="'number'"
          [id]="'annioActual'">
        </app-input>
        <app-input
          class="mt-3"
          [control]="formGroup.controls.facturaSerie"
          [label]="'Serie de factura *'"
          [type]="'text'"
          [id]="'facturaSerie'">
        </app-input>
        <div class="mt-3">
          <label for="formFile">Mapa de Zona *</label>
          <input
            class="form-control"
            type="file" id="formFile"
            [formControl]="formGroup.controls.mapa"
            [class.invalid]="formGroup.controls.mapa.invalid && formGroup.controls.mapa.dirty && formGroup.controls.mapa.touched"
            (change)="fileChangeEvent($event)"
            >
          <p *ngIf="this.data.mapa !== ''" class="text-success">
            <fa-icon [icon]="verIcon" class="text-muted" (click)="muestraMapa(modalMapa, urlZona + data.mapa, 'Mapa general' )"></fa-icon>
            Archivo: {{this.data.mapa}}
            <fa-icon [icon]="eliminaIcon" class="text-muted" (click)="confirmaEliminaMapa(formConfirmaBorrado)"></fa-icon>
           <!-- The Modal -->
           <ng-template #formConfirmaBorrado let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">
                Confirmar Borrado
              </h4>
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('')"
              ></button>
            </div>
            <div class="modal-body">
              ¿Seguro que desea eliminar el mapa? <br /><br />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="modal.close('')"
              >
                Eliminar
              </button>
            </div>
          </ng-template>
          </p>
          <p *ngIf="this.data.mapa === ''" class="text-danger">Sin mapa</p>
        </div>

        <div class="">
        </div>
        <div class="full-width">
          <hr>
          <h5 class="mb-3">Zonas a renovar documentación</h5>
          <div class="d-flex gap-3">
            <app-select-input
            class="d-block zonas-seleccion"
            [optionData]="zonasData"
            [optionsLabels]="['id','nombre']"
            [defaultSelectText]="'Selecciona zona'"
            [defaultSelectValue]="0"
            [control]="formGroup.controls.zonasSeleccion"
            [name]="'select-documentacion'"
          />
          <button type="button" class="btn btn-outline-principal btn-add mt-3" (click)="insertaZona(formGroup.controls.zonasSeleccion.value ?? 0)">
            <fa-icon [icon]="addIcon"></fa-icon>
          </button>
          </div>
          <ng-container *ngIf="zonasSeleccionadas.length > 0">
            <div class="d-flex gap-3">
              <div *ngFor="let zonaSel of zonasSeleccionadas; index as i">
                <div class="zonaSelector mt-3">
                  <span class="align-middle mb-0">{{zonaSel.nombre}}</span>
                  <button type="button" (click)="deleteZona(zonaSel.id, i)" class="btn">X</button>
                </div>
              </div>
            </div>
          </ng-container>
          <hr>
        </div>
        <div class="full-width mt-3">
          <app-tiny-editor
            [control]="formGroup.controls.consentimiento"
            [label]="'Consentimiento'"
            [id]="'consentimiento'"
            [height]="400"
          />
        </div>
        <div class="full-width mt-3">
          <app-tiny-editor

            [height]="400"
            [control]="formGroup.controls.condiciones"
            [label]="'Condiciones'"
            [id]="'condiciones'"
          />
        </div>
      </div>
      <div class="position-relative">
        <div *ngIf="!showEmailCfg" class="position-absolute top-50 start-50" style="transform: translate(-50%, -50%); z-index: 15;">
          <button
            (click)="showContent(formConfirmaContentido, $event)"
            class="btn btn-aceptar"
          >
              Mostrar contenido
          </button>
        </div>
        <div
          [ngStyle]="{
            'opacity': showEmailCfg ? '1' : '0.3',
          }"
          [ngClass]="{'bef-cover' : !showEmailCfg}"
          class="form-body-three-cols mt-3 cfg-important"
          >
          <app-input
            class="mt-5"
            [control]="formGroup.controls.notificacionesEmail"
            [label]="'Email para notificicaciones *'"
            [type]="'text'"
            [id]="'notificacionesEmail'">
          </app-input>
          <app-input
            class="mt-5"
            [control]="formGroup.controls.notificacionesHost"
            [label]="'Host de notificicaciones *'"
            [type]="'text'"
            [id]="'notificacionesHost'">
          </app-input>
          <app-input
            class="mt-5"
            [control]="formGroup.controls.notificacionesPuerto"
            [label]="'Puerto de notificicaciones *'"
            [type]="'text'"
            [id]="'notificacionesPuerto'" />
          <app-input
            class="mt-5"
            [control]="formGroup.controls.notificacionesUsuario"
            [label]="'Email de salida de notificaciones *'"
            [type]="'text'"
            [id]="'notificacionesUsuario'" />
          <app-input
            class="mt-5"
            [control]="formGroup.controls.notificacionesPassword"
            [label]="'Contraseña de notificaciones *'"
            [type]="'password'"
            [id]="'notificacionesPassword'" />
        </div>
      </div>

      <div class="d-flex justify-content-end gap-3">
        <button
          type="submit" class="btn btn-aceptar mt-5"
          [disabled]="!formGroup.valid"
          >
          <span>
            Actualiza
          </span>
        </button>
        <a [routerLink]="'/'" class="btn btn-outline-error mt-5" >
          Cancelar
        </a>
      </div>
    </form>
  </div>
</app-page-container-publico>

<!-- Modal Información -->
<ng-template #formConfirmaContentido let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Información</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      La modificación del contenido que se mostrará puede provocar problemas en el envío automático de Emails. ¿Estás seguro de que deseas continuar?
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-aceptar"
      (click)="showEmailCfg = !showEmailCfg; modal.close('');"
    >
      Sí, déjame verlo
    </button>
    <button
      type="button"
      class="btn btn-outline-error"
      (click)="modal.close('')"
    >
      Volver sin ver el contenido
    </button>
  </div>
</ng-template>


<ng-template #modalMapa let-modal >
  <div class="modal-header">
      <h4>{{nombreMapa}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <img src="{{urlMapa}}" class="w-100 img-fluid rounded" >
  </div>
</ng-template>

