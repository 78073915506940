import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbConfig, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfigGeneralModel } from 'src/app/models/entity/configuracion.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createConfigGenereal, createZona } from 'src/app/services/helpers/model.helper';
import { generateRandomName, timeoutTime } from 'src/app/services/helpers/utils.helper';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { UploadServices } from 'src/app/services/https/upload.services';
import { globalState, tiposValidado } from 'src/global';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faEye, faPlus, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { ZonasModel } from 'src/app/models/entity/zonas.model';

@Component({
  selector: 'app-configuraciones-page',
  templateUrl: './configuraciones-page.component.html',
  styleUrls: ['./configuraciones-page.component.scss'],
  providers: [ConfiguracionService, UploadServices, ZonasService],
})
export class ConfiguracionesPageComponent implements OnInit {
  data: ConfigGeneralModel = new ConfigGeneralModel(
    0,'','','','','','','','','','',
    '',0,'','','',0,'','','',''
  );

  // Iconos a mostrar
  verIcon: IconDefinition = faEye;
  eliminaIcon: IconDefinition = faTrash;
  addIcon: IconDefinition = faPlus;

  nombreMapa : string = '';
  urlZona : string = '';
  urlMapa : string = '';

  showEmailCfg: boolean = false;
  uploadFile: any;
  fileName: string = '';
  zonasData: ZonasModel[] = [];
  zonasSeleccionadas: ZonasModel[] = [];
  confZonas: any[] = []

  constructor(
    private _service: ConfiguracionService,
    private _uploadService: UploadServices,
    private _modalService: NgbModal,
    private _toastService: ToastrService,
    private _zonasService: ZonasService,
    private _loadService: LoadService,
    private _modalCfg: NgbConfig,
    config: NgbModalConfig,
    private modalService: NgbModal,
  ) {

    this.urlZona = globalState.urlImg+'configuracion/';
    this.urlMapa = '';

  }

  ngOnInit(): void {
    this.obtainConfiguracion();
    this.obtainAllZonas();

  }

  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    cif: new FormControl<string>('', [Validators.required]),
    domicilio: new FormControl<string>('', [Validators.required]),
    codigoPostal: new FormControl<string>('', [Validators.required]),
    localidad: new FormControl<string>('', [Validators.required]),
    provincia: new FormControl<string>('', [Validators.required]),
    web: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required]),
    telefono: new FormControl<string>('', [Validators.required]),
    registroMercantil: new FormControl<string>('', [Validators.required]),
    annioActual: new FormControl<number>(0, [Validators.required]),
    facturaSerie: new FormControl<string>("", [Validators.required]),
    consentimiento: new FormControl<string>('', [Validators.required]),
    condiciones: new FormControl<string>('', [Validators.required]),
    notificacionesEmail: new FormControl<string>('', [Validators.required]),
    notificacionesHost: new FormControl<string>('', [Validators.required]),
    notificacionesPuerto: new FormControl<number>(0, [Validators.required]),
    notificacionesUsuario: new FormControl<string>('', [Validators.required]),
    notificacionesPassword: new FormControl<string>('', [Validators.required]),
    zonasSeleccion: new FormControl<number>(0, []),
    mapa: new FormControl<string>('', []),
  });

  obtainConfiguracion() {
    this._loadService.notifyLoadChange(true);
    this._service.ficha(1).subscribe({
      next: (res) => {
        this.data = createConfigGenereal(res.data);
        this.actualizaFormGroup(this.data);
        this._loadService.notifyLoadChange(false);
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);

      },
      complete: () => {},
    });
  }

  obtainAllZonas() {
    this._zonasService.lista().subscribe({
      next: (res) => {
        res.data.forEach((item: any) => {
          this.zonasData.push(createZona(item))
        });
        this.obtenZonasSeleccionadas();
      }
    })
  }

  actualizaFormGroup(updateInf: ConfigGeneralModel) {
    this.formGroup.patchValue({
      nombre: updateInf.nombre,
      cif: updateInf.cif,
      codigoPostal: updateInf.codigoPostal,
      localidad: updateInf.localidad,
      provincia: updateInf.provincia,
      web: updateInf.web,
      email: updateInf.email,
      telefono: updateInf.telefono,
      registroMercantil: updateInf.registroMercantil,
      annioActual: updateInf.annioActual,
      facturaSerie: updateInf.facturaSerie,
      consentimiento: updateInf.consentimiento,
      condiciones: updateInf.condiciones,
      notificacionesEmail: updateInf.notificacionesEmail,
      notificacionesHost: updateInf.notificacionesHost,
      notificacionesPuerto: updateInf.notificacionesPuerto,
      notificacionesUsuario: updateInf.notificacionesUsuario,
      notificacionesPassword: updateInf.notificacionesPassword,
      domicilio: updateInf.domicilio,
      // mapa: updateInf.mapa !== '' ? `${C:/}`,
    });
  }

  onSubmit() {
    const newCfg = new ConfigGeneralModel(
      1,
      this.formGroup.value.nombre ?? '',
      this.formGroup.value.cif ?? '',
      this.formGroup.value.domicilio ?? '',
      this.formGroup.value.codigoPostal ?? '',
      this.formGroup.value.localidad ?? '',
      this.formGroup.value.provincia ?? '',
      this.formGroup.value.web ?? '',
      this.formGroup.value.email ?? '',
      this.formGroup.value.telefono ?? '',
      this.formGroup.value.notificacionesEmail ?? '',
      this.formGroup.value.notificacionesHost ?? '',
      this.formGroup.value.notificacionesPuerto ?? 0,
      this.formGroup.value.notificacionesUsuario ?? '',
      this.formGroup.value.notificacionesPassword ?? '',
      this.formGroup.value.registroMercantil ?? '',
      this.formGroup.value.annioActual ?? new Date('now').getUTCFullYear(),
      this.formGroup.value.facturaSerie ?? '',
      this.formGroup.value.consentimiento ?? '',
      this.formGroup.value.condiciones ?? '',
      this.formGroup.value.mapa?.split('/').slice(-1)[0] ?? '',
    );
    this.actualizaCfg(newCfg);
  }

  showContent(content: any, event: Event) {
     // Evita el comportamiento predeterminado del evento de clic
     // Así evito que se envíe el formulario al darle a enviar contenido
    event.preventDefault();
    this._modalService.open(content, { centered: true }).result.then(() => {});
  }

  fileChangeEvent(event: Event) {
    if(event.target) {
      this.uploadFile = event.target;
    }
  }

  /**
   * Envía a la api la info para actualizar
   * @param newCfg
   */
  actualizaCfg(newCfg: ConfigGeneralModel) {
    this._loadService.notifyLoadChange(true);
    this._service.actualiza(newCfg).subscribe({
      next: (res) => {
        this.prepareUpload();
        globalState.annioActual = newCfg.annioActual;
        this._toastService.success(res.message);
      },
      error: (err) => {
        this._toastService.error(err.error.message);
        timeoutTime(1000).then(() => {
          this._loadService.notifyLoadChange(false);
        });
      },
      complete: () => {
        timeoutTime(1000).then(() => {
          this._loadService.notifyLoadChange(false);
        });
      },
    });
  }


  prepareUpload() {
    try {
      if(this.uploadFile.files.length > 0) {
        this._loadService.notifyLoadChange(true);
        for(let i = 0; i < this.uploadFile.files.length; i++) {
          const extension = this.uploadFile.files[i].name.split('.').slice(-1)[0];
          this.fileName = `${generateRandomName()}.${extension}`;
          const formData = new FormData();
          const file = new File([this.uploadFile.files[i]], this.fileName );
          formData.set('file', file);
          this.uploadImage(formData)
        }
      }
    } catch (e: any) {
      this._loadService.notifyLoadChange(false);
    }
  }

  uploadImage(formData: FormData) {
    this._loadService.notifyLoadChange(true)
    this._uploadService.subir(1, formData, 'configuracion').subscribe({
      next:(res) => {
        this._loadService.notifyLoadChange(false)
      },
      error:(err) => {

        this._loadService.notifyLoadChange(false)
      },
      complete:() => {
        this._loadService.notifyLoadChange(false)
      }
    });
  }


  muestraMapa(content : any, url : string, nombre : string) {
    this.nombreMapa = nombre;
    this.urlMapa = url;
    this.modalService.open(content, { centered: true, size: 'lg' }).result.then(
            (result) => {
        //this.urlMapa = '';
            },
            (reason) => {
          },
        );
  }

  /**
   * Abre el modal que permite eliminar un dato de BBDD
   * @param content ID del modal
   * @param id Id del objeto a eliminar
   */
  confirmaEliminaMapa(content: any) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        this.data.mapa = '';
      },
      (reason) => {}
    );
  }

  obtenZonasSeleccionadas() {
    this._service.listaZonas().subscribe({
      next: (res) => {
        res.data.forEach((item:any) => {
          this.confZonas.push(item)
          this.zonasSeleccionadas.push(
            this.buscaZona(item.zona_id)[0]
          )
        });

      }
    })
  }

  buscaZona(id: number) :any {
    return this.zonasData.filter((x: ZonasModel) => {
        return x.id === id
      });
  }

  insertaZona(idZona: number) {
    const data = {
      configuracionId: 1,
      zonaId: idZona
    }
    this._zonasService.insertaZona(data).subscribe({
      next: (res) => {
        this.confZonas.push(res.data[0]);
        this.zonasSeleccionadas.push(
          this.buscaZona(res.data[0].zona_id)[0]
        );
      },
      error: (err) => {
        this._toastService.error('Zona no insertada', 'Error')
      }
    });
  }

  buscaConf(id: number): any {
    return this.confZonas.filter((x: any) => {
      return x.zona_id === id
    })
  }

  deleteZona(zonaId: number, position: number) {
    const configId = this.buscaConf(zonaId)[0].id
    this._zonasService.deleteConf(configId).subscribe({
      next: (res) => {
        this.zonasSeleccionadas.splice(position, 1);
      }
    })
  }
}
