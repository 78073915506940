<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4">
    <h6 *ngIf="isNuevo" class="mb-0">Registro de usuario</h6>
    <h6 *ngIf="!isNuevo" class="mb-0">Actualización de usuario</h6>
    <app-reverse-switch
      [label]="'Activo'"
      [control]="formGroup.controls.activo"
      />
  </div>
  <div class="form-body-three-cols mt-3">
    <div class="two-cols mt-3">
      <app-input
        [control]="formGroup.controls.nombre"
        [label]="'Nombre completo*'"
        [type]="'text'"
        [id]="'nombre'"
      />
    </div>
    <app-input
      [control]="formGroup.controls.dni"
      class="mt-3"
      [label]="'DNI/NIE*'"
      [type]="'text'"
      [id]="'dni'"
    />
    <app-input
      [control]="formGroup.controls.email"
      class="mt-3"
      [label]="'Email*'"
      [ngClass]="{ 'two-cols': !isNuevo }"
      [type]="'email'"
      [id]="'email'"
    />
    <app-input
      [control]="formGroup.controls.telefono"
      class="mt-3"
      [label]="'Telefono*'"
      [type]="'tel'"
      [id]="'telefono'"
    />
    <app-input
      *ngIf="isNuevo"
      [control]="formGroup.controls.password"
      class="mt-3"
      [label]="'Password*'"
      [type]="'password'"
      [id]="'password'"
    />
    <app-input
      [control]="formGroup.controls.localidad"
      class="mt-3"
      [label]="'Localidad*'"
      [type]="'text'"
      [id]="'localidad'"
    />
    <app-input
      [control]="formGroup.controls.provincia"
      class="mt-3"
      [label]="'Provincia*'"
      [type]="'text'"
      [id]="'provincia'"
    />
    <app-input
      [control]="formGroup.controls.codigoPostal"
      class="mt-3"
      [label]="'Código Postal*'"
      [type]="'number'"
      [id]="'codigoPostal'"
    />
    <div class="full-width">
      <app-input
        [control]="formGroup.controls.domicilio"
        [label]="'Domicilio*'"
        [type]="'text'"
        [id]="'domicilio'"
      />
    </div>
    <div class="mt-3">
      <label for="formFile" class="form-label"
        >Avatar</label
      >
      <input
        (change)="preparaImagen($event)"
        class="form-control"
        type="file"
        id="avatar"
        name="avatar"
        #avatar
        />
    </div>
    <div *ngIf="data.avatar !== ''">
    </div>
    <div *ngIf="data.avatar !== ''" class="mt-3 p-5 border">
      <img class="img-fluid" [src]="urlImg + data.avatar" alt="Avatar">
    </div>
  </div>
  <div class="d-flex gap-3 justify-content-start">
    <button
      type="submit"
      class="btn btn-aceptar mt-5"
      [disabled]="!formGroup.valid"
    >
      <span *ngIf="isNuevo"> Crear </span>
      <span *ngIf="!isNuevo"> Actualiza </span>
    </button>
    <a
      [routerLink]="'/'"
      class="btn btn-outline-error mt-5"
    >
      Cancelar
    </a>
  </div>
</form>
