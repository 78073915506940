import { Component, EventEmitter, Input, Output,SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { TarjetaModel } from 'src/app/models/entity/tarjeta.model';
import { TarjetaView } from 'src/app/models/entity/views/tarjetas.view';
//import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createSolicitud, createTarjetaVista } from 'src/app/services/helpers/model.helper';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { globalState } from 'src/global';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight
} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-renovaciones',
  templateUrl: './modal-renovaciones.component.html',
  styleUrls: ['./modal-renovaciones.component.scss'],
  providers: [TarjetasServices, SolicitudesServices]
})

export class ModalRenovacionesComponent {
  constructor(
    public active_modal: NgbActiveModal,
    private _tar_service: TarjetasServices,
    private _sol_service: SolicitudesServices,
    private _toast: ToastrService,
    private _nav: Router,
    private _loading: LoadService
  ) {}
  renovaciones: TarjetaView[] = [];
  filtro: string = "";
  filtro_texto: string = "";
  pagina_actual: number = 1;
  total_paginas: number = 1;

  async ngOnInit(): Promise<void> {
    try {
      this._loading.notifyLoadChange(true)
      await this.obtener_renovaciones();
    } catch (e:any) {
      this._toast.error(e)
    } finally {
      this._loading.notifyLoadChange(false)
    }
  }

  genera_filtro(): string {
    let filtro: string = ` WHERE ANNIO = ${globalState.annioActual - 1}`
    if (this.filtro_texto !== "") {
      filtro += ` AND (INSTR(nombre,'${this.filtro_texto}') or INSTR(dni, '${this.filtro_texto}') or INSTR(matricula, '${this.filtro_texto}') or INSTR(numero, '${this.filtro_texto}')or INSTR(destinatario, '${this.filtro_texto}')or INSTR(domicilio, '${this.filtro_texto}'))`;
    }
    filtro += ";";
    return filtro;
  }

  async obtener_renovaciones(): Promise<void> {
    this.filtro = this.genera_filtro()
    console.log(this.filtro)
    const data = await this._tar_service.lista_paginacion_nueva(this.pagina_actual, globalState.registrosPagina, this.filtro)
    this.renovaciones = data.tarjetas;
    this.pagina_actual = data.pagina_actual;
    this.total_paginas = data.total_paginas
  }

  async buscar_en_renovacion() {
    await this.obtener_renovaciones();
  }

  cambia_pagina (pagina: number) {
    this.pagina_actual = pagina;
  }

  async selecciona_renovacion(position: number) {
    const renovacion = createTarjetaVista(this.renovaciones[position]);
    const tramita = globalState.identity?.id ?? 0;
    const today = new Date();
    let solicitud = createSolicitud(new SolicitudModel(0,renovacion.usuarioId,renovacion.destinatario,today,0,null,null,
      tramita,renovacion.matricula,0,0,false,renovacion.annio + 1,0,0,'',0,
      renovacion.marca,renovacion.modelo,renovacion.tipoclienteId, false, true, renovacion.zonaId, 0,
      renovacion.domicilio,0,true,0,renovacion.id,0,0,0,''));

      solicitud.annio = today.getFullYear();
      solicitud.pagado = false;
      solicitud.renovacion = true;
      solicitud.calleId = 0;
      solicitud.facturaNumero = renovacion.facturaNumero;
      solicitud.facturaSerie = renovacion.facturaSerie;
      solicitud.fechaSolicitud = today;
      solicitud.tarjeta_id = 0;
      solicitud.tipoClienteId = renovacion.tipoclienteId;
      solicitud.tramitaId = tramita;
      solicitud.usuarioId = renovacion.usuarioId;
      solicitud.zonaId = renovacion.zonaId;
      solicitud.tarjetarenovada = renovacion.id;
      try {
        const { data } = await this._sol_service.crea_nuevo(solicitud)
        this._nav.navigate([`solicitudes/${data.id}`])
      } catch (e: any) {
        this._toast.error(e)
      } finally {
        this._loading.notifyLoadChange(false);
      }
  }
}
