import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-ver-observaciones',
  templateUrl: './modal-ver-observaciones.component.html',
  styleUrls: ['./modal-ver-observaciones.component.scss']
})
export class ModalVerObservacionesComponent {
  @Input() observaciones!: string;

  constructor(
    public active_modal: NgbActiveModal,
  ) {}
}
