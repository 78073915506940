<div
  [ngClass]="{'not-show' : !isLoading}"
  class="loading-container">
  <div class="spinner-container">
    <div class="spinner"></div>
  </div>
  <div class="banner-container">
    <div class="logo-img"></div>
    <!-- LOADING DOTS... -->
    <div class="spinner-box">
      <div class="pulse-container">
        <div class="pulse-bubble pulse-bubble-1"></div>
        <div class="pulse-bubble pulse-bubble-2"></div>
        <div class="pulse-bubble pulse-bubble-3"></div>
      </div>
    </div>
  </div>
</div>
