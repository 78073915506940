import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconDefinition, faBars } from '@fortawesome/free-solid-svg-icons';
import { IGlobalState, globalState } from './../../../../../global';
import { AuthService } from './../../../../services/https/auth.services';

@Component({
  selector: 'app-header-nav-publico',
  templateUrl: './header-nav-publico.component.html',
  styleUrls: ['./header-nav-publico.component.scss']
})
export class HeaderNavPublicoComponent implements OnInit{
  identity = globalState.identity;
  rutaActiva: string = '';
  mobileMenuActive: boolean = false;
  menuIcon: IconDefinition = faBars
  menuCloseIcon: IconDefinition = faCircleXmark

  constructor(private _router: Router,private _authService: AuthService) {}

  ngOnInit(): void {
    this.rutaActiva = this._router.url;
    this.identity = this._authService.getIdentity();
  }

  toggleMenuMobile(){
    this.mobileMenuActive = !this.mobileMenuActive;
  }
}
