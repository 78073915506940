import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalObservacionesComponent } from 'src/app/components/modals/modal-observaciones/modal-observaciones.component';
import { ModalSubirArchivoComponent } from 'src/app/components/modals/modal-subir-archivo/modal-subir-archivo.component';
import { DocumentoModel } from 'src/app/models/entity/documento.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createDocumento } from 'src/app/services/helpers/model.helper';
import { DocumentosService } from 'src/app/services/https/documentos.services';

@Component({
  selector: 'app-solicitud-documentos-list',
  templateUrl: './solicitud-documentos-list.component.html',
  styleUrls: ['./solicitud-documentos-list.component.scss'],
  providers: [DocumentosService]
})
export class SolicitudDocumentosListComponent implements OnInit {
  documentos: DocumentoModel[] = [];
  solicitud_id: number = 0

  constructor(
    private _service: DocumentosService,
    private _route: ActivatedRoute,
    private _loader: LoadService,
    private _modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      this.solicitud_id = Number(params['slug']);
      this.obten_documentos(this.solicitud_id);
    })
  }

  async obten_documentos(solicitud_id: number) {
    try {
      this.documentos = await this._service.lista_por_solicitud(solicitud_id)
      console.log(this.documentos)
    } catch (e: any) {
      console.log(e)
      this.documentos = [];
    }
  }

  abrir_modal_subir_archivo() {
    this._modalService.open(ModalSubirArchivoComponent, { centered: true, });

  }

  abrir_modal_observaciones() {
    this._modalService.open(ModalObservacionesComponent, { centered: true, });
  }

  async recarga_datos(solicitud_id: number) {
    try {
      this.documentos = await this._service.lista_por_solicitud(solicitud_id)
    } catch (e: any) {
      console.log(e)
      this.documentos = [];
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
