<div class="form-group">
  <label [htmlFor]="id">{{label}}</label>
  <select
    [attr.disabled]="disabled ? true : null"
    [class.invalid]="control.invalid && control.dirty && control.touched && control.value == 0"
    [formControl]="control"
    [id]="id"
    [name]="name"
    class="form-select">

    <option *ngIf="defaultSelectText != ''" disabled value="0">{{ defaultSelectText }}</option>
    <option *ngFor="let option of optionData" [value]="option[optionsLabels[0]]">
      {{option[optionsLabels[1]]}}
    </option>
  </select>
  <ng-container class="position-relative" *ngFor="let err of control.errors | keyvalue">
    <div class="position-absolute error-message" [ngClass]="{'not-show' : !control.touched && control.invalid}">
      <p>
        {{errorMessages[err.key]}}
      </p>
    </div>
  </ng-container>
</div>


