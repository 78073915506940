<app-page-container-publico
  class="solo-pantalla"
  [title]="usuario!!.role === 2 ? 'Solicitudes' : 'Mis Solicitudes'"
>
  <div class="container-fluid">
    <ng-container *ngIf="usuario">
      <!-- Role administrador-->
      <ng-container *ngIf="typeForm === ''">
        <app-table-default
          [data]="allData"
          [columnas_tabla]="[
            'codigo#nombreZona',
            'usuarioNombre#destinatario',
            'usuarioDomicilio#usuarioTelefono',
            'matricula',
            'marca#modelo',
            'fechaAceptacion#fechaDenegacion',
            'tarjeta_numero#tarjeta_activa',
            'factura_codigo#pagada'
          ]"
          [nombre_columnas]="[
            'Solicitud',
            'Nombre',
            'Domicilio',
            'Matricula',
            'Vehiculo',
            'Aceptación',
            'Tarjeta',
            'Factura'
          ]"
          [tipo_dato_tabla]="[
            '',
            '',
            '',
            '',
            '',
            'badge-success#badge-error',
            '#boolean',
            '#boolean',
          ]"
          [alineacion_columnas]="[
            'start#start',
            'start#start',
            'start#start',
            'start#start',
            'start#start',
            'start#start',
            'center#start',
            'center#start',
            ]"
          [filtro_dinamico_principal]="filtros_dinamicos_principales"
          [ancho_columnas]="[]"
          [ver_boton_editar]="true"
          [ver_boton_borrar]="true"
          [ver_boton_imprimir]="true"
          [propiedades_para_filtro]="['usuarioNombre','usuarioDni','matricula','destinatario']"
          [nombre_tabla]="'solicitudes'"
          [tiene_paginacion] = "true"
          [pagina_actual] = "pagina_actual"
          [total_paginas]="total_paginas"
          [ver_boton_modal_externo]="true"
          [texto_modal_externo]="'Renovaciones'"
          (valores_modal_externo)="abrir_modal_externo($event)"
          (envia_filtro)="recibe_filtro($event)"
          (envia_pagina)="recibe_pagina($event)"
          (envia_borrado)="abrir_modal_borrado($event)"
          (enviar_datos_impresion)="recibir_ambos($event)"
        />
      </ng-container>
      <app-edita-solicitud-admin
        *ngIf="typeForm !== ''"
        [id]="id_seleccionado"
        (envia_datos_tarjeta)="recibir_datos_tarjeta($event)"
        (envia_datos_factura)="recibir_datos_factura($event)"
        (enviar_datos_impresion)="recibir_ambos($event)"

      />
      <!-- <app-edita-solicitud-admin
        (enviarDatosTarjeta)="recibir_datos_tarjeta($event)"
        (enviarDatos)="recibir_datos($event)"
        (enviarAmbos)="recibir_ambos($event)"
        *ngIf="typeForm !== ''"
      ></app-edita-solicitud-admin> -->
    </ng-container>

    <ng-container *ngIf="usuario && usuario.role === 1">
      <!-- Role usuario-->
      <!-- <ng-container *ngIf="typeForm === ''">
        <h3 *ngIf="allData.length <= 0" class="text-gray text-center mt-5">
          No tiene solicitudes registradas
        </h3>
        <app-table-default
          *ngIf="allData.length > 0"
          [data]="allData"
          [tableColumns]="[
            'id',
            'matricula',
            'marca',
            'modelo',
            'fechaSolicitud',
            'fechaAceptacion',
            'fechaDenegacion'
          ]"
          [tableBooleans]="[false, false, false, false, false, false]"
          [labelColumns]="[
            'Código',
            'Matrícula',
            'Marca',
            'Modelo',
            'F. Solicitud',
            'F. Aceptacion',
            'F. Denegacion'
          ]"
          [ancho_columnas]="[]"
          [searcher]="['id', 'marca', 'matricula']"
          [tableName]="'solicitudes'"
          [propertyName]="'matricula'"
          [disableActions]="true"
          [ver_boton_modal_externo]="true"
          [texto_modal_externo]="'Renovaciones'"
          (valueExternalModal)="openModal($event, solicitudes)"
        ></app-table-default>
      </ng-container>
      <ng-template #solicitudes let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">
            Posibles Renovaciones
          </h4>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('')"
          ></button>
        </div>
        <div class="modal-body">
          <div class="modalTableSearch"></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="modal.close('')"
          >
            Cancelar
          </button>
        </div>
      </ng-template>
      <app-solicitud-header-fomr />
      <app-edita-solicitud-usuario
        *ngIf="typeForm !== ''"
      ></app-edita-solicitud-usuario> -->
    </ng-container>
  </div>
</app-page-container-publico>

<factura-print
  *ngIf="datos_factura !== null"
  id="factura"
  #factura
  [solicitud]="datos_factura.id"
  [imprime]="imprimir_factura"
/>

<tarjeta-print
  *ngIf="datos_tarjeta !== null"
  class="mt-2"
  id="tarjeta"
  #tarjeta
  [tarjetaId]="datos_tarjeta.id"
  [annio]="datos_tarjeta.annio"
  [numero]="datos_tarjeta.numero"
  [zonaId]="datos_tarjeta.zona_id"
  [zonaAbreviatura]="datos_tarjeta.zonaAbreviatura"
  [matricula]="datos_tarjeta.matricula"
  [color]="datos_tarjeta.color"
  [imprime]="imprimir_tarjeta"
/>
