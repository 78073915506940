import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoDocuModel } from 'src/app/models/entity/tipoDocu.model';
import { TipoDocuClienteModel } from 'src/app/models/entity/tipoDocuCliente.model';
import { createTipoDocuCliente } from '../helpers/model.helper';

@Injectable()
export class TipoDocuClienteService {
  public url: string = globalState.url ;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
   const params = new HttpParams()
      .set('id', id+'')

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(this.url + 'tiposclientedocumentacion/ficha', params, httpOptions);
  }

  // Realiza el login del usuario
  lista(id: number): Observable<any> {
    const params = new HttpParams().set('tipocliente_id', id)
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'tiposclientedocumentacion/listatipo', params, httpOptions);
  }

  // Realiza el login del usuario
  crea(data: TipoDocuClienteModel): Observable<any> {
    const params = new HttpParams()
    .set('nombre', data.nombre)
    .set('activo', data.activo ? '1' : '0')
    .set('caducidad', data.caducidad ? '1' : '0')
    .set('obligatorio', data.obligatorio ? '1' : '0')
    .set('observaciones', data.observaciones);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposclientedocumentacion/crea', params, httpOptions);
  }

    // Realiza el login del usuario
  creaActualiza(data: TipoDocuClienteModel, funcion: string): Observable<any> {

    let params = new HttpParams()
    .set('nombre', data.nombre)
    .set('activo', data.activo ? '1' : '0')
    .set('caducidad', data.caducidad ? '1' : '0')
    .set('obligatorio', data.obligatorio ? '1' : '0')
    .set('tipodocumentacion_id', data.tipoDocumentacionId)
    .set('tipocliente_id', data.tipoClienteId)
    .set('observaciones', data.observaciones)
    .set('cambio_domicilio', data.cambio_domicilio ? '1' : '0')
    .set('cambio_vehiculo', data.cambio_vehiculo ? '1' : '0')

    if(funcion === 'actualiza') {
      params = params.set('id', data.id);
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposclientedocumentacion/' + funcion, params, httpOptions);
  }

  // Realiza el login del usuario
  actualiza(data: TipoDocuModel): Observable<any> {
    const params = new HttpParams()
      .set('id', data.id)
      .set('nombre', data.nombre)
      .set('activo', data.activo ? '1' : '0')
      .set('caducidad', data.caducidad ? '1' : '0')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposclientedocumentacion/actualiza', params, httpOptions);
  }


  async lista_completa(): Promise<TipoDocuClienteModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}tipo_cliente_documentacion`, httpOptions));
      const documentos: TipoDocuClienteModel[] = [];
      data.forEach((item: any) => {
        documentos.push(createTipoDocuCliente(item))
      })
      return documentos
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async lista_paginacion(pagina: number, registros: number, filtro: string): Promise<{ documentos: TipoDocuClienteModel[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}tipo_cliente_documentacion?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const list_docs: TipoDocuClienteModel[] = [];
      res.data.documentos.forEach((item: any) => {
        list_docs.push(createTipoDocuCliente(item))
      })
      const { pagina_actual, total_paginas } = res.data
      return { documentos: list_docs, pagina_actual, total_paginas };
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }


  async ficha_nuevo(id: number): Promise<TipoDocuClienteModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}tipo_cliente_documentacion/${id}`, httpOptions));
      return createTipoDocuCliente(data)
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async crea_nuevo(model: TipoDocuClienteModel): Promise<TipoDocuClienteModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const json_payload = {
      nombre: model.nombre,
      activo: model.activo ? '1' : '0',
      caducidad: model.caducidad ? '1' : '0',
      obligatorio: model.obligatorio ? '1' : '0',
      tipodocumentacion_id: model.tipoDocumentacionId,
      tipocliente_id: model.tipoClienteId,
      observaciones: model.observaciones,
      cambio_domicilio: model.cambio_domicilio ? '1' : '0',
      cambio_vehiculo: model.cambio_vehiculo ? '1' : '0'
    }
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}tipo_cliente_documentacion`,json_payload, httpOptions));
      return createTipoDocuCliente(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_nuevo(id: number, model: TipoDocuClienteModel): Promise<TipoDocuClienteModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const json_payload = {
      id: id,
      nombre: model.nombre,
      activo: model.activo ? '1' : '0',
      caducidad: model.caducidad ? '1' : '0',
      obligatorio: model.obligatorio ? '1' : '0',
      tipodocumentacion_id: model.tipoDocumentacionId,
      tipocliente_id: model.tipoClienteId,
      observaciones: model.observaciones,
      cambio_domicilio: model.cambio_domicilio ? '1' : '0',
      cambio_vehiculo: model.cambio_vehiculo ? '1' : '0'
    }
    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}tipo_cliente_documentacion/${id}`,json_payload, httpOptions));
      return createTipoDocuCliente(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }
}
