import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { createTipoClient } from '../helpers/model.helper';

@Injectable()
export class TipoClienteService {
  public url: string = globalState.url ;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
   const params = new HttpParams()
      .set('id', id+'')

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(this.url + 'tiposcliente/ficha', params, httpOptions);
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'tiposcliente/lista', params, httpOptions);
  }

  async lista_completa(): Promise<{ tipos: TipoClienteModel[] }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}tipo_cliente`, httpOptions));
      const tipos: TipoClienteModel[] = [];
      data.forEach((item: any) => {
        tipos.push(createTipoClient(item))
      })
      return { tipos }
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener los tipos';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  crea(zona: TipoClienteModel): Observable<any> {
    const params = new HttpParams()
    .set('nombre', zona.nombre)
    .set('activo', zona.activo ? '1' : '0')
    .set('color', zona.color)

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposcliente/crea', params, httpOptions);
  }

    // Realiza el login del usuario
  creaActualiza(zona: TipoClienteModel, funcion: string): Observable<any> {
    let params = new HttpParams()
    .set('nombre', zona.nombre)
    .set('activo', zona.activo ? '1' : '0')
    .set('color', zona.color)
    if(funcion === 'actualiza') {
      params = params.set('id', zona.id)
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposcliente/' + funcion, params, httpOptions);
  }

  // Realiza el login del usuario
  actualiza(zona: TipoClienteModel): Observable<any> {
    const params = new HttpParams()
      .set('id', zona.id)
      .set('nombre', zona.nombre)
      .set('activo', zona.activo ? '1' : '0')
      .set('color', zona.color);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposcliente/actualiza', params, httpOptions);
  }

  async ficha_nueva(id: number): Promise<TipoClienteModel> {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}tipo_cliente/${id}`, httpOptions));
      return createTipoClient(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async crea_nuevo(model: TipoClienteModel): Promise<TipoClienteModel> {
    const params = {
      nombre: model.nombre,
      activo: model.activo ? 1 : 0,
      color: model.color
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(this.url + 'tipo_cliente', params, httpOptions));
      return createTipoClient(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  async actualiza_nuevo(model: TipoClienteModel, id: number): Promise<TipoClienteModel> {
    const params = {
      nombre: model.nombre,
      activo: model.activo ? 1 : 0,
      color: model.color
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}tipo_cliente/${id}`, params, httpOptions));
      return createTipoClient(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}
