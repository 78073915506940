<form action="" (ngSubmit)="onSubmit()">
  <app-text-editor
    [placeholder]="'Ej: Pude conseguir un documento sustitutivo, por esa razón no encontrarán exactamente el mismo archivo'"
    [control]="form.controls.observa"
    [height]="500"
    [label]="'Observaciones'"
  />
  <div class="d-flex justify-content-end mt-3">
    <button class="btn btn-primary" type="submit">Actualiza Observaciones</button>
  </div>
</form>
