import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { ConfigGeneralModel } from 'src/app/models/entity/configuracion.model';
import { createConfigGenereal } from '../helpers/model.helper';

@Injectable()
export class ConfiguracionService {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) {}

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
    const params = new HttpParams().set('id', id + '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(
      this.url + 'configuracion/ficha',
      params,
      httpOptions
    );
  }

  async ficha_nueva(id: number): Promise<ConfigGeneralModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}configuracion/${id}`, httpOptions));
      return createConfigGenereal(data)
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al iniciar sesión';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(
      this.url + 'configuracion/lista',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  actualiza(model: ConfigGeneralModel): Observable<any> {
    const params = new HttpParams()
      .set('id', model.id)
      .set('nombre', model.nombre)
      .set('cif', model.cif)
      .set('domicilio', model.domicilio)
      .set('codigopostal', model.codigoPostal)
      .set('localidad', model.localidad)
      .set('provincia', model.provincia)
      .set('web', model.web)
      .set('email', model.email)
      .set('telefono', model.telefono)
      .set('notificaciones_email', model.notificacionesEmail)
      .set('notificaciones_host', model.notificacionesHost)
      .set('notificaciones_usuario', model.notificacionesUsuario)
      .set('notificaciones_puerto', model.notificacionesPuerto)
      .set('notificaciones_password', model.notificacionesPassword)
      .set('registro_mercantil', model.registroMercantil)
      .set('annio_actual', model.annioActual)
      .set('factura_serie', model.facturaSerie)
      .set('consentimiento', model.consentimiento)
      .set('condiciones', model.condiciones);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(
      this.url + 'configuracion/actualiza',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  listaZonas(): Observable<any> {
    const params = new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(
      this.url + 'configuracionzona/lista',
      params,
      httpOptions
    );
  }
}
