<app-page-container-publico style="background-color: #95c2de;" [title]="'404 - Página no encontrada'">
  <div class="mainbox">
    <div class="err">4</div>
    <fa-icon [icon]="faQuestion" class="far fa-question-circle"></fa-icon>
    <div class="err2">4</div>
    <div class="msg">
      ¡Página no encontrada!
      <p>Vuelve a <a [routerLink]="'/'">Inicio</a> e intentalo de nuevo.</p>
    </div>
  </div>
</app-page-container-publico>
