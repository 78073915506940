
<!-- Anverso Tarjeta -->
<div class="row tarjeta anverso mb-5">

  <div class="col-12">
    <div class="row">

      <div class="col-9 " [ngStyle]="{'background-color': 'green'}">
        <!-- Cabecera Logos -->
        <div class="row tarjeta-cabecera mt-3">
          <div class="col-12">
            <div class="row">
              <div class="col-5">
                <div class="row">
                  <div class="col text-center cabecera-texto1">
                    Tarjeta estacionamiento
                  </div>
                </div>

                <div class="row">
                  <div class="col text-center cabecera-texto2">ORA</div>
                  <div class="col text-center cabecera-texto3">
                    Residente y comercio
                  </div>
                </div>
              </div>

              <div class="col-2">
                <img src="../../../../assets/logolorca.png" class="logo-lorca" />
              </div>

              <div class="col-5 text-center">
                <img src="../../../../assets/logo_limusa.png" class="logo-limusa" />
                <span class="texto-domicilio"
                  >Plaza Óvalo nº3, entresuelo - 968 46 78 58</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Cabecera  -->
        <div class="row tarjeta-cabecera">
          <div class="col-12">
            <div class="row">
              <div class="zona col-1 " [ngStyle]="{'background-color': color ? color : 'white'}"><p> </p></div>
              <div class="col-3">
                <div
                class="row align-items-center h-100"
                >
                  <div class="col texto-zona">ZONA {{ zonaAbreviatura }}</div>
                </div>
              </div>

              <div class="col-4">
                <div class="row align-items-center h-100">
                  <div class="col texto-horario2">Horario de servicio</div>
                </div>
              </div>

              <div class="col-4 cuadro-horario">
                <div class="texto-horario">Mañanas de 9 a 14 h</div>
                <div class="texto-horario">Tardes de 17 a 20 h</div>
                <div class="texto-horario">Sábados de 9 a 14 h</div>
                <div class="texto-horario">Salvo festivos, sábados</div>
                <div class="texto-horario">de julio y mes de agosto</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row tarjeta-cuerpo">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <div class="registro">REGISTRO: {{ annio }}/{{ numero }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-1">
                <div class="texto-annio">AÑO {{ annio }}</div>
              </div>

              <div class="col-7">
                <div class="row justify-content-between">
                  <div class="col-12 text-center mt-4">MATRÍCULA</div>
                  <div class="col-12 text-center texto-matricula">
                    {{ matricula }}
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="">
                  <qrcode
                    *ngIf="codigoQR != ''"
                    [qrdata]="codigoQR"
                    [width]="164"
                    [errorCorrectionLevel]="'M'"
                  >
                  </qrcode>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Avisos -->
        <div class="border-top">

          <div class="col-12"  >
            <p class="texto-reverso">- Esta acreditación es válida sólo para la <strong>zona ORA distinguida en color verde</strong> y para las calles anotadas en esta tarjeta. De acuerdo a las Ordenanzas, las calles habilitadas pueden ser susceptibles de variación.</p>
          </div>

          <div class="col-12">
            <p class="texto-reverso">- En caso de <strong>modificación y/o manipulación</strong>, carecerá de validez a todos los efectos, pudiendo suponer la anulación y acarrear sanción administrativa.</p>
          </div>

          <div class="col-12">
            <p class="texto-reverso">- Durante el estacionamiento en el area reservada, el anverso de la tarjeta habrá de permanecer <strong>completamente visible desde el exterior por el parabrisas delantero</strong>.</p>
          </div>

        </div>

      </div>

      <!-- Descripcion Calles -->
      <div class="col-3 border-left mt-2" [ngStyle]="{'background-color': 'red'}">

        <div class="col-12"  >
          <p class="texto-reverso texto-calles">Esta tarjeta le permite estacionar en <strong>ZONA VERDE</strong> en los tramos delimitados a tal efecto de las siguientes vías públicas:</p>
        </div>

        <!-- Calles -->
        <div class="col-12 mt-3"  >

          <ul class="">
            <li *ngFor="let calle of calles1"
                class="texto-calles">
              {{calle.nombre}}</li>
          </ul>
        </div>

      </div>

    </div>
  </div>
</div>

<hr [ngClass]="{ 'saltopagina' : saltopagina }" />

<!-- Reverso Tarjeta -->
<!--<div class="row tarjeta p-1" [ngClass]="{'saltopagina' : !visualiza }">-->
<!--
<div class="row tarjeta saltopagina p-1"  >

  <div class="col-12"  >
    <p class="texto-reves texto-reverso">- Esta acreditación es válida sólo para la <strong>zona ORA distinguida en color verde</strong> y para las calles anotadas en este reverso. De acuerdo a las Ordenanzas, las calles habilitadas pueden ser susceptibles de variación.</p>
  </div>

  <div class="col-12">
    <p class="texto-reves texto-reverso">- En caso de <strong>modificación y/o manipulación</strong>, carecerá de validez a todos los efectos, pudiendo suponer la anulación y acarrear sanción administrativa.</p>
  </div>

  <div class="col-12">
    <p class="texto-reves texto-reverso">- Durante el estacionamiento en el area reservada, el anverso de la tarjeta habrá de permanecer <strong>completamente visible desde el exterior por el parabrisas delantero</strong>.</p>
  </div>
-->
  <!-- Calles -->
<!--
  <div class="col-12 calles">
    <div class="row">
      <div class="col">
        <ul class="">
          <li *ngFor="let calle of calles3"
              class=" texto-reves texto-calles">
            {{calle.nombre}}</li>
        </ul>
      </div>

      <div class="col">
        <ul class="">
          <li *ngFor="let calle of calles2"
              class=" texto-reves texto-calles">
            {{calle.nombre}}</li>
        </ul>
      </div>

      <div class="col">
        <ul class="">
          <li *ngFor="let calle of calles1"
              class=" texto-reves texto-calles">
            {{calle.nombre}}</li>
        </ul>
      </div>
    </div>

  </div>

  <div class="col-12 ">
    <p class="texto-reves texto-resaltado">Esta tarjeta le permite estacionar en <strong>ZONA VERDE</strong> en los tramos delimitados a tal efecto de las siguientes vías públicas:</p>
  </div>

</div>-->
