<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Añadir/Editar Observaciones
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  <app-text-editor
    [control]="form_group.controls.edita_observaciones"
    [label]="'Observaciones'"
    [height]="300"
  />
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="guardar_observaciones()"
  >
    Guardar
  </button>
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="active_modal.close('')"
  >
    Cancelar
  </button>
</div>
