import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioPageComponent } from './pages/publico/inicio-page/inicio-page.component';
import { LoginPageComponent } from './pages/login/login-page/login-page.component';
import { RegistroPageComponent } from './pages/publico/registro-page/registro-page.component';
import { AvisoEmailComponent } from './pages/publico/aviso-email/aviso-email.component';
import { UsuarioAccess } from './services/guards/usuarioAccess.guard';
import { AuthService } from './services/https/auth.services';
import { ZonasPageComponent } from './pages/privado/zonas/zonas-page/zonas-page.component';
import { AdminAccess } from './services/guards/adminAcces.guard';
import { TipoClientePageComponent } from './pages/privado/tipo-cliente/tipo-cliente-page/tipo-cliente-page.component';
import { TipoDocumentacionPageComponent } from './pages/privado/tipo-documentacion/tipo-documentacion-page/tipo-documentacion-page.component';
import { TipoClienteDocumentacionPageComponent } from './pages/privado/tipo-cliente-documentacion/tipo-cliente-documentacion-page/tipo-cliente-documentacion-page.component';
import { CallesPageComponent } from './pages/privado/calles/calles-page/calles-page.component';
import { SolicitudesPageComponent } from './pages/privado/solicitudes/solicitudes-page/solicitudes-page.component';
import { ConfiguracionesPageComponent } from './pages/privado/configuracion/configuraciones-page/configuraciones-page.component';
import { DocumentoPageComponent } from './pages/privado/documentos/documento-page/documento-page.component';
import { TarjetaPageComponent } from './pages/privado/tarjetas/tarjeta-page/tarjeta-page.component';
import { UsuariosPageComponent } from './pages/privado/usuarios/usuarios-page/usuarios-page.component';
import { InspectorAccess } from './services/guards/inspectorAcces.guard';
import { NotFoundComponent } from './pages/publico/not-found/not-found.component';
import { UtilidadesPageComponent } from './pages/privado/utilidades-page/utilidades-page.component';
import { ChangePasswordComponent } from './pages/publico/change-password/change-password.component';
import { InformesPageComponent } from './pages/privado/informes/informes-page/informes-page.component';


const routes: Routes = [
  // Públicas
  { path: '', component:  InicioPageComponent},
  { path: 'login', component:  LoginPageComponent},
  { path: 'registro', component:  RegistroPageComponent},
  { path: 'aviso-activa-email', component:  AvisoEmailComponent},
  // Privadas
  { path: 'zonas', component:  ZonasPageComponent, canActivate:[AdminAccess]},
  { path: 'zonas/:slug', component:  ZonasPageComponent, canActivate:[AdminAccess]},
  { path: 'tipo-cliente', component:  TipoClientePageComponent, canActivate:[AdminAccess]},
  { path: 'tipo-cliente/:slug', component:  TipoClientePageComponent, canActivate:[AdminAccess]},
  { path: 'tipo-documentacion', component:  TipoDocumentacionPageComponent, canActivate:[AdminAccess]},
  { path: 'tipo-documentacion/:slug', component:  TipoDocumentacionPageComponent, canActivate:[AdminAccess]},
  { path: 'tipo-documentacion-cliente/client/:id/tipo/:slug', component:  TipoClienteDocumentacionPageComponent, canActivate:[AdminAccess]},
  { path: 'tipo-documentacion-cliente/solicitud/:id/tipo/:slug', component:  TipoClienteDocumentacionPageComponent, canActivate:[AdminAccess]},
  //tipo-documentacion-cliente/client/1/tipo/nuevo
  { path: 'calles', component:  CallesPageComponent, canActivate:[AdminAccess]},
  { path: 'zonas/:id/calle/:slug', component:  CallesPageComponent, canActivate:[AdminAccess]},
  { path: 'informes', component:  InformesPageComponent, canActivate:[AdminAccess]},
  //zonas/1/calle/nuevo
  { path: 'solicitudes', component:  SolicitudesPageComponent, canActivate:[UsuarioAccess]},
  { path: 'solicitudes/:slug', component:  SolicitudesPageComponent, canActivate:[UsuarioAccess]},
  { path: 'documentos', component:  DocumentoPageComponent, canActivate:[UsuarioAccess]},
  { path: 'documentos/:slug/visor', component:  DocumentoPageComponent, canActivate:[UsuarioAccess]},
  { path: 'documentos/:slug/:id', component:  DocumentoPageComponent, canActivate:[AdminAccess]},
  { path: 'tarjetas', component:  TarjetaPageComponent, canActivate:[UsuarioAccess]},
  { path: 'tarjetas/:slug', component:  TarjetaPageComponent, canActivate:[UsuarioAccess]},
  { path: 'usuarios', component:  UsuariosPageComponent, canActivate:[ UsuarioAccess]},
  { path: 'usuarios/:slug', component:  UsuariosPageComponent, canActivate:[UsuarioAccess]},
  { path: 'configuraciones', component:  ConfiguracionesPageComponent, canActivate:[AdminAccess]},
  { path: 'utilidades', component:  UtilidadesPageComponent, canActivate:[AdminAccess]},
  { path: 'valida-tarjetas/:slug/:id', component:  TarjetaPageComponent, canActivate:[InspectorAccess]},
  { path: 'changepassword/:slug', component:  ChangePasswordComponent},
  { path: '**', component: NotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, UsuarioAccess, AdminAccess, InspectorAccess]
})
export class AppRoutingModule { }
