
export class TableField {
  value: string;
  type: string;
  align: string;
  name: string;

  constructor(
    value: string,
    type: string,
    align: string,
    name: string
  ) {
    this.value = value,
    this.type = type;
    this.align = align;
    this.name = name;
  }
}
