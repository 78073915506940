<!-- Lista -->
<div *ngIf="typeForm === 'lista'">
  <div class="mt-5">
    <!--
<app-table-default
        *ngIf="allData.length >= 0"
        [data] = allData
        [tableColumns] = "['id','nombre', 'zonaId']"
        [tableBooleans] = "[false,false, false,]"
        [labelColumns] = "['Código','Nombre', 'Zona']"
        [widthColumns] = "['','','']"
        [searcher] = "['id','nombre']"
        [tableName] = "'zonascalles'"
        [idName] = "'id'"
      ></app-table-default>

    -->
    <!-- <app-table-default
      *ngIf="allData.length >= 0"
      [data] = allData
      [tableColumns] = "['id','nombre']"
      [tableItemType] = "['','']"
      [labelColumns] = "['Código','Nombre']"
      [alignColumns]="['center', 'center']"
      [widthColumns] = "['','','']"
      [searcher] = "['id','nombre']"
      [tableName] = "'tiposclientedocumentacion'"
      [idName] = "'id'"
      [tableName] = "'zonascalles'"
      [idName] = "'id'"
      [isPrincipal] = false
      [routerNew]="'/zonas/'+ idCliente +'/calle/nuevo'"
      [routerEdit]="'/zonas/'+ idCliente +'/calle/'"
      [showDeleteButton]="true"
      [showEditButton]="true"


    >
    </app-table-default> -->
  </div>
</div>
<!-- Edita -->
