import { Component, AfterViewInit, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DocumentoModel } from 'src/app/models/entity/documento.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createDocumento } from 'src/app/services/helpers/model.helper';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { ActualizaServices } from 'src/app/services/https/actualiza.services';
import { DocumentosService } from 'src/app/services/https/documentos.services';

@Component({
  selector: 'app-form-user-docs',
  templateUrl: './form-user-docs.component.html',
  styleUrls: ['./form-user-docs.component.scss']
})

export class FormUserDocsComponent {
  @Input() observaciones!: string
  @Input() id!: number;

  form = new FormGroup({
    observa: new FormControl<string>('', []),
  })

  constructor(
    private _route: ActivatedRoute,
    private _toastr: ToastrService,
    private _loadService: LoadService,
    private _service: DocumentosService,

  ) {
  }

  ngOnInit(): void {
    this.form.patchValue({
      observa: this.observaciones
    })
  }


  async onSubmit() {
    try {
      this._loadService.notifyLoadChange(true);
      this._service.actualiza_observaciones(this.id, this.form.value.observa ?? '');
    } catch (e: any) {
      this._toastr.error(e);
    } finally {
      this._loadService.notifyLoadChange(false);
    }
  }
}
