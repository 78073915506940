import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { globalState } from 'src/global';

@Component({
  selector: 'app-modal-consentimiento',
  templateUrl: './modal-consentimiento.component.html',
  styleUrls: ['./modal-consentimiento.component.scss']
})
export class ModalConsentimientoComponent {
  consentimiento_content: SafeHtml = "";

  @Output() envia_consentimiento: EventEmitter<boolean> = new EventEmitter<boolean>

  constructor(
    public active_modal: NgbActiveModal,
    private _satinizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.consentimiento_content = this._satinizer.bypassSecurityTrustHtml(globalState.configuracion_global?.consentimiento ?? "");
  }
}
