import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { ITipoDocu } from 'src/app/models/interface/tipoDocu.interface ';
import { IUsuario } from 'src/app/models/interface/usuario.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createTipoDocu, createUsuario } from 'src/app/services/helpers/model.helper';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { FormControl } from '@angular/forms';
import { globalState, tiposRole } from 'src/global';
import { UploadServices } from 'src/app/services/https/upload.services';
import { generateRandomName, timeoutTime } from 'src/app/services/helpers/utils.helper';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nuevo-edita-usuario',
  templateUrl: './nuevo-edita-usuario.component.html',
  styleUrls: ['./nuevo-edita-usuario.component.scss'],
  providers: [UsuariosService, UploadServices]
})
export class NuevoEditaUsuarioComponent {

  @Input() idCliente!: number;
  @Input() type: string = '';
  data: UsuarioModel = new UsuarioModel(
    0,'','','', true, '', '', '', 0, '', '', '', '', ''
  );

  identity : any;
  id: number = 0;
  idUsuario: number = 0;
  avatar: string ='';
  isNuevo: boolean = false;
  isLectura: boolean = false;
  role: number = 0;
  urlImg: string = `${globalState.urlImg}usuarios/`;
  uploadEvent: any;

  public tiposRole : any;

  // Iconos a mostrar
  keyIcon: IconDefinition = faKey;

  // Definir un validador personalizado que no hace nada (validación válida siempre)
  emptyValidator: Validators = (control: AbstractControl): { [key: string]: any } | null => {
    return null;
  };

  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    activo: new FormControl<boolean>(true, [Validators.required]),
    role: new FormControl<number>(0, [Validators.required]),
    dni: new FormControl<string>('', [Validators.required]),
    telefono: new FormControl<string>('', [Validators.required, Validators.pattern(/^\d{9}$/),]),
    email: new FormControl<string>('', [Validators.email]),
    password: new FormControl<string>('', this.isNuevo ? [] : []),
    localidad: new FormControl<string>('', [Validators.required]),
    provincia: new FormControl<string>('', [Validators.required]),
    codigoPostal: new FormControl<string>('', [Validators.required]),
    domicilio: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private _dataServices: UsuariosService,
    private _uploadServices: UploadServices,
    private _route: ActivatedRoute,
    private _nav: Router,
    private _toastService: ToastrService,
    private _loadService: LoadService,
    private modalService: NgbModal,
  ) {
    this.identity = globalState.identity;
    this.tiposRole = tiposRole;

    if (globalState.identity) {
      this.role = globalState.identity.role;
      this.idUsuario = globalState.identity.id;
      if (globalState.identity.role < 2) {
        this.isLectura = true;
      }
    }
    else {
      this.isLectura = true;
    }
  }

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      this.isNuevo = params['slug'] === 'nuevo' ? true : false;
      this.idCliente = params['id'];
      if ((this.idCliente != this.idUsuario) && (this.role < 1)) {
        this.idCliente = this.idUsuario;
      }

      if(!this.isNuevo) {
        this.id = Number(params['slug']);
        this.obtenData()
      }
    });
  }


  obtenData() {
    this._loadService.notifyLoadChange(true);
    try {
      this._dataServices.ficha(this.id).subscribe({
        next: (res) => {
          this.data = createUsuario(res.data)
          this.actualizaFormGroup(this.data);
          timeoutTime((1000)).then(() => {
            this._loadService.notifyLoadChange(false);
          })
        },
        error: (err) => {
          this._loadService.notifyLoadChange(false);
          this._toastService.error(err.error.message);
        },
        complete: () => {
          this._loadService.notifyLoadChange(false);
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  actualizaFormGroup(updateInf: UsuarioModel) {
    this._loadService.notifyLoadChange(true);
    this.formGroup.patchValue({
      nombre: updateInf.nombre,
      dni: updateInf.dni,
      telefono:updateInf.telefono,
      email: updateInf.email,
      role:updateInf.role,
      activo: updateInf.activo,
      password: updateInf.password,
      localidad: updateInf.localidad,
      provincia: updateInf.provincia,
      codigoPostal: updateInf.codigoPostal,
      domicilio:updateInf.domicilio,
    });
  }

  onSubmit(){
    let newUser = new UsuarioModel(
      this.id,
      this.formGroup.value.email ?? '',
      this.formGroup.value.dni ?? '',
      this.formGroup.value.nombre ?? '',
      this.formGroup.value.activo ?? false,
      this.formGroup.value.telefono ?? '',
      this.formGroup.value.password ?? '',
      this.avatar,
      this.formGroup.value.role ?? 1,
      this.formGroup.value.domicilio ?? '',
      this.formGroup.value.codigoPostal ?? '',
      this.formGroup.value.localidad ?? '',
      this.formGroup.value.provincia ?? '',
      '',
    );
    this.creaActualizaUsuario(newUser, this.isNuevo ? 'crea':'actualiza')
  }

  creaActualizaUsuario(newUser: UsuarioModel, func: string) {
    this._dataServices.creaActualiza(newUser, func).subscribe({
      next: (res) => {
        try {
          this._toastService.success(res.data.message, func === 'crea' ? 'Creado con éxito' : 'Actualizado con éxito');
          if (func === 'crea') {
            timeoutTime((1000)).then(()=> {
              this._loadService.notifyLoadChange(false);
              this._nav.navigate(['/usuarios/' + res.data.id])
            });
          }
        } catch (e) {
          this._loadService.notifyLoadChange(false);
        }

      },
      error: (err) => {
        this._toastService.error(err.error.message, 'Error');
        timeoutTime((1000)).then(()=> {
          this._loadService.notifyLoadChange(false)
        });
      },
      complete: () => {
        timeoutTime((1000)).then(()=> {
          this._loadService.notifyLoadChange(false)
        });
      }
    });
  }

  preparaImagen(event: any) {
    try {
      // Comprobamos que exista algún archivo seleccionado en el evento
      if(event.target.files.length > 0) {
        // Indicamos que se va a realizar una petición http (pantalla de carga)
        this._loadService.notifyLoadChange(true);
        // Recorremos los archivos
        for(let i = 0; i < event.target.files.length; i++) {
          // Se guarda extension
          const extension = event.target.files[i].name.split('.').slice(-1)[0];
          // Se guarda nombre de archivo
          let fileName = `${generateRandomName()}.${extension}`;
          // Se genera datos del formulario
          const formData = new FormData();
          // Creamos un tipo archivo
          const file = new File([event.target.files[i]], fileName );
          // Insertamos en los datos
          formData.set('file', file);
          // Subida de imagenes
          this.subeImagen(this.id ?? 0, formData)
        }
      }
    } catch (e: any) {
      this._loadService.notifyLoadChange(false);
      this._toastService.error('No ha sido posible subir el archivo.');
    }
  }

  subeImagen(id: number, formData: FormData ) {
    this._uploadServices.subir(id, formData, 'usuarios').subscribe({
      next: (res) => {
        this._toastService.success(res.data.message, 'Imagen subida con éxito');
        timeoutTime((1000)).then(()=> {
          this._loadService.notifyLoadChange(false)
          window.location.reload();
        });
      },
      error: (err) => {
        timeoutTime((1000)).then(()=> {
          this._loadService.notifyLoadChange(false)
          this._toastService.error(err.error.message, 'Error');
        });
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }

  confirmaResetClave(content: any, id: number) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        this.mailPassword(id);
      },
      (reason) => {}
    );
  }

  mailPassword(id : number) {
    this._loadService.notifyLoadChange(true);
    this._dataServices.mailPassword(this.id,this.formGroup.controls.email.value).subscribe({
      next: (res) => {

        this._toastService.success(res.data.message, 'Solicitud enviada correctamente');

        timeoutTime((1000)).then(() => {
          this._loadService.notifyLoadChange(false);
        })
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);
        this._toastService.error(err.error.message);
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }

}
