export class SolicitudView {
  id: number;
  codigo: string;
  fechaSolicitud: string | Date | null;
  estado: string;
  fechaAceptacion: string | Date | null;
  fechaDenegacion: string | Date | null;
  tramitaId: number;
  matricula: string;
  tarjetaId: number;
  importe: number;
  pagada: boolean;
  annio: number;
  numero: number;
  iva: number;
  total: number;
  documentosPendientes: number;
  documentosCorrectos: number;
  facturaSerie: string;
  facturaNumero: number;
  factura_codigo : string;
  marca: string;
  modelo: string;
  tipoClienteId: number;
  medio: boolean;
  consentimiento: boolean;
  zonaId: number;
  calleId: number;
  domicilio: string;
  usuarioId: number;
  destinatario: string;
  usuarioEmail: string;
  usuarioDni: string;
  usuarioNombre: string;
  usuarioActivo: boolean;
  usuarioTelefono: string;
  usuarioAvatar: string;
  usuarioRole: number;
  usuarioDomicilio: string;
  usuarioCodigoPostal: string;
  usuarioLocalidad: string;
  usuarioProvincia: string;
  usuarioObservaciones: string;
  nombreZona: string;
  tarjeta_numero: number;
  tiposolicitud: number;
  solicitud_destino:number;
  solicitud_origen:number;
  tarjeta_activa: boolean;
  fecha_pagado: string | null;
  tipo_pago: string

  constructor(
    id: number,
    codigo: string,
    fechaSolicitud: string | Date | null,
    estado: string,
    fechaAceptacion: string | Date | null,
    fechaDenegacion: string | Date | null,
    tramitaId: number,
    matricula: string,
    tarjetaId: number,
    importe: number,
    pagada: boolean,
    annio: number,
    numero: number,
    iva: number,
    total: number,
    documentosPendientes: number,
    documentosCorrectos: number,
    facturaSerie: string,
    facturaNumero: number,
    factura_codigo: string,
    marca: string,
    modelo: string,
    tipoClienteId: number,
    medio: boolean,
    consentimiento: boolean,
    zonaId: number,
    calleId: number,
    domicilio: string,
    usuarioId: number,
    destinatario: string,
    usuarioEmail: string,
    usuarioDni: string,
    usuarioNombre: string,
    usuarioActivo: boolean,
    usuarioTelefono: string,
    usuarioAvatar: string,
    usuarioRole: number,
    usuarioDomicilio: string,
    usuarioCodigoPostal: string,
    usuarioLocalidad: string,
    usuarioProvincia: string,
    usuarioObservaciones: string,
    nombreZona: string,
    tarjeta_numero: number,
    tiposolicitud: number,
    solicitud_destino: number,
    solicitud_origen: number,
    tarjeta_activa: boolean,
    fecha_pagado: string | null,
    tipo_pago: string
  ) {
    this.id = id
    this.codigo = codigo
    this.fechaSolicitud = fechaSolicitud
    this.estado = estado
    this.fechaAceptacion = fechaAceptacion
    this.fechaDenegacion = fechaDenegacion
    this.tramitaId = tramitaId
    this.matricula = matricula
    this.tarjetaId = tarjetaId
    this.importe = importe
    this.pagada = pagada
    this.annio = annio
    this.numero = numero
    this.iva = iva
    this.total = total
    this.documentosPendientes = documentosPendientes
    this.documentosCorrectos = documentosCorrectos
    this.facturaSerie = facturaSerie
    this.facturaNumero = facturaNumero
    this.factura_codigo = factura_codigo
    this.marca = marca
    this.modelo = modelo
    this.tipoClienteId = tipoClienteId
    this.medio = medio
    this.consentimiento = consentimiento
    this.zonaId = zonaId
    this.calleId = calleId
    this.domicilio = domicilio
    this.usuarioId = usuarioId
    this.destinatario = destinatario;
    this.usuarioEmail = usuarioEmail
    this.usuarioDni = usuarioDni
    this.usuarioNombre = usuarioNombre
    this.usuarioActivo = usuarioActivo
    this.usuarioTelefono = usuarioTelefono
    this.usuarioAvatar = usuarioAvatar
    this.usuarioRole = usuarioRole
    this.usuarioDomicilio = usuarioDomicilio
    this.usuarioCodigoPostal = usuarioCodigoPostal
    this.usuarioLocalidad = usuarioLocalidad
    this.usuarioProvincia = usuarioProvincia
    this.usuarioObservaciones = usuarioObservaciones
    this.nombreZona = nombreZona
    this.tarjeta_numero = tarjeta_numero
    this.tiposolicitud = tiposolicitud
    this.solicitud_destino = solicitud_destino
    this.solicitud_origen = solicitud_origen
    this.tarjeta_activa = tarjeta_activa
    this.fecha_pagado = fecha_pagado
    this.tipo_pago = tipo_pago
  }
}
