<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Posibles Renovaciones
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  <input
    type="text"
    class="form-control"
    placeholder="Buqueda por DNI: Ej: 232351Q"
    [(ngModel)]="filtro_texto"
    (input)="buscar_en_renovacion()"
  />



<!-- Barra de Navegacion -->
<paginator
  class=""
  [pagina_actual]="pagina_actual"
  [total_paginas]="total_paginas"
  (cambia_pagina)="cambia_pagina($event)"
/>
<!-- Barra de Navegacion -->
  <table class="table table-striped table-responsive table-sm table-hover mt-5">
    <thead class="thead-default">
      <th scope="col" class="text-center">Nombre</th>
      <th scope="col" class="text-center">Matrícula</th>
      <th scope="col" class="text-center">DNI</th>
      <th scope="col" class="text-center">Acciones</th>
    </thead>
    <tbody>
      <tr *ngFor="let row of renovaciones; index as x">
        <td class="text-center">
          {{ row.nombre }}
        </td>
        <td class="text-center">
          {{ row.matricula }}
        </td>
        <td class="text-center">
          {{ row.dni }}
        </td>
        <td class="text-center">
          <button
            class="btn btn-primary"
            type="button"
            (click)="selecciona_renovacion(x);active_modal.close()"
          >
            Renovar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="active_modal.close('')"
  >
    Cancelar
  </button>
</div>
