import { ICalle } from "../interface/calle.interface";

export class CalleModel implements ICalle {
  id: number;
  nombre: string;
  zonaId: number;

  constructor(
    id: number,
    nombre: string,
    zonaId: number,
  ) {
    this.id = id,
    this.nombre = nombre;
    this.zonaId = zonaId;
  }
}
