<form class="login-form" (ngSubmit)="onSubmit()" #loginForm="ngForm">
  <div class="login-header">
    <img class="banner-login__img" src="../../../../../assets/logo_limusa.png" alt="logo-limusa">
    <button type="button" class="btn-outline-principal" (click)="enviarA('registro')">Registrate</button>
  </div>
  <div class="">
    <app-input
      [control]="formGroup.controls.dni"
      [label]="'DNI *'"
      [type]="'text'"
      [id]="'dni'"
    ></app-input>
  </div>
  <div class="mt-3">
    <app-input
    [control]="formGroup.controls.password"
    [label]="'Contraseña *'"
    [type]="'password'"
    [id]="'password'"
    ></app-input>
  </div>
  <div class="mt-5 d-flex justify-content-between flex-wrap gap-2">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="!formGroup.valid"
    >Iniciar sesión</button>
    <button type="button" (click)="enviarA('/')" class="btn-outline-error">Cancelar</button>
  </div>
  <button type="button" (click)="enviarA('registro')" class="btn-footer btn-outline-principal">Registrate</button>
</form>
