import { IFiltro } from "../interface/filtro.interface";

export class FiltroModel implements IFiltro {
  id: number;
  id_nombre: string;
  key: string;
  label: string;
  defaultSelectText: string;
  defaultSelectValue: any;
  princ: string;
  campo: string;
  lista: any[];
  selectedIndex: number;

  constructor(
    // Id del filtro
    id: number,
    // Id y name para el select
    id_nombre: string,
    // Value del option
    key: string,
    // Label del option
    label: string,
    // Texto por defecto
    defaultSelectText: string,
    // Valor por defecto
    defaultSelectValue: any,
    // Campo con la información principal
    princ: string,
    // Campo sobre el que filtrar
    campo: string,
    // Lista sobre la que iterar
    lista: any[],
    // Valor seleccionado
    selectedIndex: number
  ) {
    this.id = id;
    this.id_nombre = id_nombre;
    this.key = key;
    this.label = label;
    this.princ = princ;
    this.campo = campo;
    this.lista = lista;
    this.defaultSelectText = defaultSelectText;
    this.defaultSelectValue = defaultSelectValue;
    this.selectedIndex = selectedIndex
  }
}
