import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from './../../../../services/https/auth.services';
import { saveToken, saveUser } from 'src/app/services/helpers/session.helper';
import { LoadService } from 'src/app/services/helpers/load.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

interface loginData {
  email: string;
  password: string;
  rememberMe: boolean;
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  providers: [AuthService],
})
export class LoginFormComponent implements OnInit {
  formGroup = new FormGroup({
    dni: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  constructor(
    private _authService: AuthService,
    private _loadService: LoadService,
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  enviarA(direccion: string) {
    this._router.navigate([direccion]);
  }

  async onSubmit() {
    let data: any = {
      dni: this.formGroup.value.dni ?? '',
      password: this.formGroup.value.password ?? '',
      rememberMe: false,
    };

    try {
      const res = await this._authService.login(data);
      const { usuario, token } = res;
      if (saveToken(token) && saveUser(usuario)) {
        this._loadService.notifyLoadChange(false);
        this._toastr.success(
          `Bienvenido ${usuario.nombre}`,
          'Iniciada sesión!'
        );
        this._router.navigate(['/solicitudes']);
      }
    } catch (e: any) {
      console.log(e);
      this._toastr.error(e);
    }
  }
}
