export function timeoutTime(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

// Función para convertir una cadena base64 en un Blob
export function base64toBlob(base64: string, contentType: string): Blob {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}


// Proporciona un nombre aleatorio
export function generateRandomName(): string {
  const timestamp = new Date().getTime();
  const randomString = Math.random().toString(36).substring(2);
  const uniqueName = `${timestamp}_${randomString}`;
  return uniqueName;
}
