<ngb-carousel
	#carousel
	[interval]="3000"
	[pauseOnHover]="pauseOnHover"
	[pauseOnFocus]="pauseOnFocus"
	(slide)="onSlide($event)"
>
	<ng-template ngbSlide *ngFor="let slider of sliders; index as i">
		<div class="carousel-caption">
			<h3 *ngIf="!slider.isButton">{{slider.text}}</h3>
      <button
        type="button"
        *ngIf="slider.isButton"
        class="btn btn-primary"
        (click)="enviarSlideId(slider.id)"
        >{{ slider.buttonText }}</button>
		</div>
    <div class="picsum-img-wrapper">
      <div class="slider-container" style="background-image: url({{slider.img}});background-size:cover;width: 100%; height: 65vh;background-position: center center;background-repeat: no-repeat;">
      </div>
    </div>
	</ng-template>
</ngb-carousel>
