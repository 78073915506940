import { ITipoDocuCliente } from "../interface/tipoDocuCliente.interface ";

export class TipoDocuClienteModel implements ITipoDocuCliente {
  id: number;
  nombre: string;
  activo: boolean;
  caducidad: boolean;
  obligatorio: boolean;
  observaciones: string;
  tipoDocumentacionId: number;
  tipoClienteId: number;
  cambio_domicilio: boolean;
  cambio_vehiculo: boolean;

  constructor(
    id: number,
    nombre: string,
    activo: boolean,
    caducidad: boolean,
    obligatorio: boolean,
    observaciones: string,
    tipoDocumentacionId: number,
    tipoClienteId: number,
    cambio_domicilio: boolean = false,
    cambio_vehiculo: boolean = false,
  ) {
    this.id = id;
    this.nombre = nombre;
    this.activo = activo;
    this.caducidad = caducidad;
    this.obligatorio = obligatorio;
    this.observaciones = observaciones;
    this.tipoDocumentacionId = tipoDocumentacionId;
    this.tipoClienteId = tipoClienteId;
    this.cambio_domicilio = cambio_domicilio;
    this.cambio_vehiculo = cambio_vehiculo;
  }

}
