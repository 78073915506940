import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-box-mensaje',
  templateUrl: './box-mensaje.component.html',
  styleUrls: ['./box-mensaje.component.scss']
})
export class BoxMensajeComponent implements OnInit{
  @Input() mensaje!: string;
  constructor(private route: ActivatedRoute, private nav: Router){}
  ngOnInit(): void {
  }
}
