<div class="usermenu-container">

  <a
    class="usermenu-item"
    [routerLink]="'/usuarios/'+idUsuario"
  >Mi perfil</a>
  <a
    class="usermenu-item"
    [routerLink]="'/solicitudes'"
  >Mis solicitudes</a>
  <a
    class="usermenu-item"
    [routerLink]="'/tarjetas'"
  >Mis tarjetas</a>
  <a
    class="usermenu-item"
    [routerLink]="'/avisos'"
  >Avisos</a>
  <a
    class="usermenu-item"
    (click)="cerrarSesion()"
  >Cerrar sesión</a>
</div>
