import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createZona } from 'src/app/services/helpers/model.helper';
import { generateRandomName, timeoutTime } from 'src/app/services/helpers/utils.helper';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { CallesZonaComponent } from '../calles-zona/calles-zona.component';
import { UploadServices } from 'src/app/services/https/upload.services';
import { globalState, tiposValidado } from 'src/global';
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faBell, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { faEye, faTrash
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nuevo-edita-zona',
  templateUrl: './nuevo-edita-zona.component.html',
  styleUrls: ['./nuevo-edita-zona.component.scss'],
  providers: [ZonasService, UploadServices]
})
export class NuevoEditaZonaComponent {

  // Iconos a mostrar
  verIcon: IconDefinition = faEye;
  eliminaIcon: IconDefinition = faTrash;

  public nombreMapa : string = '';
  public urlZona : string = '';
  public urlMapa : string = '';
  isNuevo: boolean = true;
  zona: ZonasModel = new ZonasModel(0,'','',true,'','',0,0,0,0,'');
  tipoCalle: any = CallesZonaComponent;
  uploadFile: any;
  uploadFileName: string = '';
  formGroup = new FormGroup({
    nombre: new FormControl<string>(this.zona.nombre, [Validators.required]),
    abreviatura: new FormControl<string>(this.zona.abreviatura, [Validators.required]),
    completo_base: new FormControl<number>(this.zona.completo_base, [Validators.required]),
    completo_iva: new FormControl<number>(this.zona.completo_iva, [Validators.required]),
    medio_base: new FormControl<number>(this.zona.medio_base, [Validators.required]),
    medio_iva: new FormControl<number>(this.zona.medio_iva, [Validators.required]),
    color: new FormControl<string>(this.zona.color, [Validators.required]),
    observaciones: new FormControl<string>(this.zona.observaciones, []),
    mapa: new FormControl<string>(this.zona.mapa, []),
  });

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _zonasService: ZonasService,
    private _uploadService: UploadServices,
    private _toastService: ToastrService,
    private _loadService: LoadService,
    config: NgbModalConfig,
    private modalService: NgbModal,
){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.isNuevo = params['slug'] === 'nuevo' ? true : false;
        if(!this.isNuevo) {
          this.obtenZona(params['slug']);
        }
      }
    });

    this.urlZona = globalState.urlZonas;
    this.urlMapa = '';

  }
  obtenZona(id: number) {
    this._loadService.notifyLoadChange(true);
    this._zonasService.ficha(id).subscribe({
      next: (res) => {

        this.zona = createZona(res.data[0]);
        this.uploadFileName = this.zona.mapa;

        this.actualizaFormGroup(this.zona);
        this._loadService.notifyLoadChange(false);
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);
        this._toastService.error(err.error.message);

      },
      complete: () => {
        this._loadService.notifyLoadChange(false);

      },
    })
  }

  actualizaFormGroup(zonaUpdated: ZonasModel) {
    this.formGroup.patchValue({
      nombre: zonaUpdated.nombre,
      completo_iva: zonaUpdated.completo_iva,
      medio_iva: zonaUpdated.medio_iva,
      completo_base: zonaUpdated.completo_base,
      medio_base: zonaUpdated.medio_base,
      abreviatura: zonaUpdated.abreviatura,
      color: zonaUpdated.color,
      observaciones: zonaUpdated.observaciones,
    })
  }

  onSubmit() {
    this._loadService.notifyLoadChange(true);
    const medioBaseValue: number = parseFloat(String(this.formGroup.value.medio_base ?? 0.00));
    const medioIvaValue: number = parseFloat(String(this.formGroup.value.medio_iva ?? 0.00));
    const completoBaseValue: number = parseFloat(String(this.formGroup.value.completo_base ?? 0.00));
    const completoIvaValue: number = parseFloat(String(this.formGroup.value.completo_iva ?? 0.00));
    const creaZona = new ZonasModel(
      this.zona.id,
      this.formGroup.value.nombre ?? '',
      this.formGroup.value.abreviatura ?? '',
      this.zona.activo,
      this.formGroup.value.observaciones ?? '',
      this.formGroup.value.color === '' ? '#000000' : this.formGroup.value.color ?? '#000000',
      completoBaseValue,
      completoIvaValue,
      medioBaseValue,
      medioIvaValue,
      this.uploadFileName
    )
    let funcion = this.isNuevo ? 'crea':'actualiza';
    this.creaActualiza(creaZona, funcion);
  }

  creaActualiza(creaZona: ZonasModel, funcion: string){
    this._zonasService.creaActualiza(creaZona, funcion).subscribe({
      next:(res) => {
        this._toastService.success(res.message);
        timeoutTime(1000).then(() => {
          this._loadService.notifyLoadChange(false);
          this.prepareUpload();
        })
      },
      error: (err) => {
        console.log(err);
        this._toastService.error(err.error.message);
        this._loadService.notifyLoadChange(false);
      },
      complete: () => {
        timeoutTime(1000).then(() => {
          this._loadService.notifyLoadChange(false);
        })
      }
    })
  }

  toggleActivo() {
    this.zona.activo = !this.zona.activo;
  }

  fileChangeEvent(event: Event) {
    if(event.target) {
      this.uploadFile = event.target;
      this.prepareName();
    }
  }

  prepareName() {
    try {
      if(this.uploadFile.files.length > 0) {
        this._loadService.notifyLoadChange(true);
        for(let i = 0; i < this.uploadFile.files.length; i++) {
          const extension = this.uploadFile.files[i].name.split('.').slice(-1)[0];
          this.uploadFileName = `${generateRandomName()}.${extension}`;
          this._loadService.notifyLoadChange(false);
        }
      }
    } catch (e: any) {
      this._loadService.notifyLoadChange(false);
    }
  }

  prepareUpload() {
    if(this.uploadFile.files.length > 0) {
      for(let i = 0; i < this.uploadFile.files.length; i++) {
        const formData = new FormData();
        const file = new File([this.uploadFile.files[i]], this.uploadFileName );
        formData.set('file', file);
        this.uploadImage(formData)
      }
    }
  }

  uploadImage(formData: any) {
    // formData.append('mapa', this.uploadFileName)
    this._loadService.notifyLoadChange(true)
    this._uploadService.subir(this.zona.id, formData, 'zonas').subscribe({
      next:(res) => {
        this._loadService.notifyLoadChange(false)
        this._nav.navigate(['/zonas']);
      },
      error:(err) => {
        this._loadService.notifyLoadChange(false)
        this._toastService.error(err.error.message, 'Error')
      },
      complete:() => {
        this._loadService.notifyLoadChange(false)
      }
    });
  }


  muestraMapa(content : any, url : string, nombre : string) {
    this.nombreMapa = nombre;
    this.urlMapa = url;
    this.modalService.open(content, { centered: true, size: 'lg' }).result.then(
            (result) => {
        //this.urlMapa = '';
            },
            (reason) => {
          },
        );
  }


  /**
   * Abre el modal que permite eliminar un dato de BBDD
   * @param content ID del modal
   * @param id Id del objeto a eliminar
   */
  confirmaEliminaMapa(content: any) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        this.zona.mapa = '';
      },
      (reason) => {}
    );
  }


}
