import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';

@Injectable()
export class TotalServices {
  public url: string = globalState.url ;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  total(filtro: string='', table: string): Observable<any> {
   const params = new HttpParams()
      .set('filtro', filtro)

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    //return this._http.post(this.url +  'zonas/ficha', params, httpOptions);

    return this._http.post(`${this.url + table}/total`, params, httpOptions);
  }
}
