import { Component, Input } from '@angular/core';
import { globalState } from 'src/global';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { ConfigGeneralModel } from 'src/app/models/entity/configuracion.model';

@Component({
  selector: 'factura-print',
  templateUrl: './factura-print.component.html',
  styleUrls: ['./factura-print.component.scss'],
  providers: [ConfiguracionService, SolicitudesServices],
})
export class FacturaPrintComponent {
  @Input() solicitud: number = 0;
  @Input() imprime: Boolean = false;

  public configuracion: any;
  public factura: any;
  public telefono = '';
  public localidad = '';
  public localidadcliente = '';

  constructor(
    // private _nav: Router,
    // private _router: ActivatedRoute
    private _service: ConfiguracionService,
    private _solicitudes: SolicitudesServices
  ) {}

  async ngOnInit(): Promise<void> {
    this.configuracion = await this.obtain_configuracion();

    if (this.configuracion) {
      this.telefono = `Teléfono y Fax: ${this.configuracion.telefono}`  ;
      this.localidad = `${this.configuracion.codigoPostal}  ${this.configuracion.localidad}`;
      if (
        this.configuracion.localidad != this.configuracion.provincia &&
        this.configuracion.provincia != ''
      ) {
        this.localidad =
          this.localidad + ' (' + this.configuracion.provincia + ')';
      }
    }

    if (this.solicitud > 0) {
      this.obten_solicitud(this.solicitud);
    }
  }

  ngDoCheck(): void {
    if (this.imprime) {
      this.imprime = false;
      this.imprimeFactura();
    }
  }

  async obtain_configuracion(): Promise<ConfigGeneralModel> {
    try {
      return await this._service.ficha_nueva(1)
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  async obten_solicitud(id: number) {
    try {
      this.factura = await this._solicitudes.ficha_nueva(id);
      console.log(this.factura);
      this.localidadcliente =
          this.factura.codigopostal + ' ' + this.factura.localidad;
        if (
          this.factura.localidad != this.factura.provincia &&
          this.factura.provincia != ''
        ) {
          this.localidadcliente =
            this.localidadcliente + ' (' + this.factura.provincia + ')';
        }


    } catch (e: any) {
      console.log(e);
    }
  }


  getFactura() {
    // Busca los datos de la tarjeta
    //this. getUrlTarjeta();
  }

  imprimeFactura() {
    window.print();
  }
}
