import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TarjetaModel } from 'src/app/models/entity/tarjeta.model';
import { TarjetaView } from 'src/app/models/entity/views/tarjetas.view';
import { createTarjetaVista } from '../helpers/model.helper';

@Injectable()
export class TarjetasServices {
  public url: string = globalState.url ;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
   const params = new HttpParams()
      .set('id', id+'')

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(this.url + 'tarjetas/ficha', params, httpOptions);
  }

  // Realiza el login del usuario
  fichaVista(id: number): Observable<any> {
   const params = new HttpParams()
      .set('id', id+'')

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(this.url + 'tarjetas/fichaVista', params, httpOptions);
  }



  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'tarjetas/lista', params, httpOptions);
  }

  // Realiza el login del usuario
  listapag(pagina : number,registros : number,filtro : string): Observable<any> {
    const params = new HttpParams()
        .set('pagina', pagina)
        .set('registros', registros)
        .set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tarjetas/listapag', params, httpOptions);
  }



  // Realiza el login del usuario
  total(filtro : string): Observable<any> {
    const params = new HttpParams()
      .set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'tarjetas/total', params, httpOptions);
  }

  // Realiza el login del usuario
  ultima(annio : number): Observable<any> {
    const params = new HttpParams()
      .set('annio', annio+'');
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'tarjetas/ultima', params, httpOptions);
  }

  // Realiza el login del usuario
  vista(filtro: string = ''): Observable<any> {
    const params = new HttpParams().set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tarjetas/vista', params, httpOptions);
  }


  // Realiza el login del usuario
  creaActualiza(model: TarjetaModel, funcion: string): Observable<any> {
    let params = new HttpParams()
    .set('annio', model.annio)
    .set('numero', model.numero)
    .set('usuarioId', model.usuarioId)
    .set('destinatario', model.destinatario)
    .set('fechaCreacion', model.fechaCreacion.toDateString())
    .set('activo', model.activo ? '1' : '0')
    .set('zonaId', model.zonaId)
    .set('matricula', model.matricula)
    .set('marca', model.marca)
     .set('modelo', model.modelo)
    .set('tipoclienteId', model.tipoclienteId)
    .set('facturaSerie', model.facturaSerie)
    .set('facturaNumero', model.facturaNumero)
    .set('observaciones', model.observaciones)

    if(funcion === 'actualiza') {
      params = params.set('id', model.id)
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'solicitudes/' + funcion, params, httpOptions);
  }

  // Realiza el login del usuario
  actualiza(model: TarjetaModel): Observable<any> {
    const params = new HttpParams()
      .set('id', model.id)
      .set('annio', model.annio)
      .set('numero', model.numero)
      .set('usuarioId', model.usuarioId)
      .set('destinatario', model.destinatario)
      .set('fechaCreacion', model.fechaCreacion.toDateString())
      .set('activo', model.activo ? '1' : '0')
      .set('zonaId', model.zonaId)
      .set('matricula', model.matricula)
      .set('marca', model.marca)
       .set('modelo', model.modelo)
      .set('tipoclienteId', model.tipoclienteId)
      .set('facturaSerie', model.facturaSerie)
      .set('facturaNumero', model.facturaNumero)
      .set('observaciones', model.observaciones)


    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tarjetas/actualiza', params, httpOptions);
  }

  actualizaActivo(id:number, activo: boolean): Observable<any> {
    const params = new HttpParams()
      .set('id', id)
      .set('activo', activo ? '1' : '0')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tarjetas/actualizaActivo', params, httpOptions);
  }

  actualizaObservaciones(id: number, destinatario: string, observaciones: string): Observable<any> {
    const params = new HttpParams()
      .set('id', id)
      .set('observaciones', observaciones)
      .set('destinatario', destinatario)

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tarjetas/actualizaObservaciones', params, httpOptions);
  }


  async lista_paginacion_nueva(pagina : number,registros : number,filtro : string): Promise<{tarjetas: TarjetaView[], pagina_actual: number, total_paginas: number}> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}tarjetas?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const list_tarjetas: TarjetaView[] = [];
      res.data.tarjetaes.forEach((item: any) => {
        list_tarjetas.push(createTarjetaVista(item))
      })
      const { pagina_actual, total_paginas } = res.data
      return { tarjetas: list_tarjetas, pagina_actual, total_paginas};
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }


  async ficha_vista(id: number): Promise<TarjetaView> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}tarjetas/${id}`, httpOptions))
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_activo(id: number, activo: boolean): Promise<TarjetaView> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    const json_payload = {
      activo: activo ? 1 :0
    }
    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}tarjetas/${id}`, json_payload, httpOptions))
      return createTarjetaVista(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al actualiza la tarjeta';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_observaciones(id: number, observaciones: string): Promise<TarjetaView> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    const json_payload = {
      observaciones: observaciones
    }
    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}tarjetas/${id}`, json_payload, httpOptions))
      return createTarjetaVista(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al actualiza la tarjeta';
      return Promise.reject(errorMessage);
    }
  }
}
