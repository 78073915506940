import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { globalState } from 'src/global';

@Component({
  selector: 'app-modal-condiciones',
  templateUrl: './modal-condiciones.component.html',
  styleUrls: ['./modal-condiciones.component.scss']
})
export class ModalCondicionesComponent {
  condiciones_content: SafeHtml = "";

  @Output() envia_condiciones: EventEmitter<boolean> = new EventEmitter<boolean>

  constructor(
    public active_modal: NgbActiveModal,
    private _satinizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.condiciones_content = this._satinizer.bypassSecurityTrustHtml(globalState.configuracion_global?.condiciones ?? "");
  }
}
