<div class="box-mensaje">
  <div class="box-mensaje-header">
    <img class="banner-login__img" src="../../../../../assets/logo-limusa.png" alt="logo-limusa">
  </div>
  <div class="box-mensaje-body mt-5 mb-5">
    <h5>{{mensaje}}</h5>
  </div>
  <div class="box-mensaje-footer mt-2 d-flex justify-content-evenly flex-wrap gap-2">
    <a
    [routerLink]="'/'"
      class="btn btn-aceptar"
    >Aceptar</a>
  </div>
</div>
