import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, Routes } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TarjetaModel } from 'src/app/models/entity/tarjeta.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { createTarjeta } from './../../../../../../services/helpers/model.helper';
import { globalState } from 'src/global';

@Component({
  selector: 'app-tarjeta-edit-nuevo',
  templateUrl: './tarjeta-edit-nuevo.component.html',
  styleUrls: ['./tarjeta-edit-nuevo.component.scss'],
  providers: [TarjetasServices]
})
export class TarjetaEditNuevoComponent{
  isNuevo: boolean = false;
  data: any = [];
  id: number = 0;
  formGroup = new FormGroup({
    annio:  new FormControl<number>(0, [Validators.required]),
    numero:  new FormControl<number>(0, [Validators.required]),
    usuarioId:  new FormControl<number>(0, [Validators.required]),
    destinatario:  new FormControl<string>('', []),
    activo:  new FormControl<boolean>(false, [Validators.required]),
    zonaId:  new FormControl<number>(0, [Validators.required]),
    matricula:  new FormControl<string>('', [Validators.required]),
    marca:  new FormControl<string>('', [Validators.required]),
    modelo:  new FormControl<string>('', [Validators.required]),
    tipoclienteId:  new FormControl<string>('', [Validators.required]),
    observaciones:  new FormControl<string>('', []),
  });

  constructor(
    private _service: TarjetasServices,
    private _toastService: ToastrService,
    private _load: LoadService,
    private _router: ActivatedRoute,
    private _nav: Router
  ) {
    this._load.notifyLoadChange(true);
    this._router.params.forEach((param: Params) => {

      if (param['slug'] !== undefined) {
        try {
          this.id = Number(param['slug']);
          this.getTarjeta(this.id);
        } catch {
          this._load.notifyLoadChange(false);
          this._nav.navigate(['/'])
        }
      }
    })

    if (globalState.identity) {
      if (globalState.identity.role == 0) {
        this.formGroup.controls.usuarioId.setValue(globalState.identity.id);
        //this.formGroup.controls.usuarioNombre.setValue(globalState.identity.nombre);
      }
    }
}

  async getTarjeta(id: number) {
    this._load.notifyLoadChange(false);
    try {
      this.data = await this._service.ficha_vista(id);
      this.actualizaFormGroup(this.data);
    } catch (e: any) {
      this._toastService.error(e)
    } finally {
      this._load.notifyLoadChange(false);
    }
  }


  actualizaFormGroup(modelUpdate: TarjetaModel) {
    this.formGroup.patchValue({
      annio: this.data.annio,
      numero: this.data.numero,
      destinatario: this.data.destinatario,
      usuarioId: this.data.usuarioId,
      matricula: this.data.matricula,
      marca: this.data.marca,
      observaciones: this.data.observaciones,
      modelo: this.data.modelo,
    })
  }

  async cambia_activo(activo: boolean) {
    try {
      this._load.notifyLoadChange(true);
      this.data = await this._service.actualiza_activo(this.data.id, activo);
    } catch (e: any) {
      this._toastService.error(e);
    } finally {
      this._load.notifyLoadChange(false);
    }

  }

  async onSubmit() {
    try {
      this._load.notifyLoadChange(true);
      this.data = await this._service.actualiza_observaciones(this.data.id, this.formGroup.value.observaciones ?? "");
    } catch (e: any) {
      this._toastService.error(e);
    } finally {
      this._load.notifyLoadChange(false);
    }
  }
}
