import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadService {
  private isLoadingChangedSource = new Subject<boolean>();
  loadChanged$ = this.isLoadingChangedSource.asObservable();

  notifyLoadChange(isLoading: boolean) {
    this.isLoadingChangedSource.next(isLoading);
  }
}
