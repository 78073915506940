import{ Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { AuthService } from '../https/auth.services';


@Injectable({
  providedIn: AuthService
})

export class AdminAccess {
  constructor( private _router: Router, private _loginService: AuthService ){}

  canActivate() {

    let identity: UsuarioModel | undefined = this._loginService.getIdentity();

    // Tiene acceso todos los usuario registrados
    if (identity && (identity.role === 2) ) {
      // Usuario autenticado y tiene el rol 'usuario'
      return true;
    } else {
      // Usuario no autenticado o no tiene el rol 'usuario', redirigir a otra página
      this._router.navigate(['/login']);
      return false;
    }
  }
}
