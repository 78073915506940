import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-reverse-switch',
  templateUrl: './reverse-switch.component.html',
  styleUrls: ['./reverse-switch.component.scss']
})
export class ReverseSwitchComponent {

  // Control del usuario dónde se define las propiedades y los validadores
  @Input() control = new FormControl();
  // Nombre sobre el input
  @Input() label!:string;
  // Id del campo
  @Input() id!:string;

  // Define los tipos y el mensaje de error a mostrar
  errorMessages: Record<string, string> = {
    required: "Este campo es obligatorio",
  }
}
