import {
  Component,
  Input,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { DynamicComponentDirective } from 'src/app/directives/dynamic-component';

@Component({
  selector: 'app-lineal-component-selector',
  templateUrl: './lineal-component-selector.component.html',
  styleUrls: ['./lineal-component-selector.component.scss']
})

/**
 * Este componente nos permite llamar a diferentes componentes con un click, el formato que tiene es el siguiente:
 * | componentsLabel[1] | | componentsLabel[2] | | componentsLabel[3] |
 * --------------------------------------------------------------------
 *  dynamicComponent[activeLabel]
 */
export class LinealComponentSelectorComponent {
  // Nombre que definen el componente
  @Input() componentsLabel!: string[];
  // Componentes a mostrar
  @Input() dynamicComponent!: any[];
  // Label activo
  activeLabel: string = '';
  // La directiva nos ayuda a mostrar un componente de forma dinámica
  @ViewChild(DynamicComponentDirective, { static: true }) dynamic!: DynamicComponentDirective;

  constructor(private cdr: ChangeDetectorRef) {}

  // Permite cargar el primer componente de dinámica y detecta los cambios que se hayan realizado
  ngAfterViewInit(): void {
    this.generateComponent(0);
    this.cdr.detectChanges();
  }

  /**
   * Crea el componente creado y se muestra en pantalla, además realiza el cambio de label activo
   * @param index posicion del componente en el array
   */
  generateComponent(index:number):void{
    // Cambio de label activo
    this.activeLabel = this.componentsLabel[index]
    // Selección del container con el componente actual
    const viewContainerRef = this.dynamic.viewContainerRef;
    // Se limpia container
    viewContainerRef.clear();

    // Insertamos el nuevo componente a funcionar
    viewContainerRef.createComponent<any>(this.dynamicComponent[index]);
  }
}
