import {  UsuarioModel } from "src/app/models/entity/usuario.model";
import { globalState } from "src/global";
import { IUsuario } from './../../models/interface/usuario.interface';

export function obtainToken() :string {
  try {
    const token = localStorage.getItem(globalState.tokenName);
    if(typeof(token) !== "string") {
      return 'TokenInvalido';
    } else {
      return token;
    }
  } catch(e: any) {
    return 'TokenInvalido';
  }
}

export function obtainVersion() :number {
  try {
    const versionCode = localStorage.getItem('versionCode');
    if(typeof(versionCode) !== "string") {
      return 0;
    } else {
      return Number(versionCode);
    }
  } catch(e: any) {
    return 0
  }
}

export function saveToken(token: string): boolean {
  try {
    // Guardamos en local
    localStorage.setItem(globalState.tokenName, token);
    // Guardamos en variable
    globalState.token = token;
    return true;
  } catch(e: any) {
    return false;
  }
}

export function saveVersion(version: number): boolean {
  try {
    localStorage.setItem('versionCode', version.toString())
    return true
  } catch(e: any) {
    return false
  }
}

export function saveUser(response: UsuarioModel) :boolean {
  try {
    let usuario: UsuarioModel = response;
    globalState.identity = usuario;
    return true;
  }catch(e: any) {
    return false;
  }
}


export function deleteToken() {
  localStorage.removeItem(globalState.tokenName);
  window.location.replace(globalState.frontUrl);
}
