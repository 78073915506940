import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { LoadService } from 'src/app/services/helpers/load.service';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [UsuariosService]
})
export class ChangePasswordComponent {

  token : string = '';
  objToken : any;

  caducidad : Date | null = null;

  formGroup = new FormGroup({
    clave1: new FormControl<string>('', [Validators.required]),
    clave2: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _toastService: ToastrService,
    private _loadService: LoadService,
    private _dataServices: UsuariosService,
  ) {

  }

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      this.token = params['slug'];
    });

    this.objToken = JSON.parse(atob(this.token));
    this.caducidad = new Date(this.objToken.caducidad);

    // Comprueba si está caducado
    if (this.caducidad < new Date()) {
      this._toastService.error('La solicitud ha caducado');
      this._nav.navigate(['/']);
    }

  }

  onSubmit(formulario: any){

    if ((this.formGroup.value.clave1 == '') || (this.formGroup.value.clave2 == '')) {
      this._toastService.error('La contraseña no puede estar vacía');
    } else {
      if (this.formGroup.value.clave1 != this.formGroup.value.clave2) {
        this._toastService.error('Las contraseñas son diferentes');
      }
      else {
        this._loadService.notifyLoadChange(true);
        this._dataServices.changePassword(this.objToken.id,this.formGroup.controls.clave1.value).subscribe({
          next: (res) => {

              this._toastService.success('Contraseña actualizada correctamente');
              timeoutTime((1000)).then(() => {
              this._loadService.notifyLoadChange(false);
              this._nav.navigate(['/']);
            })
          },
          error: (err) => {
            this._loadService.notifyLoadChange(false);
            this._toastService.error(err.error.message);
          },
          complete: () => {
            this._loadService.notifyLoadChange(false);
          }
        })

      }
    }
  }

}
