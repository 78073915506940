import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TipoDocuModel } from 'src/app/models/entity/tipoDocu.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createTipoDocu } from 'src/app/services/helpers/model.helper';
import { TipoDocuService } from 'src/app/services/https/tipoDocu.services';

@Component({
  selector: 'app-nuevo-edita-tipo-documentacion',
  templateUrl: './nuevo-edita-tipo-documentacion.component.html',
  styleUrls: ['./nuevo-edita-tipo-documentacion.component.scss']
})
export class NuevoEditaTipoDocumentacionComponent {
  isNuevo: boolean = true;
  data: TipoDocuModel = new TipoDocuModel(0,'',true, false);
  formGroup = new FormGroup({
    nombre: new FormControl<string>(this.data.nombre, [Validators.required]),
    caducidad: new FormControl<boolean>(false, [Validators.required]),
  })

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: TipoDocuService,
    private _toast: ToastrService,
    private _loader: LoadService,
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.isNuevo = params['slug'] === 'nuevo' ? true : false;
        if(!this.isNuevo) {
          this.obten_tipo_documentacion(params['slug']);
        }
      }
    });
  }

  async obten_tipo_documentacion(id: number) {
    try {
      this._loader. notifyLoadChange(true)
      this.data = await this._service.ficha_nuevo(id)
      this.actualizaFormGroup(this.data);
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader. notifyLoadChange(false)
    }
  }


  actualizaFormGroup(zonaUpdated: TipoDocuModel) {
    this.formGroup.patchValue({
      nombre: zonaUpdated.nombre,
    })
  }

  async onSubmit() {
    this._loader.notifyLoadChange(true);
    const nuevo_docu = new TipoDocuModel(
      this.data.id,
      this.formGroup.value.nombre ?? '',
      this.data.activo,
      this.formGroup.value.caducidad ?? false,
    )
    if (this.isNuevo) {
      const data = await this._service.crea_nuevo(nuevo_docu);
      this._nav.navigate(['tipo-documentacion/'+data.id]);
    } else {
      const data = await this._service.actualiza_nuevo(nuevo_docu.id, nuevo_docu);
      window.location.reload();
    }
  }


  toggleActivo() {
    this.data.activo = !this.data.activo;
  }
}
