import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TipoDocuModel } from 'src/app/models/entity/tipoDocu.model';
import { TipoDocuClienteModel } from 'src/app/models/entity/tipoDocuCliente.model';
import { ITipoDocu } from 'src/app/models/interface/tipoDocu.interface ';
import { ITipoDocuCliente } from 'src/app/models/interface/tipoDocuCliente.interface ';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createTipoDocu, createTipoDocuCliente, createDocumento } from 'src/app/services/helpers/model.helper';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { DocumentosService } from 'src/app/services/https/documentos.services';
import { TipoDocuService } from 'src/app/services/https/tipoDocu.services';
import { TipoDocuClienteService } from 'src/app/services/https/tipoDocuCliente.services';

@Component({
  selector: 'app-nuevo-edita-docu-cliente',
  templateUrl: './nuevo-edita-docu-cliente.component.html',
  styleUrls: ['./nuevo-edita-docu-cliente.component.scss'],
  providers: [TipoDocuClienteService, TipoDocuService, DocumentosService]
})
export class NuevoEditaDocuClienteComponent implements OnInit{

  @Input() idCliente!: number;
  @Input() type: string = '';
  data: TipoDocuClienteModel = new TipoDocuClienteModel(0,'',true,true,false,'',0,0);
  id: number = 0;
  isNuevo: boolean = false;
  tipo_documentacion_lista: TipoDocuModel[] = [];
  nombreDocuSeleccionada: string = '';
  tipoCliente: boolean = true;

  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    tipoDocumentacionId: new FormControl<number>(0,[Validators.required]),
    caducidad: new FormControl<boolean>(false,[Validators.required]),
    obligatorio: new FormControl<boolean>(false,[Validators.required]),
    observaciones: new FormControl<string>('',[]),
    cambio_domicilio: new FormControl<boolean>(false, [Validators.required]),
    cambio_vehiculo: new FormControl<boolean>(false, [Validators.required])
  });

  constructor(
    private _services: TipoDocuClienteService,
    private _sup_services: TipoDocuService,
    private _docuServices: DocumentosService,
    private _route: ActivatedRoute,
    private _nav: Router,
    private _toast: ToastrService,
    private _loader: LoadService,
  ) {
    // this.obtenerDocumentacion();
  }

  async ngOnInit(): Promise<void> {
    this._loader.notifyLoadChange(true);
    this.tipo_documentacion_lista = await this.obtener_documentacion();
    try {
      this._route.params.subscribe(async (params: Params) => {
        this.isNuevo = params['slug'] === 'nuevo' ? true : false;
        this.idCliente = params['id'];
        if(!this.isNuevo) {
          this.id = Number(params['slug']);
          this.data = await this.obtener_tipo_docu_cliente()
          this.actualizaFormGroup(this.data);
        }
      });
      this._route.url.subscribe((url: Array<any>) => {
        // Comprobamos si es del tipo cliente o del tipo solicitud
        this.tipoCliente = url[1] === 'solicitud';
      })
    } catch(e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false)
    }
  }

  async obtener_documentacion(): Promise<TipoDocuModel[]> {
    try {
      return await this._sup_services.lista_completa();
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  async obtener_tipo_docu_cliente(): Promise<TipoDocuClienteModel> {
    try {
      return await this._services.ficha_nuevo(this.id);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  actualizaFormGroup(updateInf: TipoDocuClienteModel) {
    this.formGroup.patchValue({
      nombre: updateInf.nombre,
      tipoDocumentacionId: updateInf.tipoDocumentacionId,
      caducidad: updateInf.caducidad,
      obligatorio: updateInf.obligatorio,
      observaciones: updateInf.observaciones,
      cambio_domicilio: updateInf.cambio_domicilio,
      cambio_vehiculo: updateInf.cambio_vehiculo
    })
  }

  onSubmit(){
    let tipoDocu = new TipoDocuClienteModel(
      this.isNuevo ? 0 : this.data.id,
      this.formGroup.value.nombre ?? '',
      this.data.activo,
      this.formGroup.value.caducidad ?? false,
      this.formGroup.value.obligatorio ?? false,
      this.formGroup.value.observaciones ?? '',
      this.formGroup.value.tipoDocumentacionId ?? 0,
      this.idCliente,
      this.formGroup.value.cambio_domicilio ?? false,
      this.formGroup.value.cambio_vehiculo ?? false
    )

    if (this.isNuevo) {
      this.crea(tipoDocu)
    } else {
      this.actualiza(tipoDocu)
    }
  }

  async crea(model: TipoDocuClienteModel){
    try {
      this._loader.notifyLoadChange(true);
      const data = await this._services.crea_nuevo(model)
      this._nav.navigate(['/tipo-cliente/' + this.idCliente])
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async actualiza(model: TipoDocuClienteModel) {
    try {
      this._loader.notifyLoadChange(true);
      const data = await this._services.actualiza_nuevo(this.id, model)
      window.location.reload()
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  cambia_activo() {
    this.data.activo = !this.data.activo;
  }
}
