<!-- Lista -->
<div *ngIf="typeForm === 'lista'">
  <div class="mt-5">
    <app-table-default *ngIf="typeForm === 'lista'"
      [data] = "documentos"
      [columnas_tabla] = "['nombre', 'activo', 'caducidad','obligatorio', 'cambio_domicilio', 'cambio_vehiculo']"
      [tipo_dato_tabla] = "['','boolean', 'boolean','boolean', 'boolean', 'boolean']"
      [nombre_columnas] = "['Nombre', 'Activo', 'Caducidad','Oblig.', 'C. Domicilio', 'C.Vehículo']"
      [alineacion_columnas]="['start','','','','','']"
      [ancho_columnas] = "['','10%', '10%']"
      [propiedades_para_filtro] = "['nombre']"
      [nombre_tabla] = "'tipo_cliente_documentacion'"
      [ver_boton_editar]="true"
      [ver_boton_borrar]="true"
      [id_nombre] = "'id'"
      [ruta_nueva]="'/tipo-documentacion-cliente/client/'+ id_cliente +'/tipo/nuevo'"
      [ruta_edita]="'/tipo-documentacion-cliente/client/'+ id_cliente +'/tipo/'"
      (envia_borrado)="abrir_modal_borrado($event)"
    >
    </app-table-default>
  </div>
</div>
<!-- Nuevo -->
<div *ngIf="typeForm === 'nuevo'">
  <div class="position-relative mt-5">
    <app-nuevo-edita-docu-cliente>
    </app-nuevo-edita-docu-cliente>
  </div>
</div>

<!-- Edita -->
