<app-page-container-publico [title]="'Tipo Cliente'">
  <div class="container-fluid">
    <ng-container *ngIf="typeForm === ''">
      <h3
        *ngIf="allData.length <= 0"
        class="text-gray text-center mt-5"
      >
        No hay registros
      </h3>
      <app-table-default
        *ngIf="allData.length > 0"
        [data] = allData
        [columnas_tabla] = "['nombre', 'color', 'activo']"
        [tipo_dato_tabla]="['','color','boolean']"
        [nombre_columnas] = "['Nombre', 'Color', 'Activo']"
        [alineacion_columnas]="['start','','']"
        [ancho_columnas] = "[]"
        [propiedades_para_filtro] = "['id','nombre']"
        [nombre_tabla] = "'tipo_cliente'"
        [ver_boton_editar]="true"
        [ver_boton_borrar]="true"
        [id_nombre] = "'id'"
        (envia_borrado)="abrir_modal_borrado($event)"
      ></app-table-default>
    </ng-container>
    <app-nuevo-edita-tipo-cliente *ngIf="typeForm!== ''"></app-nuevo-edita-tipo-cliente>
  </div>
</app-page-container-publico>
