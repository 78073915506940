<div class="d-flex justify-content-end container">
  <div class="d-flex gap-2">
    <a target="_blank" href="https://www.facebook.com/LorcaLimusa">
      <fa-icon class="header-icon" [icon]="faFacebook"></fa-icon>
    </a>
    <a target="_blank" href="https://api.whatsapp.com/send?phone=%3C+34646073578%3E">
      <fa-icon class="header-icon" [icon]="faWhatsapp"></fa-icon>
    </a>
    <a target="_blank" href="https://twitter.com/LorcaLimusa">
      <fa-icon class="header-icon" [icon]="faTwitter"></fa-icon>
    </a>
  </div>
</div>
