<div class="modal-header">
  <h4 class="modal-title">CONDICIONES DE USO DE LA TARJETA (EXTRACTO ORDENANZAS)</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  <div [innerHTML]="condiciones_content"></div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-error"
    (click)="active_modal.close()"
  >
    Cerrar
  </button>
</div>
