import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registro-form',
  templateUrl: './registro-form.component.html',
  styleUrls: ['./registro-form.component.scss'],
  providers: [UsuariosService]
})

export class RegistroFormComponent implements OnInit{

  emailMessage: string = '';
  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    dni: new FormControl<string>('', [Validators.required]),
    domicilio: new FormControl<string>('', [Validators.required]),
    codigoPostal: new FormControl<string>('', [Validators.required]),
    localidad: new FormControl<string>('', [Validators.required]),
    provincia: new FormControl<string>('', [Validators.required]),
    telefono: new FormControl<string>('', [Validators.required, Validators.pattern(/^\d{9}$/),]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', [Validators.required]),
  })

  constructor(
    private _usuariosService: UsuariosService,
    private _loadService: LoadService,
    private _toastr: ToastrService,
    private _route: Router,
    ) {}

  ngOnInit(): void {
  }

  onSubmit() {
   this._loadService.notifyLoadChange(true);
    const nuevoUsuario = new UsuarioModel(
      0,
      this.formGroup.value.email ?? '',
      this.formGroup.value.dni ?? '',
      this.formGroup.value.nombre ?? '',
      true,
      this.formGroup.value.telefono ?? '',
      this.formGroup.value.password ?? '',
      '',
      1,
      this.formGroup.value.domicilio ?? '',
      this.formGroup.value.codigoPostal ?? '',
      this.formGroup.value.localidad ?? '',
      this.formGroup.value.provincia ?? '',
      '',
    );
    this.creaUsuario(nuevoUsuario);
  }

  creaUsuario(nuevoUsuario: UsuarioModel) {
    this._usuariosService.crea(nuevoUsuario).subscribe({
      next:(res) => {
        this._toastr.success(res.message, 'Creado');
        this._route.navigate([`/aviso-activa-email`]);
      },
      error:(err) => {
        this._toastr.error(err.error.message, '¡Error!')
        this._loadService.notifyLoadChange(false);
      },
      complete:() => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }
}
