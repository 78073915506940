<!--<app-table-default
  [data]="allData"
  [tableColumns]="['nombre', 'observaciones', 'validado','obligatorio']"
  [tableBooleans]="[false, true, false, true, true]"
  [labelColumns]="['Nombre', 'Observaciones', 'Validado','Obligatorio']"
  [alignColumns]="['start','start','','']"
  [tableItemType]="['', 'openModal', 'numberLabel', 'boolean']"
  [labelsValidado]="['Pendiente', 'Validado', 'Rechazado']"
  [widthColumns]="['', '', '', '']"
  [searcher]="['id', 'nombre']"
  [tableName]="'documentos'"
  [idName]="'id'"
  [isPrincipal]="false"
  [routerNew]="'/tipo-documentacion-cliente/solicitud/'+ solicitudId +'/tipo/nuevo'"
  [routerEdit]="''"
  [showDeleteButton]="true"
  [showEditButton]="false"
  [showDownloadButton]="true"
  [showUploadButton]="true"
  [editableObservaciones]="true"
/> -->
<div class="pb-5">
  <app-table-default
    [data]="documentos"
    [columnas_tabla]="['nombre', 'observaciones', 'valida_id','obligatorio']"
    [nombre_columnas]="['Nombre', 'Observaciones', 'Validado','Obligatorio']"
    [alineacion_columnas]="['start','start','','']"
    [tipo_dato_tabla]="['', 'openModal', 'validado', 'boolean' ]"
    [ancho_columnas]="['', '', '', '']"
    [propiedades_para_filtro]="['id', 'nombre']"
    [nombre_tabla]="'documentos'"
    [id_nombre]="'id'"
    [ruta_nueva]="'/tipo-documentacion-cliente/solicitud/'+ solicitud_id +'/tipo/nuevo'"
    [ruta_edita]="''"
    [ver_boton_borrar]="true"
    [ver_boton_editar]="false"
    [ver_boton_descargar]="true"
    [ver_boton_subir]="true"
    [ver_editar_observaciones]="true"
    (recarga_datos)="recarga_datos(solicitud_id)"
    />
</div>
