<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4">
    <h6 *ngIf="isNuevo" class="mb-0">Registro de Tipo Documentación</h6>
    <h6 *ngIf="!isNuevo" class="mb-0">Actualización de Tipo Documentación</h6>
    <div class="form-check form-switch form-check">
      <input
        #activo
        name="activo"
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="data.activo"
        id="activo"
      >
      <label *ngIf="data.activo" class="form-check-label" for="activo">Activo</label>
      <label *ngIf="!data.activo" class="form-check-label" for="activo">Inactivo</label>
    </div>
  </div>
  <div class="form-body-three-cols mt-3">
    <app-input
      [control]="formGroup.controls.nombre"
      [label]="'Nombre *'"
      [type]="'text'"
      [id]="'nombre'">
    </app-input>
    <app-reverse-switch
      class="d-flex justify-content-center align-content-center flex-wrap"
      [control]="formGroup.controls.caducidad"
      [id]="'caducidad-switch'"
      [label]="'¿Tendrá caducidad?'"
    ></app-reverse-switch>
  </div>
  <div class="d-flex gap-3 justify-content-end">
    <button type="submit" class="btn btn-aceptar mt-5">
      <span  *ngIf="isNuevo">
        Crear
      </span>
      <span  *ngIf="!isNuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/tipo-documentacion'" class="btn btn-outline-error mt-5" >
      Cancelar
    </a>
  </div>
</form>
