import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/https/auth.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-usuario-options',
  templateUrl: './usuario-options.component.html',
  styleUrls: ['./usuario-options.component.scss']
})
export class UsuarioOptionsComponent implements OnInit {
  // Elementos declarados para ocultar el menu de usuario al hacer click out
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('menu') menu!: ElementRef;

  identity = globalState.identity;
  fechaAbajo: IconDefinition = faCaretDown;
  isOpen: boolean = false;

  constructor(
    private _authService: AuthService,
    private _renderer: Renderer2,
    ) {
    this.identity = this._authService.getIdentity();
  }

  ngOnInit(): void {
    this._renderer.listen('document', 'click', (event: Event) => {
      // Verifica si el evento de clic se originó fuera del cuadro del menú de usuario
      if (
        !this.toggleButton.nativeElement.contains(event.target) &&
        !this.menu.nativeElement.contains(event.target)
      ) {
        this.isOpen = false;
      }
    });
  }

  userMenuToggle() {
    this.isOpen  = !this.isOpen;
  }

}
