import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createZona } from 'src/app/services/helpers/model.helper';
import { ZonasService } from 'src/app/services/https/zonas.services';

@Component({
  selector: 'app-zonas-page',
  templateUrl: './zonas-page.component.html',
  styleUrls: ['./zonas-page.component.scss'],
  providers: [ZonasService]
})
export class ZonasPageComponent implements OnInit{
  typeForm: string = '';
  allZonas: ZonasModel[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _zonasService: ZonasService,
    private _loadService: LoadService,
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
      }
    });
  }

  ngOnInit(){
    if(this.typeForm === '') {
      this.obtainZonas();
    }
  }

  obtainZonas() {
    this._loadService.notifyLoadChange(true);
    this._zonasService.lista().subscribe({
      next: (res) => {
        res.data.forEach((element: any) => {
          this.allZonas.push(
            createZona(element)
          );
        });
      this._loadService.notifyLoadChange(false);
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }


}
