import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { ICalle } from 'src/app/models/interface/calle.interface';
import { IZona } from 'src/app/models/interface/zona.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createCalle, createZona } from 'src/app/services/helpers/model.helper';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { CallesService } from 'src/app/services/https/calles.services';
import { ZonasService } from 'src/app/services/https/zonas.services';

@Component({
  selector: 'app-nuevo-edita-calles',
  templateUrl: './nuevo-edita-calles.component.html',
  styleUrls: ['./nuevo-edita-calles.component.scss'],
  providers: [ZonasService, CallesService]
})
export class NuevoEditaCallesComponent {

  @Input() idZona!: number;
  @Input() type: string = '';
  // Id a la que va dirigida el formulario -- solo cuando actualiza
  id: number = 0;
  // Información a la que va dirigida el formulario
  data: CalleModel = new CalleModel(0,'',0);
  // Permite distinguir entre crear o editar
  isNuevo: boolean = false;
  // Datos de apoyo, en este caso se usa para obtener los datos del select
  supData: ZonasModel[] = [];

  // Datos del formulario
  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    zonaId: new FormControl<number>(0,[Validators.required]),
  });

  constructor(
    private _dataServices: CallesService,
    private _supServices: ZonasService,
    private _route: ActivatedRoute,
    private _nav: Router,
    private _toastService: ToastrService,
    private _loadService: LoadService,
  ) {
    this.obtenerSupData();
  }

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      this.isNuevo = params['slug'] === 'nuevo' ? true : false;
      this.idZona = params['id'];
      if(!this.isNuevo) {
        this.id = Number(params['slug']);
        this.obtenData()
      } else {
        this.data = new CalleModel(0, '', this.idZona);
        this.actualizaFormGroup(this.data);
      }
    });
  }

  /**
   * Obtiene los datos de apoyo, en este caso los datos del select
   */
  obtenerSupData() {
    this._supServices.lista().subscribe({
      next: (res) => {
        res.data.forEach((element: any) => {
          this.supData.push(
            createZona(element)
            );
        })
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);
        this._toastService.error(err.error.message);
      },
      complete: () => {

      }
    })
  }

  /**
   * Obtiene la información principal del formulario
   * Solo se usa cuando no es un nuevo registro
   */
  obtenData() {
    this._loadService.notifyLoadChange(true);
    this._dataServices.ficha(this.id).subscribe({
      next: (res) => {
        res.data.forEach((element: any) => {
          this.data = createCalle(element)
        })
        this.actualizaFormGroup(this.data);
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);
        this._toastService.error(err.error.message);
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }

  /**
   * Envío de formulario
   */
  onSubmit(){
    let calle = new CalleModel(
      this.isNuevo ? 0 : this.data.id,
      this.formGroup.value.nombre ?? '',
      this.formGroup.value.zonaId ?? 0,
    )

    let func = this.isNuevo ? 'crea': 'actualiza'
    this.creaActualiza(calle, func);
  }

  creaActualiza(data: CalleModel, func: string) {
    this._loadService.notifyLoadChange(true);
    this._dataServices.creaActualiza(data, func).subscribe({
      next:(res) => {
        this._toastService.success(res.message);
        timeoutTime(1000).then(() => {
          this._loadService.notifyLoadChange(false);
          this._nav.navigate(['/zonas/' + this.idZona])
        })
      },
      error: (err) => {
        this._toastService.error(err.error.message);
        timeoutTime(1000).then(() => {
          this._loadService.notifyLoadChange(false);
        })
      },
      complete: () => {
        timeoutTime(1000).then(() => {
          this._loadService.notifyLoadChange(false);
          this._nav.navigate(['/zonas/' + this.idZona])
        })
      }
    })
  }

  /**
   * Actualiza los datos para el formulario
   * @param updateInf
   */
  actualizaFormGroup(updateInf: CalleModel) {
    this.formGroup.patchValue({
      nombre: updateInf.nombre,
      zonaId: updateInf.zonaId
    })
  }
}

