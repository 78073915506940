import { Component, Input } from '@angular/core';
import { ModelSlider } from 'src/app/models/entity/slider.model';

@Component({
  selector: 'app-inicio-page',
  templateUrl: './inicio-page.component.html',
  styleUrls: ['./inicio-page.component.scss']
})
export class InicioPageComponent {
  sliders: ModelSlider[] = [
    new ModelSlider(
      0,
      '../../../../assets/slider_1.png',
      'Slider1',
      false,
      '',
      ''
    ),
    new ModelSlider(
      1,
      '../../../../assets/slider_2.png',
      'Slider2',
      false,
      '',
      ''
    ),
    new ModelSlider(
      2,
      '../../../../assets/slider_3.png',
      'Slider3',
      false,
      '',
      ''
    ),
  ];

}
