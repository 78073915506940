import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';

@Injectable()
export class BorradoServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  borrar(id: number, table: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    //return this._http.post(this.url +  'zonas/ficha', params, httpOptions);

    return this._http.delete(`${this.url + table}/${id}`, httpOptions);
  }

  // Realiza el login del usuario
  async borrar_nuevo(id: number, table: string): Promise<string> {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    //return this._http.post(this.url +  'zonas/ficha', params, httpOptions);
    try {
      const data = await firstValueFrom(this._http.delete<any>(`${this.url + table}/${id}`, httpOptions));
      return "Eliminado con éxito";
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al iniciar sesión';
      return Promise.reject(errorMessage);
    }
  }

}
