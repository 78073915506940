import { ITipoCliente } from "../interface/tipoCliente.interface";

export class TipoClienteModel implements ITipoCliente {
  id: number;
  nombre: string;
  activo: boolean;
  color: string;

  constructor(
    id: number,
    nombre: string,
    activo: boolean,
    color: string,
  ) {
    this.id = id;
    this.nombre = nombre;
    this.activo = activo;
    this.color = color;
  }
}
