
export class TarjetaView {
  id: number;
  codigo: string;
  annio: number;
  numero: number;
  usuarioId: number;
  destinatario: string;
  fechaCreacion: Date;
  activo: boolean;
  zonaId: number;
  matricula: string;
  modelo: string;
  marca: string;
  tipoclienteId: number;
  facturaSerie: string;
  facturaNumero: number;
  observaciones: string;
  solicitudId: number;
  nombre: string;
  email: string;
  dni: string;
  avatar: string;
  role: number;
  domicilio: string;
  codigoPostal: string;
  localidad: string;
  provincia: string;
  telefono: string;
  observacionesUsuario: string;
  zonaAbreviatura: string;
  zonaColor: string;
  constructor(
    id: number,
    codigo: string,
    annio: number,
    numero: number,
    usuarioId: number,
    destinatario: string,
    fechaCreacion: Date,
    activo: boolean,
    zonaId: number,
    matricula: string,
    modelo: string,
    marca: string,
    tipoclienteId: number,
    facturaSerie: string,
    facturaNumero: number,
    observaciones: string,
    solicitudId: number,
    nombre: string,
    email: string,
    dni: string,
    avatar: string,
    role: number,
    domicilio: string,
    codigoPostal: string,
    localidad: string,
    provincia: string,
    telefono: string,
    observacionesUsuario: string,
    zonaAbreviatura: string,
    zonaColor: string
  ) {
    this.id = id;
    this.codigo = codigo;
    this.annio = annio;
    this.numero = numero;
    this.usuarioId = usuarioId;
    this.destinatario = destinatario;
    this.fechaCreacion = fechaCreacion;
    this.activo = activo;
    this.zonaId = zonaId;
    this.matricula = matricula;
    this.modelo = modelo;
    this.marca = marca;
    this.tipoclienteId = tipoclienteId;
    this.facturaSerie = facturaSerie;
    this.facturaNumero = facturaNumero;
    this.observaciones = observaciones;
    this.solicitudId = solicitudId;
    this.nombre = nombre;
    this.email = email;
    this.dni = dni;
    this.avatar = avatar;
    this.role = role;
    this.domicilio = domicilio;
    this.codigoPostal = codigoPostal;
    this.localidad = localidad;
    this.provincia = provincia;
    this.telefono = telefono;
    this.observacionesUsuario = observacionesUsuario;
    this.zonaAbreviatura = zonaAbreviatura;
    this.zonaColor = zonaColor
  }

}
