import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { generateRandomName } from 'src/app/services/helpers/utils.helper';
import { DocumentosService } from 'src/app/services/https/documentos.services';

@Component({
  selector: 'app-modal-subir-archivo',
  templateUrl: './modal-subir-archivo.component.html',
  styleUrls: ['./modal-subir-archivo.component.scss'],
  providers: [DocumentosService]
})

export class ModalSubirArchivoComponent {
  @Input() id_documento!: number;
  evento_subida: any

  constructor(
    public active_modal: NgbActiveModal,
    private _load_service: LoadService,
    private _toastr: ToastrService,
    private _documento_service: DocumentosService
  ) { }

  formatear_archivo(evento: any) {
    console.log(evento);
    this.evento_subida = evento.target;
  }

  async subir_archivo() {
    try {
      console.log(this.id_documento)
      const upload_event = this.evento_subida;
      // Comprobamos que exista algún archivo seleccionado en el evento
      if (upload_event.files.length > 0) {
        // Indicamos que se va a realizar una petición http (pantalla de carga)
        this._load_service.notifyLoadChange(true);
        // Recorremos los archivos
        for (let i = 0; i < upload_event.files.length; i++) {
          // Se guarda extension
          const extension = upload_event.files[i].name
            .split('.')
            .slice(-1)[0];
          // Se guarda nombre de archivo
          const filename = `${generateRandomName()}.${extension}`;
          // Se genera datos del formulario
          const form_data = new FormData();
          // Creamos un tipo archivo
          const file = new File([upload_event.files[i]], filename);
          // Insertamos en los datos
          form_data.set('file', file);
          // Subida de imagenes
          const msg = await this._documento_service.subir_imagen(this.id_documento, form_data);
          this._toastr.success(msg);
        }
      }
    } catch (e: any) {
      this._load_service.notifyLoadChange(false);
      this._toastr.error('No ha sido posible subir el archivo.');
    } finally {
      this._load_service.notifyLoadChange(false);
      window.location.reload();
    }
  }
}
