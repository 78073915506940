<div class="modal-header">
  <h4 class="modal-title">Solicitar Cambio de Domicilio</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  <div class="mt-3">
    <div class="row">
      <app-select-input
      [label]="'Zona*'"
      style="display: block; width: 100%"
      [optionData]="zonas_data"
      [optionsLabels]="['id', 'nombre']"
      [defaultSelectText]="'Selecciona zona'"
      [control]="form.controls.zona_id"
      [name]="'select-zona'"
      [id]="'select-zona'"
    />
    </div>
    <div class="row">
      <app-input
          class="col mt-3"
          [control]="form.controls.calle"
          [label]="'Calle *'"
          [type]="'text'"
          [id]="'matricula_marca'"
        />
    </div>
  </div>
  <div
    *ngIf="form.controls.error.value !== ''"
    class="row mb-3"
  >
    <p class="text-danger">{{ form.controls.error.value }}</p>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-aceptar"
    (click)="this.cambia_domicilio(); active_modal.dismiss()"
  >
    Aceptar
  </button>
  <button
    type="button"
    class="btn btn-outline-error"
    (click)="
      active_modal.close()
    "
  >
    Cancelar
  </button>
</div>
