<div class="modal-header">
  <h4 class="modal-title">CLÁUSULA ADICIONAL DATOS TERCEROS</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  <div [innerHTML]="consentimiento_content"></div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-aceptar"
    (click)="
      envia_consentimiento.emit(true); active_modal.close('')
    "
  >
    Aceptar
  </button>
  <button
    type="button"
    class="btn btn-outline-error"
    (click)="
      envia_consentimiento.emit(false); active_modal.close()
    "
  >
    No aceptar
  </button>
</div>
