<div class="modal-header">
  <h4 class="modal-title">Solicitar Cambio de Matrícula</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  <div class="mt-3">
    <div class="row">
      <app-input
        class="col mb-3"
        [control]="form.controls.matricula"
        [label]="'Matrícula del vehículo *'"
        [type]="'text'"
        [id]="'matricula_matricula'"
      />
    </div>
    <div class="row">
      <app-input
        class="col mb-3"
        [control]="form.controls.marca"
        [label]="'Marca del vehículo *'"
        [type]="'text'"
        [id]="'matricula_marca'"
      />
    </div>
    <div class="row">
      <app-input
        class="col mb-3"
        [control]="form.controls.modelo"
        [label]="'Modelo del vehículo *'"
        [type]="'text'"
        [id]="'matricula_modelo'"
      />
    </div>
  </div>
  <div
    *ngIf="form.controls.error.value !== ''"
    class="row mb-3"
  >
    <p class="text-danger">{{ form.controls.error.value }}</p>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-aceptar"
    (click)="this.cambia_matricula(); active_modal.dismiss()"
  >
    Aceptar
  </button>
  <button
    type="button"
    class="btn btn-outline-error"
    (click)="
      active_modal.close()
    "
  >
    Cancelar
  </button>
</div>
