<app-page-container-publico [title]="'Tipos Clientes Documentación'">
  <div class="container-fluid">
    <app-nuevo-edita-docu-cliente
      *ngIf="typeForm !== undefined && typeForm !== ''"
      [type]="typeForm"
      [idCliente]="idCliente"
    >
    </app-nuevo-edita-docu-cliente>
  </div>
</app-page-container-publico>
