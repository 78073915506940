import { Component } from '@angular/core';

@Component({
  selector: 'app-aviso-email',
  templateUrl: './aviso-email.component.html',
  styleUrls: ['./aviso-email.component.scss']
})
export class AvisoEmailComponent {

}
