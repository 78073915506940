<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Buscar usuario
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="active_modal.dismiss('')"
    ></button>
  </div>
  <div class="modal-body">
    <input
      type="text"
      class="form-control"
      placeholder="Buqueda por DNI: Ej: 232351Q"
      [(ngModel)]="texto_filtro"
      (input)="obten_usuarios_paginacion()"
    />



  <!-- Barra de Navegacion -->
  <paginator
    class=""
    [pagina_actual]="pagina_actual"
    [total_paginas]="total_paginas"
    (cambia_pagina)="recibePagina($event)"
  />
    <h5 class="text-center" *ngIf="usuarios.length === 0">No se han encontrado usuarios</h5>
    <table *ngIf="usuarios.length > 0" class="table table-striped table-responsive table-sm table-hover mt-5">
      <thead class="thead-default">
        <th scope="col" class="text-center">Nombre</th>
        <th scope="col" class="text-center">NIF</th>
      </thead>
      <tbody>
        <tr *ngFor="let row of usuarios; index as x" (click)="selectUsuario(row); active_modal.close('')">
          <td class="text-center">
            {{ row.nombre }}
          </td>
          <td class="text-center">
            {{ row.dni }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="active_modal.close('')"
    >
      Cancelar
    </button>
  </div>
