import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createCalle, createTipoDocuCliente } from 'src/app/services/helpers/model.helper';
import { CallesService } from 'src/app/services/https/calles.services';

@Component({
  selector: 'app-calles-zona',
  templateUrl: './calles-zona.component.html',
  styleUrls: ['./calles-zona.component.scss'],
  providers: [CallesService]
})
export class CallesZonaComponent {
  allData: CalleModel[] = [];
  nuevoIcon: IconDefinition = faPlus;
  volverIcon: IconDefinition = faArrowLeft;
  typeForm: string = 'lista';
  idCliente: number = 0;

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: CallesService,
    private _loadService: LoadService,
    ){
      this._route.params.subscribe((params: Params) => {
        // Accede al valor del parámetro
        if (params['slug']) {
          this.idCliente = params['slug'];
          this.obtainData();
        }
      });
  }

  ngOnInit(){
  }

  obtainData() {
    this._loadService.notifyLoadChange(true);
    this._service.listaZona(this.idCliente).subscribe({
      next: (res) => {
        res.data.forEach((element: any) => {
          this.allData.push(
            createCalle(element)
          );
        });
      },
      error: (err) => {
        this._loadService.notifyLoadChange(false);
      },
      complete: () => {
        this._loadService.notifyLoadChange(false);
      }
    })
  }

  setTypeForm(tipo: string) {
    this.typeForm = tipo;
  }
}
