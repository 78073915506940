import { IDocumento } from "../interface/documento.interface";

export class DocumentoModel implements IDocumento {
  id: number;
  nombre: string;
  solicitud_id: number;
  archivo: string;
  fechaadjuntado: Date;
  validado: number;
  valida_id: number;
  fechavalida: Date;
  observaciones: string;
  observacionesUsuario: string;
  tipodocumentacion_id: number;
  caduca: boolean;
  obligatorio: boolean;
  caducidad: Date;
  constructor(
    id: number,
    nombre: string,
    solicitud_id: number,
    archivo: string,
    fechaadjuntado: Date,
    validado: number,
    valida_id: number,
    fechavalida: Date,
    observaciones: string,
    observacionesUsuario: string,
    tipodocumentacion_id: number,
    caduca: boolean,
    obligatorio: boolean,
    caducidad: Date,
  ) {
    this.id = id;
    this.nombre = nombre;
    this.solicitud_id = solicitud_id;
    this.archivo = archivo;
    this.fechaadjuntado = fechaadjuntado;
    this.validado = validado;
    this.valida_id = valida_id;
    this.fechavalida = fechavalida;
    this.observaciones = observaciones;
    this.observacionesUsuario = observacionesUsuario;
    this.tipodocumentacion_id = tipodocumentacion_id;
    this.caduca = caduca;
    this.obligatorio = obligatorio;
    this.caducidad = caducidad;
  }
}
