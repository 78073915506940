import { Component } from '@angular/core';
import { deleteToken } from 'src/app/services/helpers/session.helper';
import { globalState, } from 'src/global';

@Component({
  selector: 'app-general-options',
  templateUrl: './general-options.component.html',
  styleUrls: ['./general-options.component.scss']
})
export class GeneralOptionsComponent {

  public idUsuario : number;

  constructor() {

    this.idUsuario = 0; 
    if (globalState.identity) {
      this.idUsuario = globalState.identity.id;
    }
    
  }

  cerrarSesion() {
    deleteToken();
  }

}
