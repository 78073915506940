import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DocumentoModel } from 'src/app/models/entity/documento.model';
import { TipoDocuClienteModel } from 'src/app/models/entity/tipoDocuCliente.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { abrir_modal_borrado_helper } from 'src/app/services/helpers/modal.helper';
import { createTipoDocuCliente } from 'src/app/services/helpers/model.helper';
import { TipoDocuClienteService } from 'src/app/services/https/tipoDocuCliente.services';

@Component({
  selector: 'app-tipo-cliente-documentacion',
  templateUrl: './tipo-cliente-documentacion.component.html',
  styleUrls: ['./tipo-cliente-documentacion.component.scss'],
  providers: [TipoDocuClienteService]
})
export class TipoClienteDocumentacionComponent {
  documentos: TipoDocuClienteModel[] = [];
  nuevoIcon: IconDefinition = faPlus;
  volverIcon: IconDefinition = faArrowLeft;
  typeForm: string = 'lista';
  id_cliente: number = 0;

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: TipoDocuClienteService,
    private _toast: ToastrService,
    private _loadService: LoadService,
    private _modal_service: NgbModal
    ){
      this._route.params.subscribe((params: Params) => {
        // Accede al valor del parámetro
        if (params['slug']) {
          this.id_cliente = params['slug'];
          // this.obtainData();
        }
      });
  }

  async ngOnInit(){
    if (this.id_cliente > 0) {
      try {
        this.documentos = await this.obtener_documentos();
      } catch (e: any) {
        this._toast.error(e);
      } finally {
        this._loadService.notifyLoadChange(false);
      }
    }
  }

  async obtener_documentos(): Promise<TipoDocuClienteModel[]> {
    const filtro = `WHERE tipocliente_id = ${this.id_cliente}`
    const { documentos } = await this._service.lista_paginacion(1, 100, filtro);
    return documentos;
  }

  async abrir_modal_borrado(id: number) {
    const borrado = await abrir_modal_borrado_helper(this._modal_service, id, "tipo de documentación", "tipo_cliente_documentacion");
    if (borrado)
      this.documentos = await this.obtener_documentos();
  }

  setTypeForm(tipo: string) {
    this.typeForm = tipo;
  }
}
