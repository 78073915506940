import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-container-publico',
  templateUrl: './page-container-publico.component.html',
  styleUrls: ['./page-container-publico.component.scss']
})
export class PageContainerPublicoComponent {
  @Input() title: string = '';
}
