import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoDocuModel } from 'src/app/models/entity/tipoDocu.model';
import { createTipoDocu } from '../helpers/model.helper';

@Injectable()
export class TipoDocuService {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id + '')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'tiposdocumentacion/ficha', params, httpOptions);
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'tiposdocumentacion/lista', params, httpOptions);
  }

  // Realiza el login del usuario
  crea(data: TipoDocuModel): Observable<any> {
    const params = new HttpParams()
      .set('nombre', data.nombre)
      .set('activo', data.activo ? '1' : '0')
      .set('caducidad', data.caducidad ? '1' : '0')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposdocumentacion/crea', params, httpOptions);
  }

  // Realiza el login del usuario
  creaActualiza(data: TipoDocuModel, funcion: string): Observable<any> {
    let params = new HttpParams()
      .set('nombre', data.nombre)
      .set('activo', data.activo ? '1' : '0')
      .set('caducidad', data.caducidad ? '1' : '0')
    if (funcion === 'actualiza') {
      params = params.set('id', data.id)
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposdocumentacion/' + funcion, params, httpOptions);
  }

  // Realiza el login del usuario
  actualiza(data: TipoDocuModel): Observable<any> {
    const params = new HttpParams()
      .set('id', data.id)
      .set('nombre', data.nombre)
      .set('activo', data.activo ? '1' : '0')
      .set('caducidad', data.caducidad ? '1' : '0')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'tiposdocumentacion/actualiza', params, httpOptions);
  }

  // Realiza el login del usuario
  async lista_completa(): Promise<TipoDocuModel[]> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}tipo_documentacion`, httpOptions));
      const docus: TipoDocuModel[] = [];
      data.forEach((item: any) => {
        docus.push(createTipoDocu(item))
      });
      return docus;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async ficha_nuevo(id: number): Promise<TipoDocuModel> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}tipo_documentacion/${id}`, httpOptions));
      return createTipoDocu(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async crea_nuevo(model: TipoDocuModel): Promise<TipoDocuModel> {
    const json_payload = {
      nombre: model.nombre,
      activo: model.activo ? 1 : 0,
      caducidad: model.caducidad ? 1 : 0
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.post<any>(this.url + 'tipo_documentacion', json_payload, httpOptions));
      return createTipoDocu(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_nuevo(id: number, model: TipoDocuModel): Promise<TipoDocuModel> {
    const json_payload = {
      nombre: model.nombre,
      activo: model.activo ? 1 : 0,
      caducidad: model.caducidad ? 1 : 0
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.put<any>(this.url + 'tipo_documentacion/' + id, json_payload, httpOptions));
      return createTipoDocu(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }
}
