import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoDocuModel } from 'src/app/models/entity/tipoDocu.model';
import { TipoDocuClienteModel } from 'src/app/models/entity/tipoDocuCliente.model';
import { DocumentoModel } from 'src/app/models/entity/documento.model';
import { createDocumento } from '../helpers/model.helper';

@Injectable()
export class DocumentosService {
  public url: string = globalState.url ;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }


  ficha(id: number): Observable<any> {
   const params = new HttpParams()
      .set('id', id+'')

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(this.url + 'documentos/ficha', params, httpOptions);
  }


  obtenerDocumento(id: number): Observable<any> {
   const params = new HttpParams()
      .set('id', id+'')

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(this.url + 'documentos/obtenerDocumento', params, httpOptions);
  }


  lista(): Observable<any> {
    const params = new HttpParams();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'documentos/lista', params, httpOptions);
  }

  listaBySolicitud(id: number): Observable<any> {
    const params = new HttpParams().set('id', id)
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'documentos/listaBySolicitud', params, httpOptions);
  }

  // Realiza el login del usuario
  crea(data: TipoDocuClienteModel, idSolicitud?: number): Observable<any> {
    const params = new HttpParams()
    .set('nombre', data.nombre)
    .set('solicitud_id', idSolicitud ? idSolicitud : 0)
    .set('tipodocumentacion_id',data.tipoDocumentacionId )

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'documentos/crea', params, httpOptions);
  }

    // Realiza el login del usuario
  creaActualiza(data: TipoDocuClienteModel, funcion: string): Observable<any> {
    let params = new HttpParams()
    .set('nombre', data.nombre)
    .set('activo', data.activo ? '1' : '0')
    .set('caducidad', data.caducidad ? '1' : '0')
    .set('tipodocumentacion_id', data.tipoDocumentacionId)
    .set('tipocliente_id', data.tipoClienteId)
    .set('observaciones', '');

    if(funcion === 'actualiza') {
      params = params.set('id', data.id);
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'documentos/' + funcion, params, httpOptions);
  }

  // Realiza el login del usuario
  actualiza(data: TipoDocuModel): Observable<any> {
    const params = new HttpParams()
      .set('id', data.id)
      .set('nombre', data.nombre)
      .set('activo', data.activo ? '1' : '0')
      .set('caducidad', data.caducidad ? '1' : '0')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'documentos/actualiza', params, httpOptions);
  }

  async lista_por_solicitud (id_solicitud: number) : Promise<DocumentoModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}documentos?pagina=${1}&registros=${100}&filtro=where solicitud_id=${id_solicitud}`, httpOptions));
      const documentos: DocumentoModel[] = [];
      data.Documentos.forEach((item: any) => {
        documentos.push(createDocumento(item))
      });
      return documentos
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async subir_imagen(id_documento: number, form_data: FormData) : Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    form_data.append('id', id_documento.toString());
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}documentos/subir_archivo`, form_data, httpOptions));
      return data.message;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_observaciones(id: number, observaciones: string): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    let json_payload = {};
    if (globalState.identity?.role!! > 0) {
      json_payload = {
        observaciones: observaciones
      }
    } else {
      json_payload = {
        observacionesusuario: observaciones
      }
    }
    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}documentos/${id}`, json_payload, httpOptions));
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async ficha_nuevo(id: number): Promise<{documento: DocumentoModel, archivo: string}> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}documentos/${id}`, httpOptions));
      return { documento: createDocumento(data.documento), archivo: data.archivo };
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_validado(id: number, validado: number): Promise<DocumentoModel> {
    const hoy = new Date();
    const año = hoy.getFullYear();
    const mes = (hoy.getMonth() + 1).toString().padStart(2, '0');
    const día = hoy.getDate().toString().padStart(2, '0');
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    const json_payload = {
      valida_id: validado,
      fechavalida: `${año}-${mes}-${día}`
    }
    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}documentos/${id}`, json_payload, httpOptions));
      return createDocumento(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }
}
