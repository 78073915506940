<div class="form-check form-switch form-check-reverse">
  <input
    class="form-check-input"
    type="checkbox"
    [id]="id"
    [class.invalid]="control.invalid && control.dirty && control.touched"
    [formControl]="control"
  >
  <label class="form-check-label" [htmlFor]="id">{{label}}</label>
</div>
