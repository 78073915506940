import { Component } from '@angular/core';
import { IconDefinition, faFacebook, faWhatsapp, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss']
})
export class HeaderTopComponent {
  faFacebook: IconDefinition = faFacebook;
  faWhatsapp: IconDefinition = faWhatsapp;
  faTwitter: IconDefinition = faTwitter;
}
