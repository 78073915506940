<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4">
    <h6 *ngIf="isNuevo" class="mb-0">Registro de zona</h6>
    <h6 *ngIf="!isNuevo" class="mb-0">Actualización de zona</h6>
    <div class="form-check form-switch form-check">
      <input
        #activo
        name="activo"
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="zona.activo"
        id="activo"
      />
      <label *ngIf="zona.activo" class="form-check-label" for="activo"
        >Activo</label
      >
      <label *ngIf="!zona.activo" class="form-check-label" for="activo"
        >Inactivo</label
      >
    </div>
  </div>
  <div class="form-body-three-cols mt-3">
    <div class="two-cols">
      <app-input
        [control]="formGroup.controls.nombre"
        [label]="'Nombre *'"
        [type]="'text'"
        [id]="'nombre'"
      >
      </app-input>
    </div>
    <app-input
      [control]="formGroup.controls.abreviatura"
      [label]="'Abreviatura *'"
      [type]="'text'"
      [id]="'abreviatura'"
    >
    </app-input>
  </div>

  <div class="row mt-3 ">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
      <app-input
        [control]="formGroup.controls.completo_base"
        [label]="'Base Imp. Año *'"
        [type]="'number'"
        [id]="'precio_completo_base'"
        [step]="'0.01'"
      >
      </app-input>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
      <app-input
        [control]="formGroup.controls.completo_iva"
        [label]="'IVA Año *'"
        [type]="'number'"
        [id]="'precio_completo_iva'"
      >
      </app-input>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
      <app-input
        [control]="formGroup.controls.medio_base"
        [label]="'Base Imp. Medio Año *'"
        [type]="'number'"
        [id]="'medio_base'"
      >
      </app-input>
    </div>
          
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
      <app-input
        [control]="formGroup.controls.medio_iva"
        [label]="'IVA Medio Año *'"
        [type]="'number'"
        [id]="'medio_iva'"
      >
      </app-input>
    </div>
  </div>

  <div class="form-body-three-cols mt-3">

    <div class="">
      <label for="formFile">Mapa de Zona *</label>
      <input
        class="form-control"
        type="file" id="formFile"
        [formControl]="formGroup.controls.mapa"
        [class.invalid]="formGroup.controls.mapa.invalid && formGroup.controls.mapa.dirty && formGroup.controls.mapa.touched"
        (change)="fileChangeEvent($event)"
      >
      
      <p *ngIf="this.zona.mapa !== ''" class="text-success">
        <fa-icon [icon]="verIcon" class="text-muted" (click)="muestraMapa(modalMapa, urlZona + zona.mapa, zona.nombre )"></fa-icon> 
        Archivo: {{this.zona.mapa}}
        <fa-icon [icon]="eliminaIcon" class="text-muted" (click)="confirmaEliminaMapa(formConfirmaBorrado)"></fa-icon> 
       <!-- The Modal -->
       <ng-template #formConfirmaBorrado let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">
            Confirmar Borrado
          </h4>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('')"
          ></button>
        </div>
        <div class="modal-body">
          ¿Seguro que desea eliminar el mapa? <br /><br />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="modal.close('')"
          >
            Eliminar
          </button>
        </div>
      </ng-template>


      </p>
      <p *ngIf="this.zona.mapa === ''" class="text-danger">Sin mapa</p>
    </div>
    <div class="">
      <app-color-picker
        [control]="formGroup.controls.color"
        [label]="'Color *'"
        [name]="'color'"
        [maxWidth]="'38px'"
        [maxHeight]="'38px'"
        [id]="'color'"
      >
      </app-color-picker>
    </div>
    <div class="full-width">
      <!-- <app-tiny-editor
        [control]="formGroup.controls.observaciones"
        [label]="'Observaciones'"
        [id]="'observaciones'"
      ></app-tiny-editor> -->
      <app-text-editor
        [control]="formGroup.controls.observaciones"
        [label]="'Observaciones'"
        [id]="'observaciones'" />
    </div>
  </div>
  <div class="d-flex gap-3">
    <button type="submit" class="btn btn-aceptar mt-5">
      <span *ngIf="isNuevo"> Crear </span>
      <span *ngIf="!isNuevo"> Actualiza </span>
    </button>
    <a [routerLink]="'/zonas'" class="btn btn-outline-error mt-5"> Cancelar </a>
  </div>
</form>

<app-lineal-component-selector *ngIf="!isNuevo"
  [componentsLabel]="['Calles asignadas']"
  [dynamicComponent]="[tipoCalle]"
></app-lineal-component-selector>



<ng-template #modalMapa let-modal >
  <div class="modal-header">
      <h4>{{nombreMapa}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <img src="{{urlMapa}}" class="w-100 img-fluid rounded" >
  </div>
</ng-template>

