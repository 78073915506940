import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoClienteService } from 'src/app/services/https/tipoCliente.services';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { globalState } from 'src/global';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faMagnifyingGlass, faCaretDown, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { Router } from '@angular/router';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { CallesService } from 'src/app/services/https/calles.services';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { IEditaSolicitudView } from 'src/app/models/interface/ver_edita_solicitud.interface';
import { ModalSearchUsuarioComponent } from '../../../../../../components/modals/modal-search-usuario/modal-search-usuario.component';
import { ModalConsentimientoComponent } from 'src/app/components/modals/modal-consentimiento/modal-consentimiento.component';
import { ModalCondicionesComponent } from 'src/app/components/modals/modal-condiciones/modal-condiciones.component';
import { ModalMapaZonasComponent } from 'src/app/components/modals/modal-mapa-zonas/modal-mapa-zonas.component';
import { ModalCambioMatriculaComponent } from '../../../../../../components/modals/modal-cambio-matricula/modal-cambio-matricula.component';
import { ModalNuevoUsuarioComponent } from 'src/app/components/modals/modal-nuevo-usuario/modal-nuevo-usuario.component';
import { ModalCambioDomicilioComponent } from 'src/app/components/modals/modal-cambio-domicilio/modal-cambio-domicilio.component';
import { SolicitudDocumentosListComponent } from '../solicitud-documentos-list/solicitud-documentos-list.component';

@Component({
  selector: 'app-edita-solicitud-admin',
  templateUrl: './edita-solicitud-admin.component.html',
  styleUrls: ['./edita-solicitud-admin.component.scss'],
  providers: [
    TipoClienteService,
    UsuariosService,
    ConfiguracionService,
    SolicitudesServices,
    ZonasService,
    CallesService,
  ],
})
export class EditaSolicitudAdminComponent implements OnInit {
  @Input() id!: number;
  @Input() is_nuevo: boolean = true;
  @Output() envia_datos_tarjeta: EventEmitter<any> = new EventEmitter<any>
  @Output() envia_datos_factura: EventEmitter<any> = new EventEmitter<any>
  @Output() envia_datos_tar_fac: EventEmitter<any> = new EventEmitter<any>
  // Datos para dibujar en pantalla
  edita_solicitud_view: EditaSolicitudView | null = null;
  zonas: ZonasModel[] = [];
  tipos_clientes: TipoClienteModel[] = [];
  tipo_pago_data = [
    { id: 1, nombre: 'Efectivo' },
    { id: 2, nombre: 'Tarjeta' },
    { id: 3, nombre: 'Online' }
  ]
  calles: CalleModel[] = [];
  menu_boton_usuario: boolean = false;
  documentos_lista: Function = SolicitudDocumentosListComponent

  // Datos para el formulario
  formGroup = new FormGroup({
    // Variables para el formulario
    marca: new FormControl<string>("", [Validators.required]),
    usuarioId: new FormControl<number>(0, [Validators.required]),
    destinatario: new FormControl<string>('', []),
    estado: new FormControl<number>(-1, [Validators.required]),
    tramitaId: new FormControl<number>(0, []),
    zonaId: new FormControl<number>(0, []),
    tarjetaId: new FormControl<number>(0, []),
    importe: new FormControl<number>(0, []),
    modelo: new FormControl<string>("", [Validators.required]),
    matricula: new FormControl<string>("", [Validators.required]),
    tipoClienteId: new FormControl<number>(1, [Validators.required]),
    usuarioNombre: new FormControl<string>('', [Validators.required]),
    pagada: new FormControl<boolean>(false, []),
    consentimiento: new FormControl<boolean>(false, [Validators.required]),
    annio: new FormControl<number>(0, []),
    iva: new FormControl<number>(0, []),
    total: new FormControl<number>(0, []),
    facturaSerie: new FormControl<string>('', []),
    facturaNumero: new FormControl<number>(0, []),
    medio: new FormControl<boolean>(false, [Validators.required]),
    tipoPagoId: new FormControl<number>(0, [Validators.required]),
    // Variable para saber si se usa o o no la variable de la calle manual
    calleEncontrada: new FormControl<boolean>(false, [Validators.required]),
    calleId: new FormControl<number>(0, [Validators.required]),
    domicilio: new FormControl<string>('', []),
    matricula_matricula: new FormControl<string>('', []),
    matricula_modelo: new FormControl<string>('', []),
    matricula_marca: new FormControl<string>('', []),
    matricula_error: new FormControl<string>('', []),
    domicilio_zonaId: new FormControl<number>(0, []),
    domicilio_calle: new FormControl<string>('', []),
    domicilio_error: new FormControl<string>('', [])
  });

  // Iconos
  flecha_abajo: IconDefinition = faCaretDown;
  icono_anyadir: IconDefinition = faPlus;
  icono_buscar: IconDefinition = faMagnifyingGlass;
  icono_editar: IconDefinition = faEdit;
  icono_mapa: IconDefinition = faMap;

  constructor(
    private _sol_service: SolicitudesServices,
    private _load_service: LoadService,
    private _modalService: NgbModal,
    private _zonas_services: ZonasService,
    private _tc_services: TipoClienteService,
    private _calle_services: CallesService,
    private _toasr: ToastrService,
    private _nav: Router
  ) {
  }

  /**
    * - Determina si se está creando una nueva solicitud o editando una existente.
    * - Carga la solicitud y otros datos necesarios.
    * - Suscribe a cambios en el campo `zonaId` del formulario para realizar operaciones adicionales.
  */
  async ngOnInit(): Promise<void> {
    this.is_nuevo = this.id === 0;
    try {
      this._load_service.notifyLoadChange(true);
      if (this.is_nuevo === true) {
        // Iniciamos la solicitud vacía
        this.edita_solicitud_view = new EditaSolicitudView({
          id: 0, annio: globalState.annioActual, numero: 0, usuario_id: 0, usuario_nombre: "", usuario_dni: "",
          usuario_telefono: "", usuario_localidad: "", destinatario: "", fechasolicitud: null, estado: -1,
          tipocliente_id: 0, matricula: "", marca: "", modelo: "", zona_id: 0, medio: false, consentimiento: false,
          tramita_id: globalState.identity?.id ?? 0, tramita_nombre: globalState.identity?.nombre ?? "",
          fechaaceptacion: null, fechadenegacion: null, factura_serie: "", factura_numero: 0, tarjeta_id: 0,
          tarjeta_numero: 0, importe: 0, iva: 0, total: 0, pagado: false, calle_id: 0, domicilio: "",
          documentosPendientes: 0, documentosCorrectos: 0, renovacion: false, tarjetarenovada: 0, tiposolicitud: 0,
          solicitud_destino: 0, solicitud_origen: 0, fecha_pagado: null, tipo_pago: ""
        });
      } else {
        // Obtenemos los datos de la solicitud
        this.edita_solicitud_view = await this.obten_solicitud(this.id)
      }
      // Obtenemos zonas y tipos de clientes
      this.zonas = await this.obten_zonas()
      this.tipos_clientes = await this.obten_tipos_cliente();
      // Actualizamos los datos con todo lo
      this.actualizaFormGroup(this.edita_solicitud_view)
    } catch (e: any) {
      console.log(e);
    } finally {
      this._load_service.notifyLoadChange(false);
      // Evento de suscripción a los cambios de zonas para ejecutar una operacion que necesitemos cuando cambie el valor de zonaId
      this.formGroup.controls.zonaId.valueChanges.subscribe((nuevo_valor: number | null) => {
        if (nuevo_valor !== null) {
          // Obtencion de las calles
          this.obten_calles_por_zona(nuevo_valor)
        }
      })
    }
  }

  /**
   * Obtiene los datos de la solicitud a editar.
   * @param id - El ID de la solicitud a obtener.
   * @returns Una promesa que resuelve con los datos de la solicitud.
   */
  async obten_solicitud(id: number): Promise<IEditaSolicitudView> {
    try {
      return await this._sol_service.ficha_nueva(id)
    } catch (e: any) {
      this._toasr.error(e);
      return Promise.reject(e);
    }
  }

  /**
   * Obtiene la lista completa de tipos de cliente.
   * @returns Una promesa que resuelve con un array de tipos de cliente.
   */
  async obten_tipos_cliente() {
    try {
      const { tipos } = await this._tc_services.lista_completa()
      return tipos;
    } catch (e: any) {
      this._toasr.error(e);
      return Promise.reject(e);
    }
  }

  /**
   * Obtiene la lista completa de zonas.
   * @returns Una promesa que resuelve con un array de zonas.
   */
  async obten_zonas(): Promise<ZonasModel[]> {
    try {
      const { zonas } = await this._zonas_services.lista_completa()
      return zonas;
    } catch (e: any) {
      this._toasr.error(e);
      return Promise.reject(e);
    }
  }

  /**
   * Envía una peticion al componente padre para refrescar los datos de la solicitud
   * @param refresca
   */
  async refresca_datos(refresca: boolean) {
    const { id } = this.edita_solicitud_view!!;
    this.edita_solicitud_view = await this.obten_solicitud(id)
  }

  /**
   * Obtiene las calles correspondientes a una zona específica.
   * @param zona_id - El ID de la zona para obtener sus calles.
   */
  async obten_calles_por_zona(zona_id: number): Promise<void> {
    try {
      this.calles = await this._calle_services.calles_by_zona(zona_id);
    } catch (e: any) {
      this._toasr.error(e);
      this.calles = [];
    }
  }

  /**
   * Abre un modal para seleccionar un usuario y actualiza el formulario con los datos del usuario seleccionado.
   */
  abrir_modal_selecciona_usuario() {
    const modalRef = this._modalService.open(ModalSearchUsuarioComponent, { centered: true })
    modalRef.componentInstance.envia_usuario.subscribe((usuario_seleccionado: UsuarioModel) => {
      this.formGroup.patchValue({
        usuarioId: usuario_seleccionado.id,
        usuarioNombre: usuario_seleccionado.nombre
      })
      this.menu_boton_usuario = false;
    });
  }

  /**
   *
   */
  abrir_modal_nuevo_usuario() {
    const modalRef = this._modalService.open(ModalNuevoUsuarioComponent, { centered: true, });
    modalRef.componentInstance.envia_usuario.subscribe((nuevo_usuario: UsuarioModel) => {
      this.formGroup.patchValue({
        usuarioId: nuevo_usuario.id,
        usuarioNombre: nuevo_usuario.nombre
      })
      this.menu_boton_usuario = false;
    })
  }

  abrir_modal_mapa() {
    const modalRef = this._modalService.open(ModalMapaZonasComponent, { centered: true, });
    modalRef.componentInstance.selecciona_zona.subscribe((zona_id: number) => {
      this.formGroup.patchValue({
        zonaId: zona_id
      });
    })
  }

  abrir_modal_consentimiento() {
    const modalRef = this._modalService.open(ModalConsentimientoComponent, { centered: true, });
    modalRef.componentInstance.envia_consentimiento.subscribe((acepta_terminos: boolean) => {
      this.formGroup.patchValue({
        consentimiento: acepta_terminos
      })
    })
  }

  abrir_modal_condiciones() {
    this._modalService.open(ModalCondicionesComponent, { centered: true, });
  }

  abrir_modal_cambio_matricula() {
    const modal_ref = this._modalService.open(ModalCambioMatriculaComponent, { centered: true });
    modal_ref.componentInstance.id = this.edita_solicitud_view!!.id;

  }

  abrir_modal_cambio_domicilio() {
    const modal_ref = this._modalService.open(ModalCambioDomicilioComponent, { centered: true });
    modal_ref.componentInstance.id = this.edita_solicitud_view!!.id;
  }

  actualizaFormGroup(updateInf: EditaSolicitudView) {
    let tipo_pago_id = 0;
    const tipo_pago = this.tipo_pago_data.find((x) => x.nombre === updateInf.tipo_pago);

    if (tipo_pago !== undefined) {
      tipo_pago_id = tipo_pago.id;
    }

    this.formGroup.patchValue({
      marca: updateInf.marca ?? '',
      usuarioId: updateInf.usuario_id,
      usuarioNombre: updateInf.usuario_nombre,
      destinatario: updateInf.destinatario,
      estado: updateInf.estado,
      tramitaId: globalState.identity?.id ?? 0,
      tarjetaId: updateInf.tarjeta_id,
      importe: updateInf.importe,
      modelo: updateInf.modelo,
      matricula: updateInf.matricula,
      tipoClienteId: updateInf.tipocliente_id,
      consentimiento: updateInf.consentimiento,
      pagada: updateInf.pagado,
      annio: updateInf.annio,
      iva: updateInf.iva,
      facturaSerie: updateInf.factura_serie,
      facturaNumero: updateInf.factura_numero,
      medio: updateInf.medio,
      zonaId: updateInf.zona_id,
      calleId: updateInf.calle_id,
      domicilio: updateInf.domicilio,
      calleEncontrada: updateInf.domicilio === '' ? false : true,
      tipoPagoId: tipo_pago_id
    });
    this.formGroup.value.marca = updateInf.marca
  }

  /**
   * Cambio de valor del botón que permite crear o seleccionar usuario
   */
  pulsa_boton_menu() {
    this.menu_boton_usuario = !this.menu_boton_usuario;
  }

  async onSubmit() {
    this._load_service.notifyLoadChange(true);
    const nueva_solicitud = new SolicitudModel(
      this.edita_solicitud_view!!.id ?? 0,
      this.formGroup.value.usuarioId ?? 0,
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.destinatario : this.formGroup.value.destinatario ?? '',
      new Date(),
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.estado : this.formGroup.value.estado ?? 0,
      new Date(),
      new Date(),
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.tramita_id : this.formGroup.value.tramitaId ?? 0,
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.matricula : this.formGroup.value.matricula ?? '',
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.tarjeta_id : this.formGroup.value.tarjetaId ?? 0,
      this.formGroup.value.importe ?? 0,
      this.formGroup.value.pagada ?? false,
      this.formGroup.value.annio ?? 0,
      this.formGroup.value.iva ?? 0,
      this.formGroup.value.total ?? 0,
      this.formGroup.value.facturaSerie ?? '',
      this.formGroup.value.facturaNumero ?? 0,
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.marca : this.formGroup.value.marca ?? '',
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.modelo : this.formGroup.value.modelo ?? '',
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.tipocliente_id : this.formGroup.value.tipoClienteId ?? 0,
      this.formGroup.value.medio ?? false,
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.consentimiento : this.formGroup.value.consentimiento ?? false,
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.zona_id : this.formGroup.value.zonaId ?? 0,
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.calle_id : this.formGroup.value.calleId ?? 0,
      this.edita_solicitud_view!!.estado >= 2 ? this.edita_solicitud_view!!.domicilio : this.formGroup.value.domicilio ?? '',
      this.edita_solicitud_view!!.documentosPendientes,
      this.edita_solicitud_view!!.renovacion,
      this.edita_solicitud_view!!.documentosCorrectos,
      this.edita_solicitud_view!!.tarjetarenovada,
      this.edita_solicitud_view?.tiposolicitud ?? 0,
      this.edita_solicitud_view?.solicitud_destino ?? 0,
      this.edita_solicitud_view?.solicitud_origen ?? 0,
      `${this.formGroup.value.tipoPagoId ?? 1}`
    );

    if (this.is_nuevo) {
      await this.crea_solicitud(nueva_solicitud);
    } else {
      await this.actualiza_solicitud(nueva_solicitud);
    }
  }

  async crea_solicitud(solicitud: SolicitudModel) {
    try {
      this._load_service.notifyLoadChange(true)
      const { data } = await this._sol_service.crea_nuevo(solicitud)
      if (data.id) {
        this._nav.navigate(['/solicitudes/' + data.id]);
      }
    } catch (e: any) {
      this._toasr.error(e);
    } finally {
      this._load_service.notifyLoadChange(false)
    }
  }

  async actualiza_solicitud(solicitud: SolicitudModel) {
    try {
      const data = await this._sol_service.actualiza_nuevo(solicitud)
      this._toasr.success(data.message);
      window.location.reload()
    } catch (e: any) {
      this._toasr.error(e);
    } finally {
      this._load_service.notifyLoadChange(false)
    }
  }
}
