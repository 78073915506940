<app-page-container-publico [title]="'Zonas'">
  <div class="container-fluid px-5">
    <ng-container *ngIf="typeForm === ''">
      <h3
        *ngIf="allZonas.length <= 0"
        class="text-gray text-center mt-5"
      >
        No hay zonas registradas
      </h3>
      <!-- <app-table-default
        *ngIf="allZonas.length > 0"
        [data] = allZonas
        [tableColumns] = "['nombre','abreviatura', 'color', 'completo_base', 'completo_iva', 'medio_base', 'medio_iva', 'observaciones', 'activo']"
        [tableItemType] = "[ '', '', 'color', 'money', 'money', 'money', 'money', '', 'boolean']"
        [labelColumns] = "['Nombre','Abrev', 'Color', 'Base Imp. Año', 'IVA Año', 'Base Imp. Med.Año', 'IVA Med.Año', 'Observaciones', 'Activo']"
        [alignColumns]="['start','center','','','','','','','','','']"
        [widthColumns] = "['','10%', '10%', '']"
        [searcher] = "['nombre','abreviatura']"
        [showDeleteButton]="true"
        [showEditButton]="true"
        [tableName] = "'zonas'"
      ></app-table-default> -->
    </ng-container>
    <app-nuevo-edita-zona *ngIf="typeForm!== ''"></app-nuevo-edita-zona>
  </div>
</app-page-container-publico>

