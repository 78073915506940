<form class="form-container" (ngSubmit)="onSubmit()" #registroForm="ngForm">
  <div class="form-header">
    <img class="banner-login__img" src="../../../../../assets/logo_limusa.png" alt="logo-limusa">
    <h6 class="mb-0">Registro de usuario</h6>
  </div>
  <div class="form-body-three-cols">
    <div class="two-cols mb-3">
      <app-input
        [control]="formGroup.controls.nombre"
        [label]="'Nombre *'"
        [type]="'text'"
        [id]="'nombre'">
      </app-input>
    </div>
    <app-input
      [control]="formGroup.controls.dni"
      [label]="'Dni *'"
      [type]="'text'"
      [id]="'dni'">
    </app-input>
    <div class="full-width mb-3">
      <app-input
        [control]="formGroup.controls.email"
        [label]="'Email *'"
        [type]="'email'"
        [id]="'email'"
        [isBlurValidation]="true"
        />
        <p class="error-message" *ngIf="emailMessage !== ''"></p>
    </div>
    <div class="two-cols mb-3">
      <app-input
        [control]="formGroup.controls.password"
        [label]="'Contraseña *'"
        [type]="'password'"
        [id]="'password'">
      </app-input>
    </div>
    <app-input
      [control]="formGroup.controls.telefono"
      [label]="'Telefono *'"
      [type]="'tel'"
      [id]="'telefono'">
    </app-input>
    <div class="full-width mb-3">
      <app-input
        [control]="formGroup.controls.domicilio"
        [label]="'Domicilio *'"
        [type]="'text'"
        [id]="'domicilio'">
      </app-input>
    </div>
    <app-input
      [control]="formGroup.controls.codigoPostal"
      [label]="'Código Postal *'"
      [type]="'number'"
      [id]="'codigoPostal'">
    </app-input>
    <app-input
      [control]="formGroup.controls.localidad"
      [label]="'Localidad *'"
      [type]="'text'"
      [id]="'localidad'">
    </app-input>
    <app-input
      [control]="formGroup.controls.provincia"
      [label]="'Provincia *'"
      [type]="'text'"
      [id]="'provincia'">
    </app-input>

  </div>
  <div class="mt-5 d-flex justify-content-evenly flex-wrap gap-2">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="!formGroup.valid"
    >Registro</button>
    <a [routerLink]="'/'" class="btn-outline-error">Cancelar</a>
  </div>
</form>
