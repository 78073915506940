import {  NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { BorradoModalComponent } from "src/app/components/common/borrado-modal/borrado-modal.component"

export const abrir_modal_borrado_helper = (
  modal_service: NgbModal,
  id: number,
  nombre_objeto: string,
  tabla: string
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const modalRef = modal_service.open(BorradoModalComponent, { centered: true });
    modalRef.componentInstance.tipo_objeto = nombre_objeto;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.tabla = tabla;

    // Suscribirse al evento de borrado exitoso
    modalRef.componentInstance.borrado_exitoso.subscribe(
      (borrado_exitoso: boolean) => {
        resolve(borrado_exitoso);
      },
      (error: any) => {
        reject(error);
      }
    );

    // Opción para manejar el cierre del modal sin realizar ninguna acción
    modalRef.result.catch((error) => {
      // Manejo del cierre del modal sin acción
      reject(false);
    });
  });
};
