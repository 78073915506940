<pdf-viewer
  class="pdf-viewer mx-auto"
  [src]="src"
  [original-size]="false"
  [show-all]="true"

></pdf-viewer>
<!-- <embed
 [src]="file"
 type="application/pdf" width="100%" height="600px"/> -->
