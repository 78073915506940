import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent {
  // Texto sobre el input
  @Input() label!:string;
  // Opcional: tamaño del text area por defecto es 200 y permite un numero
  @Input() height: number = 200;
  // Id del input
  @Input() id!:string;
  // Control del campo dónde se define las propiedades y los validadores
  @Input() control = new FormControl();
  @Input() placeholder: string  = '';

  // Define los tipos y el mensaje de error a mostrar
  errorMessages: Record<string, string> = {
    required: "Este campo es obligatorio",
  }

  ngOnInit(): void {
  }
}
