import { ISolicitud } from './../interface/solicitud.interface';


export class SolicitudModel implements ISolicitud {
  id: number;
  usuarioId: number;
  destinatario: string;
  fechaSolicitud: Date;
  estado: number;
  fechaAceptacion: Date | string | null;
  fechaDenegacion: Date | string | null;
  tramitaId: number;
  matricula: string;
  tarjeta_id: number;
  importe: number;
  pagado: boolean;
  annio: number;
  iva: number;
  total: number;
  facturaSerie: string;
  facturaNumero: number;
  marca: string;
  modelo: string;
  tipoClienteId: number;
  medio: boolean;
  consentimiento: boolean;
  zonaId: number;
  calleId: number;
  domicilio: string;
  documentosPendientes: number;
  renovacion: boolean;
  documentosCorrectos: number;
  tarjetarenovada: number;
  tiposolicitud: number;
  solicitud_destino: number;
  solicitud_origen: number;
  tipo_pago: string;

  constructor(
    id: number,
    usuarioId: number,
    destinatario: string,
    fechaSolicitud: Date,
    estado: number,
    fechaAceptacion: Date | string | null,
    fechaDenegacion: Date | string | null,
    tramitaId: number,
    matricula: string,
    tarjeta_id: number,
    importe: number,
    pagado: boolean,
    annio: number,
    iva: number,
    total: number,
    facturaSerie: string,
    facturaNumero: number,
    marca: string,
    modelo: string,
    tipoClienteId: number,
    medio: boolean,
    consentimiento: boolean,
    zonaId: number,
    calleId: number,
    domiclio: string,
    documentosPendientes: number,
    renovacion: boolean,
    documentosCorrectos: number,
    tarjetarenovada: number,
    tiposolicitud: number,
    solicitud_destino: number,
    solicitud_origen: number,
    tipo_pago: string

  ) {
    this.id = id;
    this.usuarioId = usuarioId;
    this.destinatario = destinatario;
    this.fechaSolicitud = fechaSolicitud;
    this.estado = estado;
    this.fechaAceptacion = fechaAceptacion;
    this.fechaDenegacion = fechaDenegacion;
    this.tramitaId = tramitaId;
    this.matricula = matricula;
    this.tarjeta_id = tarjeta_id;
    this.importe = importe;
    this.pagado = pagado;
    this.annio = annio;
    this.iva = iva;
    this.total = total;
    this.facturaSerie = facturaSerie;
    this.facturaNumero = facturaNumero;
    this.marca = marca;
    this.modelo = modelo;
    this.tipoClienteId = tipoClienteId;
    this.medio = medio;
    this.consentimiento = consentimiento;
    this.zonaId = zonaId;
    this.calleId = calleId,
    this.domicilio = domiclio
    this.documentosPendientes = documentosPendientes;
    this.renovacion = renovacion;
    this.documentosCorrectos = documentosCorrectos;
    this.tarjetarenovada = tarjetarenovada;
    this.tiposolicitud = tiposolicitud;
    this.solicitud_origen = solicitud_origen
    this.solicitud_destino = solicitud_destino
    this.tipo_pago = tipo_pago
  }
}
