import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent {
  // Nombre sobre el input
  @Input() label!:string;
  // Control del campo dónde se define las propiedades y valores
  @Input() control = new FormControl();
  // Texto para input sin seleccionar
  @Input() defaultSelectText!:string;
  // Valor para input sin seleccionar
  @Input() defaultSelectValue!:any;
  // Valores para los value de las options
  @Input() optionData!:any[];
  // Valores para el texto que se visualiza en las options
  @Input() optionsLabels!:any[];
  // Id para el input
  @Input() id!:string;
  // Name para el input
  @Input() name!:string;
  @Input() disabled:boolean = false;

  errorMessages: Record<string, string> = {
    required: "Este campo es obligatorio",
    email: "Este campo no es válido",
    minlength: "Este campo no es lo suficientemente largo",
    pattern: "Formato no válido"
  }

  constructor() {
    if (this.control.value === this.defaultSelectValue /*0*/) {
      this.control.setErrors({ 'required': true });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
