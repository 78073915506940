<div class="desktop-usermenu position-relative">
  <button #toggleButton type="button" (click)="userMenuToggle()" class="btn btn-aceptar">
    <span>{{identity?.dni}}</span>
    <fa-icon style="margin-left: 1rem;"
      [icon]="fechaAbajo">
    </fa-icon>
  </button>
  <div #menu
  [ngClass]="{'not-show' : !isOpen}"
    class="header-usermenu">
    <app-general-options *ngIf="identity !== undefined && identity.role === 0"></app-general-options>
    <app-admin-options *ngIf="identity !== undefined && identity.role === 1"></app-admin-options>
    <app-admin-options *ngIf="identity !== undefined && identity.role === 2"></app-admin-options>
    <!-- <app-super-options *ngIf="identity !== undefined && identity.role === 2"></app-super-options> -->
  </div>
</div>

<div class="mobile-usermenu">

  <p class="header-link-item selected mb-0">Opciones del  usuario</p>
  <app-general-options *ngIf="identity !== undefined && identity.role === 0"></app-general-options>
  <app-admin-options *ngIf="identity !== undefined && identity.role === 1"></app-admin-options>
  <app-general-options *ngIf="identity !== undefined && identity.role === 2"></app-general-options>
</div>
