import { ITipoDocu } from "../interface/tipoDocu.interface ";

export class TipoDocuModel implements ITipoDocu {
  id: number;
  nombre: string;
  activo: boolean;
  caducidad: boolean;

  constructor(
    id: number,
    nombre: string,
    activo: boolean,
    caducidad: boolean,
  ) {
    this.id = id;
    this.nombre = nombre;
    this.activo = activo;
    this.caducidad = caducidad;
  }
}
