import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LoadService } from 'src/app/services/helpers/load.service';
import { TipoClienteService } from 'src/app/services/https/tipoCliente.services';

@Component({
  selector: 'app-tipo-cliente-documentacion-page',
  templateUrl: './tipo-cliente-documentacion-page.component.html',
  styleUrls: ['./tipo-cliente-documentacion-page.component.scss']
})
export class TipoClienteDocumentacionPageComponent {
  typeForm: string = '';
  idCliente: number = 0;
  constructor(
    private _route: ActivatedRoute,
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
      }
      if (params['id'] !== undefined) {
        this.idCliente = params['id'];
      }
    });
  }
}
