import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { ZonasService } from 'src/app/services/https/zonas.services';

@Component({
  selector: 'app-modal-mapa-zonas',
  templateUrl: './modal-mapa-zonas.component.html',
  styleUrls: ['./modal-mapa-zonas.component.scss'],
  providers: [ZonasService]
})
export class ModalMapaZonasComponent {

  slider_mapas: ModelSlider[] = [];
  @Output() selecciona_zona: EventEmitter<number> = new EventEmitter<number>

  constructor(
    public active_modal: NgbActiveModal,
    private _zonas_service: ZonasService,
    private _load_service: LoadService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this._load_service.notifyLoadChange(true);
      const mapas = await this._zonas_service.lista_mapas();
      mapas.forEach(element => {
        this.slider_mapas.push(new ModelSlider(element.id, element.mapa, `Zona {element.id}`, true, "Selecciona zona", ""))
      });
    } catch (e: any) {
      console.log(e)
    } finally {
      this._load_service.notifyLoadChange(false);
    }
  }
}
