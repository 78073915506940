<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4 justify-content-end">
    <div class="form-check form-switch form-check">
      <input
        #activo
        name="activo"
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="data.activo"
        id="activo"
        (ngModelChange)="cambia_activo($event)"
      >
      <label *ngIf="data.activo" class="form-check-label" for="activo">Activo</label>
      <label *ngIf="!data.activo" class="form-check-label" for="activo">Inactivo</label>
    </div>
  </div>
  <div class="form-body-three-cols mt-3">
    <app-input
      [control]="formGroup.controls.annio"
      [label]="'Año *'"
      [type]="'number'"
      [disabled]="true"
      [id]="'annio'">
    </app-input>
    <app-input
      [control]="formGroup.controls.numero"
      [label]="'Numero *'"
      [type]="'number'"
      [disabled]="true"
      [id]="'numero'">
    </app-input>
  </div>
  <div class="form-body-three-cols mt-3">
      <app-input
      [control]="formGroup.controls.usuarioId"
      [label]="'Usuario *'"
      [type]="'number'"
      [disabled]="true"
      [id]="'usuarioId'">
    </app-input>
    <app-input
      class=""
      [control]="formGroup.controls.destinatario"
      [label]="'Destinatario'"
      [type]="'text'"
      [disabled]="false"
      [id]="'destinatario'">
    </app-input>
  </div>
  <div class="form-body-three-cols mt-3">
      <app-input
      class="mt-3"
      [control]="formGroup.controls.matricula"
      [label]="'Matrícula *'"
      [type]="'text'"
      [disabled]="true"
      [id]="'matricula'">
    </app-input>
    <app-input
      class="mt-3"
      [control]="formGroup.controls.marca"
      [label]="'Marca *'"
      [type]="'text'"
      [disabled]="true"
      [id]="'marca'">
    </app-input>
    <app-input
      class="mt-3"
      [control]="formGroup.controls.modelo"
      [label]="'Modelo *'"
      [type]="'text'"
      [disabled]="true"
      [id]="'modelo'">
    </app-input>
    <div class="full-width mt-3">
      <app-text-editor
        [control]="formGroup.controls.observaciones"
        [label]="'Observaciones'"
        [id]="'observaciones'"
      />
    </div>
  </div>
  <div class="d-flex gap-3 justify-content-start">
    <button type="submit" class="btn btn-aceptar mt-5">
      <span  *ngIf="isNuevo">
        Crear
      </span>
      <span  *ngIf="!isNuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/tarjetas'" class="btn btn-outline-error mt-5" >
      Cancelar
    </a>
  </div>
</form>
